import { InstantMeetingComponent } from './Components/schedule/instant-meeting/instant-meeting.component';
import { UploadParticipantsDetailsExcelComponent } from './Components/upload-participants-details-excel/upload-participants-details-excel.component';
import { ServerRoomsComponent } from './Components/server-rooms/server-rooms.component';
import { ErrorMessageComponent } from './Components/error-message/error-message.component';
import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './Components/login/login.component';
import { HomeComponent } from './Components/home/home.component';
import { UserComponent } from './Components/user/user.component';
import { ScheduleComponent } from './Components/schedule/schedule.component';
import { ForgotpwdComponent } from './Components/forgotpwd/forgotpwd.component';
import { ChangePasswordComponent } from './Components/change-password/change-password.component';
import { SubscribeUserComponent } from './Components/subscribe-user/subscribe-user.component';
import { SubscribeListComponent } from './Components/subscribe-list/subscribe-list.component';
import { ProfileComponent } from './Components/profile/profile.component';
import { PaymentHistoryComponent } from './Components/payment-history/payment-history.component';
import { VideoRoomComponent } from './Components/video-room/video-room.component';
import { DashboardComponent } from './Components/dashboard/dashboard.component';
import { JoinLoadingMessageComponent } from './Components/join-loading-message/join-loading-message.component';
import { OwndomainComponent } from './Components/owndomain/owndomain.component';
import { ApproveDomainComponent } from './Components/approve-domain/approve-domain.component';
import { StreamSettingsComponent } from './Components/stream-settings/stream-settings.component';
import { UploadUserManualComponent } from './Components/upload-user-manual/upload-user-manual.component';
import { RolesComponent } from './Components/roles/roles.component';
import { RolePermissionMappingComponent } from './Components/role-permission-mapping/role-permission-mapping.component';
import { PermissionsComponent } from './Components/permissions/permissions.component';
import { JoinMeetingComponent } from './Components/join-meeting/join-meeting.component';
import { LandingComponent } from './Components/landing/landing.component';
import { SMShistoryComponent } from './Components/smsinfo/smshistory.component';
import { UserdashboardComponent } from './Components/userdashboard/userdashboard.component';
import { DashboardsummaryComponent } from './Components/dashboardsummary/dashboardsummary.component';
import { SamvaadResourcesComponent } from './Components/resource/samvaad-resources/samvaad-resources.component';
import { LmsResourcesComponent } from './Components/resource/lms-resources/lms-resources.component';
import { ContactUsComponent } from './Components/contact-us/contact-us.component';
import { RequestADemoComponent } from './Components/request-ademo/request-ademo.component';
import { RequestDemoListComponent } from './Components/request-demo-list/request-demo-list.component';
import { UserlistComponent } from './Components/userlist/userlist.component';
import { FeaturesComponent } from './Components/features/features.component';
import { JoinsessionComponent } from './Components/features/joinsession/joinsession.component';
import { LivestreamComponent } from './Components/features/livestream/livestream.component';
import { SharevideoComponent } from './Components/features/sharevideo/sharevideo.component';
import { SelectbackgroundComponent } from './Components/features/selectbackground/selectbackground.component';
import { MessageComponent } from './Components/features/message/message.component';
import { PollsComponent } from './Components/features/polls/polls.component';
import { SharescreenComponent } from './Components/features/sharescreen/sharescreen.component';
import { GridviewandspeakerviewComponent } from './Components/features/gridviewandspeakerview/gridviewandspeakerview.component';
import { PinviewComponent } from './Components/features/pinview/pinview.component';
import { SpotlightviewComponent } from './Components/features/spotlightview/spotlightview.component';
import { BreakoutroomsComponent } from './Components/features/breakoutrooms/breakoutrooms.component';
import { WhiteboardComponent } from './Components/features/whiteboard/whiteboard.component';
import { LogoutendmeetingComponent } from './Components/features/logoutendmeeting/logoutendmeeting.component';
import { NewUserSubscriptionComponent } from './Components/features/new-user-subscription/new-user-subscription.component';
import { SamvaadProContentComponent } from './Components/samvaad-pro-content/samvaad-pro-content.component';
import { FaqComponent } from './Components/faq/faq.component';
import { ReleaseNoteComponent } from './Components/release-note/release-note.component';
import { RaisehandComponent } from './Components/features/raisehand/raisehand.component';
import { EnablelobbyComponent } from './Components/features/enablelobby/enablelobby.component';
import { PasswordprotectionComponent } from './Components/features/passwordprotection/passwordprotection.component';
import { ConnectivitystatusComponent } from './Components/features/connectivitystatus/connectivitystatus.component';
import { DevicesComponent } from './Components/features/devices/devices.component';
import { RecordingComponent } from './Components/features/recording/recording.component';
import { ShowOnlyVideoComponent } from './Components/features/show-only-video/show-only-video.component';
import { SelfViewComponent } from './Components/features/self-view/self-view.component';
import { PollListComponent } from './Components/poll-list/poll-list.component';
import { IndexComponent } from './Components/index/index.component';
import { TrashbinComponent } from './Components/trashbin/trashbin.component';
import { DomainComponent } from './Components/domain/domain.component';
import { ChatListComponent } from './Components/chat-list/chat-list.component';
import { BreakoutRoomComponent } from './Components/breakout-room/breakout-room.component';
import { MobileLunchComponent } from './Components/mobile-lunch/mobile-lunch.component';
import { NewPlansAndPricingComponent } from './Components/new-plans-and-pricing/new-plans-and-pricing.component';
import { SubscriptionFeaturesComponent } from './Components/subscriptions/subscription-features/subscription-features.component';
import { SubscriptionPlansComponent } from './Components/subscriptions/subscription-plans/subscription-plans.component';
import { PlanFeaturesMappingComponent } from './Components/subscriptions/plan-features-mapping/plan-features-mapping.component';
import { PlanServicesComponent } from './Components/plan-services/plan-services.component';
import { NewHeaderComponent } from './Components/new-header/new-header.component';

const routes: Routes = [
  {
    path: '',
    component: NewHeaderComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'login', component: LoginComponent },
      { path: 'login/:token', component: LoginComponent },
      { path: 'plans', component: NewPlansAndPricingComponent },
      { path: 'joinMeeting', component: JoinMeetingComponent },
      { path: 'subuser', component: SubscribeUserComponent },
      { path: 'plan-service', component: PlanServicesComponent },
      // { path: 'aar/:id', component: VideoRoomComponent },
      { path: 'videoRoom/:id', component: VideoRoomComponent },
      { path: 'smry', component: DashboardsummaryComponent },
      { path: 'error-msg', component: ErrorMessageComponent },
      { path: 'lmsresource', component: LmsResourcesComponent },
      { path: 'samvaadresource', component: SamvaadResourcesComponent },
      { path: 'contactus', component: ContactUsComponent },
      { path: 'requestademo', component: RequestADemoComponent },
      { path: 'requestademolist', component: RequestDemoListComponent },
      { path: 'svgtopdf', component: UserlistComponent },
      { path: 'samvaadcontent', component: SamvaadProContentComponent },
      { path: 'faq', component: FaqComponent },
      { path: 'releaseNote', component: ReleaseNoteComponent },
      // { path: 'aar/:id', component: IndexComponent },
      { path: 'aar/:id', component: HomeComponent },
      { path: 'mobile/:id', component: MobileLunchComponent },
      { path: 'plansAndPrice', component: NewPlansAndPricingComponent },
    ]
  },
  {
    path: 'features', component: FeaturesComponent, children: [
      { path: 'joinSession', component: JoinsessionComponent },
      { path: 'liveStream', component: LivestreamComponent },
      { path: 'shareVideo', component: SharevideoComponent },
      { path: 'selectBackground', component: SelectbackgroundComponent },
      { path: 'message', component: MessageComponent },
      { path: 'polls', component: PollsComponent },
      { path: 'shareScreen', component: SharescreenComponent },
      { path: 'gridviewAndSpeakerview', component: GridviewandspeakerviewComponent },
      { path: 'pinview', component: PinviewComponent },
      { path: 'spotlightview', component: SpotlightviewComponent },
      { path: 'breakoutRooms', component: BreakoutroomsComponent },
      { path: 'whiteboard', component: WhiteboardComponent },
      { path: 'logoutAndEndmeeting', component: LogoutendmeetingComponent },
      { path: 'newUserSubscription', component: NewUserSubscriptionComponent },
      { path: 'raiseHand', component: RaisehandComponent },
      { path: 'enablelobby', component: EnablelobbyComponent },
      { path: 'passwordprotection', component: PasswordprotectionComponent },
      { path: 'connectivitystatus', component: ConnectivitystatusComponent },
      { path: 'devices', component: DevicesComponent },
      { path: 'recording', component: RecordingComponent },
      { path: 'showOnlyVideo', component: ShowOnlyVideoComponent },
      { path: 'selfView', component: SelfViewComponent },
    ]
  },
  {
    path: 'Home', component: LandingComponent, children: [
      { path: 'user', component: UserComponent },
      { path: 'schedule', component: ScheduleComponent },
      { path: 'forgotpwd', component: ForgotpwdComponent },
      { path: 'changepwd', component: ChangePasswordComponent },
      // { path: 'subsclist', component: SubscribeListComponent },
      { path: 'subsclist', component: NewPlansAndPricingComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'payhistory', component: PaymentHistoryComponent },
      // { path: 'aar', component: VideoRoomComponent },
      // { path: 'conclave', component: VideoRoomComponent },
      { path: 'rooms', component: ServerRoomsComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'joinload-msg', component: JoinLoadingMessageComponent },
      { path: 'owndomain', component: OwndomainComponent },
      { path: 'approvalDomail', component: ApproveDomainComponent },
      { path: 'settings', component: StreamSettingsComponent },
      { path: 'userManual', component: UploadUserManualComponent },
      { path: 'uploadexcel', component: UploadParticipantsDetailsExcelComponent },
      { path: 'role', component: RolesComponent },
      { path: 'rolePermissionMapping', component: RolePermissionMappingComponent },
      { path: 'permission', component: PermissionsComponent },
      { path: 'smshistory', component: SMShistoryComponent },
      { path: 'userdashboard', component: UserdashboardComponent },
      { path: 'poll/:id', component: PollListComponent },
      { path: 'trashbin', component: TrashbinComponent },
      { path: 'domain', component: DomainComponent },
      { path: 'chat/:id', component: ChatListComponent },
      // { path: 'breakoutroom/:id', component: BreakoutRoomComponent },
      { path: 'subscription-features', component: SubscriptionFeaturesComponent },
      { path: 'subscription-plans', component: SubscriptionPlansComponent },
      { path: 'planfeaturesMap', component: PlanFeaturesMappingComponent },
      { path: 'instance-meeting', component: InstantMeetingComponent },

    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
