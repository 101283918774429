import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EntityService } from 'src/app/Services/entity.service';
import { HttpService } from 'src/app/Services/http.service';
import { PaginationService } from 'src/app/Services/pagination.service';
import { BaseComponent } from '../BaseComponent';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css'],
  providers: [DatePipe]
})
export class ContactUsComponent extends BaseComponent implements OnInit {

  contactUsForm:FormGroup; submitted :boolean=false;
  countryList:Array<any>=[]; stateList:Array<any>=[];
   url = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  constructor(private formBuilder: FormBuilder
    ,protected toastr: ToastrService
    ,protected router: Router
    ,protected httpSvc: HttpService
    ,protected pageSvc: PaginationService
    ,protected entitysvc: EntityService
    ,private datepipe: DatePipe
  ) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
  }
  ngOnInit() {
    this.contactFormInit();
    this.loadCountry();
  }

  contactFormInit(){
    this.contactUsForm = this.formBuilder.group({
      'id': 0,
      'firstName': ['', [Validators.required]],
      'lastName':['', [Validators.required]],
      'email':['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]],
      'phone': ['', [Validators.required, Validators.minLength(10)]],
      'organizationName':['', [Validators.required]],
      'organizationType':['',[Validators.required]],
      'organizationUrl':['', [Validators.required,Validators.pattern(this.url)]],
      'organizationStrength':['',[Validators.required]],
      'countryId':['', [Validators.required]],
      'stateId':['', [Validators.required]],
    });
  }

  get f() { return this.contactUsForm.controls; }

 async loadCountry(){
    this.loading = true; this.countryList = [];this.stateList = [];
    await super.FetchData('Get', this.apiMethod.getCountry, {})
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          this.countryList = this.result.data;
        }
        this.loading = false;
      });
  }

  async onCountryChange(id:any){
    this.loading = true; this.stateList = [];
    this.contactUsForm.controls['stateId'].setValue('');
    await super.FetchData('Get', `${this.apiMethod.getStateById}${id==''?0:+id}`, {})
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          this.stateList = this.result.data;
        }
        this.loading = false;
      });
  }

  async onSave(){
    this.submitted=true;
    if (this.contactUsForm.invalid) {
      this.toastr.warning('Please Enter All Mandatory Fields', 'Contacts Us');
      return;
    }
   this.loading = true;
    let payload = this.contactUsForm.getRawValue();
    await super.FetchData('Post', this.apiMethod.saveContuctUs, JSON.parse(JSON.stringify( payload)))
    .catch(() => { this.httpSvc.handleError, this.loading = false; })
    .then(() => {
      if (this.result != []) {
        if (this.result.data != null && this.result != undefined) {
          if (this.result.status == 'OK'){
            this.toastr.success(this.result.message);
            this.contactFormInit();this.submitted=false;
          }
        }
      }
      this.loading = false;
    });
  }

  clear(){
    this.contactFormInit();
    this.submitted=false;
  }

  numberValidate(event:any){
    event.target.value = event.target.value.replace(/[^0-9]*/g, '');
  }
  nameValidate(event:any){
    event.target.value = event.target.value.replace(/[^A-Za-z. ]*/g, '');
  }
}
