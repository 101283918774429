import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pinview',
  templateUrl: './pinview.component.html',
  styleUrls: ['./pinview.component.css']
})
export class PinviewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
