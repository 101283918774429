import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/Components/BaseComponent';
import { EntityService } from 'src/app/Services/entity.service';
import { HttpService } from 'src/app/Services/http.service';
import { LoginService } from 'src/app/Services/login.service';
import { PaginationService } from 'src/app/Services/pagination.service';
import { environment } from 'src/environments/environment';
declare let $: any;
@Component({
  selector: 'app-new-plans-and-pricing',
  templateUrl: './new-plans-and-pricing.component.html',
  styleUrls: ['./new-plans-and-pricing.component.css']
})
export class NewPlansAndPricingComponent extends BaseComponent implements OnInit {

  isMonthely: boolean = true; payUrl: string = '';
  lstNewSubscriptionPack: Array<any> = [];
  constructor(
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected entitysvc: EntityService,
    protected pageSvc: PaginationService,
    private isLogin: LoginService
  ) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
  }
  ngOnInit() {
    this.loadPlans('monthly');
    let userData = JSON.parse(sessionStorage.getItem('loginData'));
    if (userData)
      this.isLogin.changeMessage(true);
  }

  toggelPack(event) {
    this.isMonthely = event.target.checked;
    let plan = this.isMonthely ? 'annually' : 'monthly';

    this.loadPlans(plan);
  }

  loadPlans(plan: string) {
    this.loading = true;
    let Payload = `{"duration":"${plan}"}`
    super.FetchData('Post', `${this.apiMethod.getNewSubscriptionPack}`, Payload)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result.data.length > 0 && this.result.status == 'OK') {
          this.lstNewSubscriptionPack = this.result.data;
        }
        this.loading = false;
      });
  }

  async startedPayment(item: any) {
    let [payload, userData] = [{}, JSON.parse(sessionStorage.getItem('loginData'))];
    // payload = {amount:item.rate, description:'Subscription', name:userData.name, emailid:userData.email, phone:userData.mobileNO, loginid:userData.id,
    //  packageid:item.id};
    // this.entitySvc.post('https://lms.dhanushinfotech.com/lmstelecon/payment/startPaymentA',payload).subscribe(res=>{
    //   console.log('Saved Successfully');
    // },
    // err=>{
    //   console.log(err);
    // });
    //this.payUrl = `https://meet.dhanushinfotech.com/conference/nojwt/payment/startPaymentB/${userData.id}/${item.id}`;
    // debugger
    let url = location.hostname != 'localhost' ? `${location.origin}/conference/` : environment.apiUrl;
    // debugger
    if (item.duration == 'TrialPeriod') {
      this.payUrl = `${url}nojwt/payment/activateFreeTrail/${userData.id}/${item.id}`;
      this.httpSvc.newGet(this.payUrl).subscribe((res) => {
        const result = res.json();
        if (result.status == 'OK') {
          this.toastr.success(result.message);
          this.router.navigate(['/login']);
        }
      },
        (err) => {
          const errResult = err.json();
          this.toastr.error(errResult.message);
        }
      );
    }
    else {
      // let prodUrl = 'https://scheduling.samvaad.pro/conference/';
      // debugger
      this.payUrl = `${url}nojwt/payment/startPaymentB/${userData.id}/${item.id}`;
      (<HTMLAnchorElement>document.getElementById('payId')).href = this.payUrl;
      document.getElementById('payId').click();
    }
  }

  numberWithCommas(price: any) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
