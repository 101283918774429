import { Component, OnInit, Input, EventEmitter, Output, ɵConsole } from '@angular/core';
import { User } from 'src/app/Entities/entity';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/Services/http.service';
import { EntityService } from 'src/app/Services/entity.service';
import { BaseComponent } from 'src/app/Components/BaseComponent';
import { Subject } from 'rxjs';
import { PaginationService } from 'src/app/Services/pagination.service';
import { DatePipe } from '@angular/common';
import { ThrowStmt } from '@angular/compiler';
import * as commonJson from 'src/app/Shared/common.json';
declare let $: any;


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
  providers: [DatePipe]
})

export class UserComponent extends BaseComponent implements OnInit {

  // subscription: any;
  // ngOnDestroy(): void {
  //   this.subscription.unsubscribe();
  // }
  public loading = false;
  userForm: FormGroup; confpassword = ''; numberOfParticipants = ''; numberOfHosts: ''; amount: ''; hederText = ''; roleId = "0"; creatorId = 0; serchText = '';
  user = new User(); lstUser = []; lstRole = []; lstUserRole = []; btnText = 'Save'; results = []; status = '';
  dtTrigger: Subject<any> = new Subject(); minExpDate = new Date(); logUserRoleId = 0;
  lstOrgAdminList: Array<any> = []; orgAdminId = '0'; isShowOrgAdmin: boolean = false; orgId: number = 0;
  latArchiveDuration: Array<any> = [];
  @Input('show-modal') showModal: boolean;
  @Output('closed') closeEmitter: EventEmitter<User> = new EventEmitter<User>();
  constructor(private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected pageSvc: PaginationService,
    protected entitysvc: EntityService,
    private datepipe: DatePipe
  ) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
    this.creatorId = JSON.parse(sessionStorage.getItem('loginData')).id;
    this.logUserRoleId = JSON.parse(sessionStorage.getItem('loginData')).role.id;
    this.orgId = JSON.parse(sessionStorage.getItem('loginData')).orgId;
    console.log(this.logUserRoleId);
    this.pageSvc.setPageNumber = 0; this.pageNumber = 0
    this.pageSvc.table_Data.subscribe((s) => { this.results = s });
    this.pageSvc.page_Number.subscribe((s) => (this.pageNumber = s));
    this.pageSvc.mthd_Name.subscribe((s) => (this.mthdname = s));
    this.pageSvc._payload.subscribe((s) => (this.payload = s));
    this.pageSvc._apiType.subscribe((s) => (this.apiType = s));
    this.pageSvc._svcTagIndx.subscribe((s) => (this.svcTagIndx = s));
    this.pageSvc._isLcl.subscribe((s) => (this.isLcl = s));
    this.pageSvc.entity_data.subscribe((s) => (this.entity = this.results));
    this.latArchiveDuration = commonJson.archiveDuration;
  }

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      'id': 0,
      'name': ['', [Validators.required]],
      'password': ['', [Validators.minLength(5)]],
      'confpassword': ['', [Validators.minLength(5)]],
      'role': {
        'id': [0, [Validators.required]],
      },
      'mobileNO': ['', [Validators.required, Validators.minLength(10)]],
      'active': true,
      'address': '',
      'email': new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]),
      'createdBy': {
        'id': 0,
      },
      'recordByDefault': false,
      'liveStreamByDefault': false,
      'packType': '',
      'expiryDateTime': '',
      'createdDate': '',
      'numberOfParticipants': '',
      'numberOfHosts': '',
      'amount': '',
      'archiveDuration': '',

    });
    this.validatePattern();
    this.hederText = 'New User Registration';
    this.pageNumber = 0;
    Promise.all([this.getAllRole(), this.getUserList()]);
    console.log(this.logUserRoleId);
    // this.creatorId = JSON.parse(sessionStorage.getItem('loginData')).id;


  }

  get f() { return this.userForm.controls; }

  addEntity() {
    // this.userForm.reset();
    this.getAllRole();
    this.btnText = 'Save';
    this.hederText = "New user registration";
    this.user = new User();
    if (this.logUserRoleId != 3) {
      this.userForm.controls['expiryDateTime'].disable();
      this.userForm.controls['amount'].disable();
      this.userForm.controls['numberOfHosts'].disable();
      this.userForm.controls['numberOfParticipants'].disable();
    } else {
      this.userForm.controls['expiryDateTime'].enable();
      this.userForm.controls['amount'].enable();
      this.userForm.controls['numberOfHosts'].enable();
      this.userForm.controls['numberOfParticipants'].enable();
    }
    $('#adminUserModal').show();
  }

  async getAllRole() {
    this.loading = true; this.lstRole = [];
    await super.FetchData('Get', this.apiMethod.getAllRole, {})
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result.data != []) {
          this.lstRole = this.logUserRoleId == 3 ? this.result.data : this.result.data.filter(m => m.id == 2);
          this.lstUserRole = this.result.data;
        }
        this.loading = false;
      });
  }

  async getUserList() {
    this.loading = true; //this.lstUser = [];
    // jQuery('.dataTable').DataTable().destroy();
    // jQuery('.dataTable').DataTable({ searching: false });
    // await super.FetchData('Get', `${this.apiMethod.fetchUserByCreatorId}${this.creatorId}`, {}, 1)
    // alert('at getUserList ');
    this.orgAdminId = this.isShowOrgAdmin ? this.orgAdminId : '0';
    let userid = +this.orgAdminId != 0 ? +this.orgAdminId : this.creatorId;
    let roleid = +this.orgAdminId != 0 ? 0 : this.roleId;
    this.pageSvc.setPageNumber = 0; this.pageNumber = 0; this.serchText = '';
    let payload = `{"userID":${userid},"roleid":${+roleid},"search":"","status":"${this.status}"}`;

    //let payload = `{"userID":${this.creatorId},"roleid":0,"search":"","status":"${this.status}"}`;

    await super.FetchDataByPagination('Post', `${this.apiMethod.getUsersList}`, payload, 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          // this.lstUser = this.result.data;
          this.results = this.result;
          //this.dtTrigger.next();
        }
        this.loading = false;
      });
  }

  async onRoleChange(id: any) {
    this.pageSvc.setPageNumber = 0; this.pageNumber = 0; this.serchText = ''; this.status = ''; this.orgAdminId = '0';
    if (+id == 0) {
      this.getUserList();
      return;
    }
    if (+id == 1) this.getOrganizationAdmin(this.creatorId);
    this.loading = true;
    //await super.FetchData('Get', `${this.apiMethod.getUserByRole}${this.creatorId}/${id}`, {}, 1)
    let payload = `{"userID":${this.creatorId},"roleid":${+id},"search":"","status":""}`;
    await super.FetchDataByPagination('Post', `${this.apiMethod.getUsersList}`, payload, 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          //this.lstUser = this.result.data;
          //this.dtTrigger.next();
          this.results = this.result;
        }
        this.loading = false;
      });

  }

  async onStatusChange(userStatus: string) {
    this.orgAdminId = this.isShowOrgAdmin ? this.orgAdminId : '0';
    let userid = +this.orgAdminId != 0 ? +this.orgAdminId : this.creatorId;
    let roleid = +this.orgAdminId != 0 ? 0 : this.roleId;
    this.pageSvc.setPageNumber = 0; this.pageNumber = 0; this.serchText = '';
    let payload = `{"userID":${userid},"roleid":${+roleid},"search":"","status":"${userStatus}"}`;
    await super.FetchDataByPagination('Post', `${this.apiMethod.getUsersList}`, payload, 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          this.results = this.result;
        }
        this.loading = false;
      });
  }

  async onUserIdCheck() {
    console.log(this.user.email);
    if (this.user.email != '') {
      await super.FetchData('Get', `${this.apiMethod.checkExistEmail}${this.user.email.toLowerCase()}/${this.user.id}`, {})
        .catch(() => { this.httpSvc.handleError, this.loading = false; })
        .then(() => {
          if (this.result != []) {
            if (this.result.status != 'OK') {
              this.user.email = '';
              return false;
            }
          }
          this.loading = false;
        });
    }
  }

  async editUser(item: any) {
    await this.getAllRole();
    this.btnText = 'Update';
    this.hederText = "Edit user registration";
    if (item) {
      // this.userForm.patchValue(item);
      // this.user = item as User;
      this.user.id = item.id;
      this.user.name = item.name;
      this.user.role.id = item.role.id;
      this.user.mobileNO = item.mobileNO;
      this.user.email = item.email;
      this.user.createdBy.id = item.createdBy.id;
      this.user.address = item.address;
      this.user.active = item.active;
      this.user.recordByDefault = item.recordByDefault;
      this.user.liveStreamByDefault = item.liveStreamByDefault;
      this.user.packType = item.packType;
      this.user.numberOfHosts = item.numberOfHosts;
      this.user.numberOfParticipants = item.numberOfParticipants;
      this.user.amount = item.amount;
      this.user.expiryDateTime = this.datepipe.transform(item.expiryDateTime, 'dd-MM-yyyy');
      this.user.createdDate = this.datepipe.transform(item.createdOn, 'dd-MM-yyyy');
      this.user.archiveDuration = item.archiveDuration ? item.archiveDuration : '';
    }
    if (this.logUserRoleId != 3 || this.user.packType == 'Dhanush User') {
      this.userForm.controls['expiryDateTime'].disable();
      this.userForm.controls['amount'].disable();
      this.userForm.controls['numberOfHosts'].disable();
      this.userForm.controls['numberOfParticipants'].disable();
    } else {
      this.userForm.controls['expiryDateTime'].enable();
      this.userForm.controls['amount'].enable();
      this.userForm.controls['numberOfHosts'].enable();
      this.userForm.controls['numberOfParticipants'].enable();
    }
    $('#adminUserModal').show();
  }

  async saveUpdateUser() {
    if (!this.validatePass())
      return;
    if (this.user.role.id == 1 && this.user.archiveDuration == '') {
      this.toastr.warning('Please enter Archive Duration');
      return;
    }
    this.loading = true;
    this.user.name = this.user.name.trim();
    this.user.createdBy.id = this.creatorId;
    this.user.email = this.user.email.toLowerCase();
    this.user.numberOfHosts = this.user.numberOfHosts;
    this.user.numberOfParticipants = this.user.numberOfParticipants;
    //this.user.amount=this.user.amount;
    var objUser: any = this.user;
    objUser.orgId = this.orgId;
    objUser.amount = this.user.amount == null ? 0 : this.user.amount;
    if (typeof this.user.expiryDateTime === 'string' && this.user.expiryDateTime != '') {
      let arr: Array<string> = this.user.expiryDateTime.split('-');
      objUser.expiryDateTime = this.datepipe.transform(new Date(`${arr[2]}-${arr[1]}-${arr[0]}`), 'yyyy-MM-dd');
    }
    else if (typeof this.user.expiryDateTime === 'object') {
      objUser.expiryDateTime = this.datepipe.transform(this.user.expiryDateTime, 'yyyy-MM-dd');
    }
    await super.FetchData('Post', this.apiMethod.saveUser, JSON.parse(JSON.stringify(objUser)))
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          if (this.result.data != null && this.result != undefined) {
            if (this.result.status == 'OK')
              this.toastr.success(this.result.message);
            this.user = new User(); this.confpassword = '';
            $(".close").click(); this.serchText = '';
            this.getUserList();
            // if (this.btnText == 'Save')
            //   this.toastr.success(this.message.saveSuccess);
            // else
            //   this.toastr.success(this.message.updateSuccess);
            // $('#adminUserModal').hide(); $('.modal-backdrop').remove();
            //$("#adminUserModal").modal("hide");
          }
        } //else {
        //  this.toastr.error(this.message.saveFailed);
        // }
        this.loading = false;
      });
  }

  clearUser() {
    this.user = new User(); this.confpassword = '';
  }

  validatePass(): boolean {
    let result = true;
    if (this.user.id == 0 && this.user.password == '') {
      this.toastr.error('Please enter Password');
      return result = false;
    }
    if (this.user.id == 0 && this.confpassword == '') {
      this.toastr.error('Please enter Confirm Password');
      return result = false;
    }
    if (this.user.id == 0 && this.user.password != '' && this.confpassword != '') {
      if (this.user.password != this.confpassword) {
        this.toastr.error('Password and Confirm password is mismatched.');
        this.confpassword = '';
        return result = false;
      }
    }
    return result;
  }

  async onSearchUser() {
    this.pageSvc.setPageNumber = 0; this.pageNumber = 0
    // let url = (+this.roleId != 0) ? `${this.apiMethod.getUsersList}${this.creatorId}/${this.roleId}` : `${this.apiMethod.fetchUserByCreatorId}${this.creatorId}`;
    this.orgAdminId = this.isShowOrgAdmin ? this.orgAdminId : '0';
    let userid = +this.orgAdminId != 0 ? +this.orgAdminId : this.creatorId;
    let roleid = +this.orgAdminId != 0 ? 0 : this.roleId;
    let url = `${this.apiMethod.getUsersList}`;
    if (this.serchText.trim() && this.serchText.trim().length > 2) {
      let payload = `{"userID":${userid},"roleid":${roleid},"search":"${this.serchText.trim()}","status":"${this.status}"}`;
      this.loading = true;
      await super.FetchDataByPagination('Post', `${url}`, payload, 1)
        .catch(() => { this.httpSvc.handleError, this.loading = false; })
        .then(() => {
          if (this.result != []) {
            this.results = this.result;
          }
          this.loading = false;
        });
    }
    else if (this.serchText.trim() == '') {
      let payload = `{"userID":${userid},"roleid":${roleid},"search":"","status":"${this.status}"}`;
      this.loading = true;
      await super.FetchDataByPagination('Post', url, payload, 1)
        .catch(() => { this.httpSvc.handleError, this.loading = false; })
        .then(() => {
          if (this.result != []) {
            this.results = this.result;
          }
          this.loading = false;
        });
    }
  }
  validatePattern() {
    this.getName.valueChanges.subscribe(val => {
      let v = /[^a-zA-Z .\-\/]/
      if (v.test(val)) {
        this.getName.setValue(val.substr(0, val.length - 1));
        if (val.length > 50) {
          this.getName.setValue(val.substr(0, 50))
        }
      }
    });
    this.getMobileNO.valueChanges.subscribe(val => {
      let v = /[^0-9]+$/
      if (v.test(val)) {
        this.userForm.controls['mobileNO'].setValue(val.substr(0, val.length - 1));
        if (val.length > 10) {
          this.getMobileNO.setValue(val.substr(0, 10))
        }
      }
    });
  }

  get getName() {
    return this.userForm.controls['name'];
  }
  get getMobileNO() {
    return this.userForm.controls['mobileNO'];
  }

  ngOnDestroy() {
    $(".close").click();
  }

  async getOrganizationAdmin(id: any) {
    this.loading = true;
    await super.FetchData('Get', `user/fetchadminuserlist/${id}`, {}, 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result.data != []) {
          this.lstOrgAdminList = this.result.data;
        }
        this.loading = false;
      });
  }

  async onOrgAdminChange(id: any) {
    this.loading = true;
    let roleid = +id != 0 ? 0 : 1, userid = +id != 0 ? +id : this.creatorId;
    let payload = `{"userID":${userid},"roleid":${roleid},"search":"","status":"${this.status}"}`;
    await super.FetchDataByPagination('Post', `${this.apiMethod.getUsersList}`, payload, 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          this.results = this.result;
        }
        this.loading = false;
      });
  }
}
