import { Component, OnInit } from '@angular/core';
import { Schedule, User } from 'src/app/Entities/entity';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/Services/http.service';
import { EntityService } from 'src/app/Services/entity.service';
import { BaseComponent } from 'src/app/Components/BaseComponent';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { PaginationService } from 'src/app/Services/pagination.service';
declare let $: any;

@Component({
  selector: 'app-stream-settings',
  templateUrl: './stream-settings.component.html',
  styleUrls: ['./stream-settings.component.css'],
  providers: [DatePipe]
})
export class StreamSettingsComponent extends BaseComponent implements OnInit {
  stream_DetailForm: FormGroup;
  submitted = false;

  createdId = 0;
  streamsList = [];
  streamTypes: Array<{ id: number, name: string }> = [];
  dtTrigger: Subject<any> = new Subject();

  save_title: string = '';
  constructor(private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected entitysvc: EntityService,
    protected pageSvc: PaginationService,
    private datepipe: DatePipe
  ) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
    this.createdId = JSON.parse(sessionStorage.getItem('loginData')).id;
  }

  initFormControls() {
    this.stream_DetailForm = this.formBuilder.group({
      id: [0],
      userId: this.createdId,
      streamTypeId: ['', Validators.required],
      streamURL: ['', Validators.required],
      streamKey: ['', Validators.required],
      userName: '',
    });

  }

  get f() { return this.stream_DetailForm.controls; }

  ngOnInit() {
    this.initFormControls();
    this.expiryDateTime = JSON.parse(sessionStorage.getItem('loginData')).expiryDateTime == null ? '' : JSON.parse(sessionStorage.getItem('loginData')).expiryDateTime;
    this.getAllStreams();
    this.getStreamTypes();
  }

  async getAllStreams() {
    this.loading = true; this.streamsList = [];
    jQuery('.dataTable').DataTable().destroy();
    jQuery('.dataTable').DataTable({ searching: false });
    await super.FetchData('Get', `${this.apiMethod.getUserStreamData}${this.createdId}`, {}, 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          this.streamsList = this.result.data;

          this.dtTrigger.next();
        }
        this.loading = false;
      });

  }

  async getStreamTypes() {
    await super.FetchData('Get', `${this.apiMethod.getStreamMastersData}`, {}, 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          this.streamTypes = this.result.data;
          console.log(this.result);
        }
        this.loading = false;
      });
  }

  clearModal(isFocus: boolean = false) {
    this.submitted = false;
    // this.stream_DetailForm.reset();
    this.initFormControls();
    if (isFocus)
      (<HTMLInputElement>document.getElementById('streamTypeId')).focus();
  }

  async onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.stream_DetailForm.invalid) {
      return;
    }

    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.stream_DetailForm.value, null, 4));
    const streamData =
    {
      "id": this.stream_DetailForm.value.id,
      "user": {
        "id": this.createdId
      },
      "master": {
        "id": this.stream_DetailForm.value.streamTypeId
      },
      "streamurl": this.stream_DetailForm.value.streamURL,
      "streamKey": this.stream_DetailForm.value.streamKey,
      "userName": this.stream_DetailForm.value.userName,
    }
    if (+streamData.id == 0)
      delete streamData.id;


    this.loading = true;
    await super.FetchData('Post', this.apiMethod.addUserStream, streamData, 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          if (this.result.data != null && this.result != undefined) {
            if (this.result.status == 'OK')
              this.toastr.success(this.result.message);
            $(".close").click(); // $('#userModal').hide();
            this.getAllStreams();
          }
        }
        this.loading = false;
      });

  }

  updateStream(item: any) {
    this.stream_DetailForm.patchValue({
      id: item.id,
      userId: this.createdId,
      streamTypeId: item.master.id,
      streamURL: item.streamurl,
      streamKey: item.streamKey,
      userName: item.userName,
    });

  }

  ngOnDestroy() {
    $(".close").click();
  }
}