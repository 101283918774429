import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { async } from 'rxjs/internal/scheduler/async';
import { environment } from 'src/environments/environment';
import { HttpService } from './Services/http.service';
import { LoginService } from './Services/login.service';
// import { BackButtonDisableModule } from 'angular-disable-browser-back-button';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [NgxSpinnerService],

})
export class AppComponent {
  title = 'ConferenceApplication'; query: any = {};

  baseUrl = location.hostname != "localhost" ? `${location.origin}/` : environment.apiUrl;
  is_Login: boolean = false;
  showjoinMessage: boolean = false;
  loaderMsg: string = "";
  constructor(protected router: Router,
    protected httpSvc: HttpService,
    private isLogin: LoginService,
    private spinner: NgxSpinnerService,
  ) {
    this.isLogin.currentLogIn.subscribe(message => { this.is_Login = message; });
    let userLoginData = JSON.parse(localStorage.getItem('samvaadUserLogin'));
    let userSessionStorage = JSON.parse(sessionStorage.getItem('loginData'));
    if (userLoginData && !userSessionStorage && !location.href.includes('/aar/') && !location.href.includes('/smry'))
      this.reLogin(userLoginData);
  }
  ngOnInit() {
    this.isLogin.loaderSpinner.subscribe(b => {
      console.log(b);
      this.showjoinMessage = b;
      if (b)
        this.spinner.show();
      else
        this.spinner.hide();
    });

    this.isLogin.loader_Message.subscribe(m => this.loaderMsg = m);

  }
  onActivate() {
    window.scroll(0, 0);
  }

  async reLogin(loginData: any) {
    await this.httpSvc.newPost(`${this.baseUrl}conference/nojwt/login/login`, loginData).subscribe({
      next: (res: any) => {
        if (res && res.json().data) {
          let data = res.json().data;
          this.httpSvc.isLogin = true;
          localStorage.setItem('samvaadUserLogin', JSON.stringify(loginData));
          sessionStorage.setItem('loginData', JSON.stringify(data));
          sessionStorage.setItem('jwtToken', JSON.stringify(data.jwtToken));
          localStorage.username = data.name;

          this.httpSvc.loginUserDetails = data;
          this.httpSvc.userName = data.name;
          data.role.permission.forEach(e => this.httpSvc.permissionArry.push(e.name));
          console.log('permissions', this.httpSvc.permissionArry);
          if (data && data.email == 'admin@dhanushinfotech.net')
            this.httpSvc.permissionArry.push(...['Privileges', 'Role', 'Privilege', 'Role Privilege Mapping']);
          if (Object.keys(this.query).length != 0)
            this.router.navigate(['/Home/subsclist']);
          else {
            if (data.role.name == 'admin')
              this.router.navigate(['/Home/user']);
            else if (data.role.name == 'IS')
              this.router.navigate(['/Home/approvalDomail']);
            else {
              if (data.subscribed || data.userType == 'EH')
                this.router.navigate(['/Home/schedule']);
              else
                this.router.navigate(['/Home/subsclist']);
            }
          }
          this.isLogin.changeMessage(true);
        }
      },
      error: (err: any) => { this.httpSvc.handleError },
      complete: () => { }
    });
  }
}


