import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/Services/http.service';
import { EntityService } from 'src/app/Services/entity.service';
import { map, retryWhen, delayWhen, tap, shareReplay } from 'rxjs/operators';
import { timer, Subscription, Subject } from 'rxjs';
import * as XLSX from 'xlsx';
import { environment } from 'src/environments/environment';
import { PaginationService } from 'src/app/Services/pagination.service';
declare const $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [DatePipe]
})
export class DashboardComponent implements OnInit, OnDestroy {
  public loading = false;
  private subscription: Subscription = new Subscription();
  fromDate: Date = new Date();
  toDate: Date = new Date();
  todayDate: Date = new Date();
  bbb_url: string = '';
  isFirstTime: boolean = false;
  url = location.hostname != 'localhost' ? `${location.origin}/conference/` : environment.apiUrl + 'conference/';
  meetingsCount: number = 0;
  participantCount: number = 0;
  allMeetings: Array<any> = [];
  participantList: Array<any> = [];
  bandWidthList: Array<any> = [];
  participantDetailsList: Array<any> = [];
  dtTrigger: Subject<boolean> = new Subject();
  dtTrigger1: Subject<boolean> = new Subject();
  dtTriggerAttendee: Subject<boolean> = new Subject();
  constructor(
    protected pageSvc: PaginationService,
    private toastr: ToastrService,
    private httpSvc: HttpService,
    private entitysvc: EntityService,
    private datepipe: DatePipe
  ) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    $(".close").click();
  }

  ngOnInit() {
    this.getDashboardData();
  }

  dateChange(event: any) {
    if (this.isFirstTime)
      this.toDate = (this.toDate < (event)) ? null : this.toDate;
    this.isFirstTime = true;
  }

  getDashboardData() {
    const dt = new Date(), today = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
    if (!(this.fromDate instanceof Date)) {
      this.toastr.success('', 'Please select From Date');
      return;
    }
    else if (!(this.toDate instanceof Date)) {
      this.toastr.success('', 'Please select To Date');
      return;
    }

    let [dateFrom, dateTo, currentTime] = [this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'), this.datepipe.transform(this.toDate, 'yyyy-MM-dd'), this.datepipe.transform(new Date(), 'HH:mm:ss')];
    [this.participantCount, this.meetingsCount] = [0, 0];
    let to_date = new Date(dateTo);
    if (today.getTime() == to_date.getTime())
      currentTime = currentTime;//'23:59:00';
    else if (to_date.getTime() < today.getTime())
      currentTime = '23:59:00';
    let payload = `{
      "fromDate":"${dateFrom} 00:00:00",
      "toDate":"${dateTo} ${currentTime}",
      "zonename":"${Intl.DateTimeFormat().resolvedOptions().timeZone}",
      "userId":${JSON.parse(sessionStorage.getItem('loginData')).id}
    }`;
    // const fetchBBBMettings$: any = this.httpSvc.newGet(`${this.url}conclave/nojwt/meeting/fetchBBBMettings/${dateFrom} 00:00:00/${dateTo} ${currentTime}/${JSON.parse(sessionStorage.getItem('loginData')).id}`);
    const fetchBBBMettings$: any = this.httpSvc.newPost(`${this.url}conclave/nojwt/meeting/fetchBBBMettingsFromDateToDateAndUserID`, payload);
    this.subscription.add(fetchBBBMettings$);

    this.loading = true; this.allMeetings = [];
    jQuery('.dataTable').DataTable().destroy();
    jQuery('.dataTable').DataTable({ searching: false });

    // fetchBBBMettings$.pipe(
    //   tap(() => console.log("HTTP request executed")),
    //   map(res => Object.values(res["payload"])),
    //   shareReplay(),
    //   retryWhen(errors => {
    //     return errors
    //       .pipe(
    //         delayWhen(() => timer(2000)),
    //         tap(() => console.log(errors,'retrying...'))
    //       );
    //   })
    // )
    fetchBBBMettings$.subscribe(
      res => {
        const result = res.json();
        if (result.status == 'OK') {
          let meetings: Array<any> = [];
          let attendee: Array<any> = [];
          let noDup_meetings: Array<any> = [];

          result.data.forEach(i => {
            if (i.meetingID)
              meetings.push(i.meetingID);

          });
          //  noDup_meetings = [...new Set(meetings)];
          noDup_meetings = meetings;// [...new Set(meetings)];
          result.data.forEach(i => {
            this.allMeetings.push(i);
            attendee.push(i.attendeeMap.length);

            // if (noDup_meetings.includes(i.meetingID)) {
            //   let item = this.allMeetings.find(e => e.meetingID == i.meetingID);
            //   if (!item) {
            //     this.allMeetings.push(i);
            //     attendee.push(i.attendeeMap.length);
            //   }
            // }
          });

          this.meetingsCount = noDup_meetings.length;
          if (attendee.length > 0)
            this.participantCount = attendee.reduce((a, b) => a + b);
        }
        console.log('HTTP response', result);
      },
      err => {
        console.log('HTTP Error', err);
        this.loading = false;
        this.dtTrigger.next();
      },
      () => {
        console.log('HTTP request completed.');
        this.loading = false;
        this.dtTrigger.next();
      }
    );
  }

  // getBBB_Api() {
  //   const getCheckSumUrl$ = this.entitysvc.GetData1('login/getAVServiceUrl').subscribe(res => {
  //     const result = res.json();
  //     if (result.message === 'getAVServiceUrl' && result.status === 'OK') {
  //       this.bbb_url = result.data.avserviceurl;
  //       this.getDashboardData();
  //     }
  //     console.log(result);
  //   });
  //   this.subscription.add(getCheckSumUrl$);
  // }

  openModal(attendee: any) {
    this.participantList = [];
    jQuery('.attendee').DataTable({ searching: false });
    jQuery('.attendee').DataTable().destroy();
    // this.participantList = attendee;
    debugger;
    this.participantList = attendee != [] ? attendee.sort((a, b) => a.username.localeCompare(b.username)) : attendee;
    this.participantList = this.participantList.map(e => ({ ...e, newusername: e.meetingendedby ? `<strong>${e.username}</strong></br>(End By)` : e.username }));
    setTimeout(() => { this.dtTrigger.next(); }, 100);
    document.getElementById('btnOpenModal').click();
  }

  openParticipantDetailsModal(item: any) {
    let endDate = item.userLeftDateTime != null ? `"${this.datepipe.transform(item.userLeftDateTime, 'yyyy-MM-ddTHH:mm:ss')}"` : null;
    let payload = `{ "startDateTime": "${this.datepipe.transform(item.createdOn, 'yyyy-MM-ddTHH:mm:ss')}", "endDateTime":${endDate}}`;
    this.httpSvc.newPost(`${this.url}conclave/nojwt/join/getJoinParticipantData/${item.meetingID}`, payload).subscribe(res => {
      const result = res.json();
      if (result.status === 'OK') {
        this.participantDetailsList = result.data;
        this.participantDetailsList.map(m => m.platformInfo = JSON.parse(m.platformInfo));
      }
      document.getElementById('btnPatispantDtailsModal').click();

    });

  }


  ExportTOExcel(list: any) {
    var jsonArray = [];
    var sno = 1;
    list.forEach(e => {
      jsonArray.push({
        'S No': sno,
        'Attendee Name': e.meetingendedby ? `${e.username}(End By)` : e.username,
        'Role': e.role,
        'Start Date & Time': this.datepipe.transform(((e.zoneid > 0) ? e.userJoinedWithTimeZone : e.startdatetime), 'yyyy-MM-dd HH:mm'),
        'End Date & Time': this.datepipe.transform(((e.zoneid > 0) ? e.userLeftWithTimeZone : e.leftdatetime), 'yyyy-MM-dd HH:mm'),
        'Time Zone': e.zonename,
        'Duration': e.duration
      });
      sno++;
    });
    // const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById('attendeeTable'), { raw: true });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonArray);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'AttendeeDetails_' + new Date().getTime() + '.xlsx');
  }

  level: string;
  bandwidthDateTime: string;
  levelArray: string[];
  bandwidthDateTimeArray: string[];
  openBandWidthDetails(bandwidth: any) {
    this.bandWidthList = [];
    jQuery('.bandWidth').DataTable({ searching: false });
    jQuery('.bandWidth').DataTable().destroy();
    let usrBnd = bandwidth;
    this.level = usrBnd.level;
    this.bandwidthDateTime = usrBnd.bandwidthDateTime;
    if (this.level) {
      this.levelArray = this.level.split(",");
      this.bandwidthDateTimeArray = this.bandwidthDateTime.split(",");
      for (let i = 0; this.levelArray.length > i; i++) {
        let arr = {
          level: this.levelArray[i],
          datetime: this.datepipe.transform(new Date(+this.bandwidthDateTimeArray[i]).toISOString(), "dd-MM-yyyy HH:mm:ss"),
        }
        this.bandWidthList.push(arr);
      }
    }
    setTimeout(() => { this.dtTrigger1.next(); }, 100);
    document.getElementById('btnOpenModal1').click();
  }


  exportMoreDetailsToExcel(list: any) {
    var jsonArray = [];
    var sno = 1;
    list.forEach(e => {
      jsonArray.push({
        'S No': sno,
        'Name': e.name,
        'CompanyName': e.companyName,
        'Designation': e.designation,
        'Mobile': (e.mobileNumber == 0) ? null : e.mobileNumber,
        'Email': e.mail,
        'Location': e.location,
        'Occupation': e.occupation,
        'Created Date and Time': this.datepipe.transform((e.Zoneid && e.Zoneid > 0) ?
          e.createdOnTimeZone.replace('T', ' ') : e.createdOn, 'yyyy-MM-dd HH:mm'),
        'DeviceName': e.platformInfo.os,
        'DeviceType': e.platformInfo.deviceType,
        'Browser': e.platformInfo.browser + ' ' + e.platformInfo.browser_version
      });
      sno++;
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonArray);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'AttendeeMoreDetails.xlsx');
  }



}

