import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { EntityService } from 'src/app/Services/entity.service';
import { HttpService } from 'src/app/Services/http.service';
import { PaginationService } from 'src/app/Services/pagination.service';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../../BaseComponent';

@Component({
  selector: 'app-subscription-features',
  templateUrl: './subscription-features.component.html',
  styleUrls: ['./subscription-features.component.css']
})
export class SubscriptionFeaturesComponent extends BaseComponent implements OnInit {

  mode: string = 'Create';
  featureForm: FormGroup;
  lstSubscriptionFeature: Array<any> = [];
  formData: FormData; logo: File; logoName: string = ''; imagepath: string = '';
  jwtToken: string = '';
  dtTrigger: Subject<any> = new Subject();
  url = location.hostname != 'localhost' ? `${location.origin}/conference/` : environment.apiUrl + 'conference/';
  fileUrl = location.hostname != 'localhost' ? `${location.origin}` : environment.apiUrl;
  constructor(private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected pageSvc: PaginationService,
    protected entitysvc: EntityService,
    private http: HttpClient) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
    this.featuresFormInet();
  }

  ngOnInit() {
    this.jwtToken = JSON.parse(sessionStorage.getItem('loginData')).jwtToken;
    this.getAllFeatures();
  }

  featuresFormInet() {
    this.featureForm = this.formBuilder.group({
      id: [0],
      featureName: ['', [Validators.required]],
      featureType: ['', [Validators.required]],
      logopath: [''],
      deleted: [false],
      createdBy: [''],
      modifiedBy: [''],
    });
  }

  editFeature(item: any, mode: any) {
    this.mode = mode;
    if (mode == 'Update') {
      this.assignData(this.featureForm, item);
      this.logoName = item.logopath;
      this.imagepath = `${this.fileUrl}${item.logopath}`;
    }
  }

  async saveUpdateFeature() {
    this.formData = new FormData;
    let payload = this.featureForm.value;
    payload.logopath = this.logoName;
    if (payload.id == 0)
      payload.createdBy = JSON.parse(sessionStorage.loginData).id;
    else payload.modifiedBy = JSON.parse(sessionStorage.loginData).id;
    this.formData.append('request', JSON.stringify(payload));
    this.formData.append('logo', this.logo);
    await this.http.post(`${this.url}${this.apiMethod.saveUpdateFeature}`, this.formData,
      { headers: new HttpHeaders({ Authorization: this.jwtToken }) }).
      subscribe(res => {
        let result: any = res;
        if (result.status == 'OK')
          this.toastr.success(result.message);
        else this.toastr.error(result.message);
      },
        err => {
          if (err.status == 401) {
            this.toastr.error(err.error.message);
            this.jwtToken = '';
          }
          else {
            this.toastr.error(err.error.message);
          }
        },
        () => {
          this.getAllFeatures();
          this.loading = false;
          this.close();
        });
  }

  async getAllFeatures() {
    this.loading = true; this.lstSubscriptionFeature = [];
    jQuery('.dataTable').DataTable().destroy();
    jQuery('.dataTable').DataTable({ searching: false });
    await super
      .FetchData("Get", this.apiMethod.getAllSubcriptionFeatures, {}, 1)
      .catch(() => { this.httpSvc.handleError, (this.loading = false); })
      .then(() => {
        if (this.result != []) {
          this.lstSubscriptionFeature = this.result.data;
          this.dtTrigger.next();
        }
        this.loading = false;
      });

  }

  changeFile(event: any) {
    if (event.target.files.length > 0) {
      let file: File = event.target.files[0];
      this.logoName = file.name;
      this.logo = file;
      var reader = new FileReader();
      reader.onload = (e: any) => { $('#features_logo').attr('src', e.target.result) };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  close() {
    this.logoName = '';
    this.logo = null;
    this.formData = new FormData;
    this.featuresFormInet();
    $("#logofile1").val('');
    $(".close").click();
  }

}
