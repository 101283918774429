import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { Router, ActivatedRoute, Params } from "@angular/router";
import "rxjs/add/operator/map";
import "rxjs/Rx";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  public svcTag: string[] = [
    `conference/nojwt/`,
    `conference/jwt/`,
    "conference/",
    "conference/conclave/nojwt/",
    "conference/conclave/jwt/",
    "conclave/nojwt/",
    "conclave/jwt/"
  ];
  // public svcTag: string[] = [`conference/nojwt/`, `conference/jwt/`, 'conference/user/jwt/', 'conference/user/nojwt/', 'conference/payment/jwt/'];
  public httpUrl: string = window.location.href;
  public BaseUrl: string = ``;
  public lclUrl: string = ``;
  public loginUserDetails: any;
  public userName: string = "";
  public permissionArry: string[] = [];
  public subscribeUser: any;
  public isLogin: boolean = false;

  constructor(private _http: Http, private http: HttpClient) {
    this._http = _http;
    [this.BaseUrl, this.lclUrl] = [environment.apiUrl, environment.localUrl];
    if (location.hostname != "localhost")
      this.BaseUrl = `${location.origin}/`;
  }

  public PostAPI(bundle, useJWT: number = 1) {
    //   bundle.isLcl = true;
    var apiPath = bundle.apiMethod;
    //let oPath = `${this.lclUrl}${this.svcTag[+useJWT]}${apiPath}`;
    let oPath = `${bundle.isLcl ? this.lclUrl : this.BaseUrl}${this.svcTag[+bundle.svcTagIndx]
      }${apiPath}`;
    var header: Headers = this.addHeader(+bundle.svcTagIndx);
    return this._http.post(oPath, bundle.payLoad, { headers: header });
  }

  public GetAPI(bundle: any, useJWT: number = 1, isLocalURL: boolean = false) {
    // bundle.isLcl = true;
    var apiPath = bundle.apiMethod;
    // let oPath = `${isLocalURL ? this.BaseUrl : this.lclUrl}${this.svcTag[+useJWT]}${apiPath}`;
    let oPath = `${bundle.isLcl ? this.lclUrl : this.BaseUrl}${this.svcTag[+bundle.svcTagIndx]
      }${apiPath}`;
    var header: Headers = this.addHeader(+bundle.svcTagIndx);
    let options = new RequestOptions({ headers: header });
    return this._http.get(oPath, options).toPromise();
  }
  public GetAPI1(bundle: any, useJWT: number = 1, isLocalURL: boolean = false) {
    // bundle.isLcl = true;
    var apiPath = bundle.apiMethod;
    // let oPath = `${isLocalURL ? this.BaseUrl : this.lclUrl}${this.svcTag[+useJWT]}${apiPath}`;
    let oPath = `${bundle.isLcl ? this.lclUrl : this.BaseUrl}${this.svcTag[+bundle.svcTagIndx]
      }${apiPath}`;
    var header: Headers = this.addHeader(+bundle.svcTagIndx);
    let options = new RequestOptions({ headers: header });
    return this._http.get(oPath, options);
  }

  private addHeader(useJwt: number) {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    if (useJwt == 1 || useJwt == 4 || useJwt == 6) {
      let jwtToken = JSON.parse(sessionStorage.getItem("jwtToken"));
      headers.append("Authorization", jwtToken);
    }
    return headers;
  }

  // private addHeader(useJwt: number) {
  //   let headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   let jwtarray = [1, 2, 4];
  //   if (jwtarray.includes(useJwt)) {
  //     let jwtToken = JSON.parse(sessionStorage.getItem('jwtToken'));
  //     headers.append("Authorization", jwtToken);
  //   }
  //   return headers;
  // }

  public handleError(error: Response) { }

  postTest(url: string, postData: Object) {
    return this.http.post(url, postData);
  }

  newGet(url: string) {
    return this._http.get(url);
  }

  newPost(url: string, postData: any) {
    return this._http.post(url, postData);
  }
}
