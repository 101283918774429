import { Component, OnInit } from '@angular/core';
import { ChangePassword } from 'src/app/Entities/entity';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/Services/http.service';
import { EntityService } from 'src/app/Services/entity.service';
import { BaseComponent } from 'src/app/Components/BaseComponent';
import { Subject } from 'rxjs';
import { PaginationService } from 'src/app/Services/pagination.service';
declare let $: any;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent extends BaseComponent implements OnInit {

  createdId = 0; userid = ''; conformPassword = ''; public loading = false;
  changePasswordform: FormGroup; changePassword = new ChangePassword();

  constructor(private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected entitysvc: EntityService,
    protected pageSvc: PaginationService

  ) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
    this.createdId = sessionStorage.getItem('loginData') ? JSON.parse(sessionStorage.getItem('loginData')).id : 0;
    this.userid = sessionStorage.getItem('loginData') ? JSON.parse(sessionStorage.getItem('loginData')).email : '';
  }

  ngOnInit() {
    this.changePasswordform = this.formBuilder.group({
      'userid': ['', Validators.required],
      'password': ['', [Validators.required, Validators.minLength(5)]],
      'newPassword': ['', [Validators.required, Validators.minLength(5)]],
      'conformPassword': ['', [Validators.required, Validators.minLength(5)]],
    });
  }
  get f() { return this.changePasswordform.controls; }

  async onSave() {
    this.loading = true;
    this.changePassword.userID = this.createdId;
    super.FetchData('Post', this.apiMethod.changePwd, JSON.parse(JSON.stringify(this.changePassword)), 1).
      catch(() => { this.loading = false, this.httpSvc.handleError; }).
      then(() => {
        let data = JSON.parse(JSON.stringify(this.result));
        if (data.status == 'OK') {
          this.toastr.success(data.message);
          this.onClear();
          this.loading = false;
          setTimeout(() => this.router.navigate(['/login']), 5000);
        }
      });
  }


  onCnfmPwdChange() {
    if (this.changePassword.updatePwd == '') {
      this.conformPassword = '';
      this.toastr.error('Please enter New Password');
    }
    else if (this.conformPassword == '') {
      this.toastr.error('Please enter Conform Password');
    }
    else {
      if (this.changePassword.updatePwd != this.conformPassword) {
        this.conformPassword = '';
        this.toastr.error('New Password & Confirm Password does not match');
      }
    }
  }

  onClear() {
    this.changePassword = new ChangePassword();
    this.conformPassword = '';
    let usrData = JSON.parse(sessionStorage.getItem('loginData'));
    this.changePassword.userID = usrData.id;
    // this.changePassword.password = usrData.password;
  }

  async logOut() {
    await super.LogOut();
  }
}
