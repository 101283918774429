import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs/Rx';
import { EntityService } from 'src/app/Services/entity.service';
import { HttpService } from 'src/app/Services/http.service';
import { PaginationService } from 'src/app/Services/pagination.service';
import { BaseComponent } from '../BaseComponent';
declare let $: any;
@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent extends BaseComponent implements OnInit {
  public loading = false;
  lstRole = []; mode = 'Add'; roleName = ''; status: boolean = true;
  roleForm: FormGroup;
  dtTrigger: Subject<any> = new Subject();
  constructor(private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected pageSvc: PaginationService,
    protected entitysvc: EntityService
  ) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
    this.formInet();
  }

  ngOnInit() {
    this.getAllRole();
  }

  async getAllRole() {
    this.loading = true; this.lstRole = [];
    jQuery('.dataTable').DataTable().destroy();
    jQuery('.dataTable').DataTable({ searching: false });
    await super
      .FetchData("Get", this.apiMethod.getAllRole, {})
      .catch(() => { this.httpSvc.handleError, (this.loading = false); })
      .then(() => {
        if (this.result != []) {
          this.lstRole = this.result.data;
          this.dtTrigger.next();
        }
        this.loading = false;
      });
  }

  addEditRole(item: any, mode: string) {
    this.mode = mode;
    if (mode == 'Add')
      this.formInet();
    else
      this.roleForm.patchValue({ id: item.id, name: item.name, deleted: item.deleted ? false : true, permission: item.permission });
    $('#myModal').show();
  }

  async saveRole() {
    let flag = false, isExist = false, count = 0;
    this.lstRole.forEach(e => {
      if ((e.name as string).toLowerCase() == this.roleName.trim().toLowerCase() && e.id == this.roleForm.value.id) {
        // count++;
      } else if ((e.name as string).toLowerCase() == this.roleName.trim().toLowerCase()) {
        flag = true;
        return;
      }
    });

    if (flag) {
      this.toastr.error('Role name already exist');
      this.roleName = '';
      return;
    }
    this.loading = true;
    this.roleForm.patchValue({ name: this.roleName.trim() });
    let entity = this.roleForm.getRawValue();
    entity.deleted = entity.deleted == true ? false : true;
    await super.FetchData("Post", this.apiMethod.saveRole, entity)
      .catch(() => { this.httpSvc.handleError, (this.loading = false); })
      .then(() => {
        if (this.result != []) {
          if (this.result.status == 'OK') {
            this.toastr.success(this.result.message);
            $(".close").click(); this.getAllRole();
          }
        }
        this.loading = false;
      });
  }

  formInet() {
    this.roleForm = this.formBuilder.group({
      id: [0],
      name: ['', [Validators.maxLength(20), Validators.required]],
      deleted: [true],
      permission: []
    });
  }

  ngOnDestroy() {
    $(".close").click();
  }

}
