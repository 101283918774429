import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs-compat';
import * as XLSX from 'xlsx';
import { EntityService } from 'src/app/Services/entity.service';
import { HttpService } from 'src/app/Services/http.service';
import { PaginationService } from 'src/app/Services/pagination.service';
import { Domain } from 'src/app/Entities/entity';
import { BaseComponent } from '../BaseComponent';
declare let $: any;
@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.css']
})
export class DomainComponent extends BaseComponent implements OnInit {
  public loading = false; domainForm: FormGroup; excelUploadForm: FormGroup; formData: FormData;
  excelFile: any = ''; btnText = 'Save'; createrById = 0;
  lstDomain: Array<any> = [];
  sampleEmailExcel = [];
  domain = new Domain();
  dtTrigger: Subject<any> = new Subject();

  constructor(private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected entitysvc: EntityService,
    protected pageSvc: PaginationService,
    private datepipe: DatePipe,) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
    if (sessionStorage.getItem('loginData')) {
      this.createrById = JSON.parse(sessionStorage.getItem('loginData')).id;
    }
  }

  ngOnInit() {
    this.domainInit();
    this.excelUploadForm = this.formBuilder.group({
      'excelFile': ['', [Validators.required]],
      'file': ['', [Validators.required]],
    });
    this.getAllDomain();
  }

  domainInit() {
    this.domainForm = this.formBuilder.group({
      'id': 0,
      'domainname': ['', [Validators.required]],
      'active': [true],
      'emailIds': ['', [Validators.required]],
      'createdBy': 0
    });
  }

  async getAllDomain() {
    this.loading = true, this.lstDomain = [];
    jQuery('.dataTable').DataTable().destroy();
    jQuery('.dataTable').DataTable({ searching: false });
    await super.FetchData("Get", `${this.apiMethod.getDomainById}/${this.createrById}`, {}, 1)
      .catch(() => { this.httpSvc.handleError, (this.loading = false); })
      .then(() => {
        if (this.result != []) {
          this.lstDomain = this.result.data;
          setTimeout(() => { this.dtTrigger.next(); }, 100);
        }
        this.loading = false;
      });
  }

  onEdit(item: any) {
    this.onClear();
    this.btnText = 'Update';
    this.domainForm.patchValue(item);
    this.domainForm.patchValue({ emailIds: item.mailids });
  }

  async saveUpdateDomain() {
    let emails = [];
    //#region email validate
    this.domainForm.patchValue({ domainname: this.domainForm.value.domainname.trim(), emailIds: this.domainForm.value.emailIds.trim() });
    if (this.domainForm.invalid) {
      this.toastr.warning('Please Enter All Mandatory Fields');
      return;
    }
    var regex = /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/;
    let lstEmails = [], lstWrongEmails = [];
    let lstSelectedEmails = this.domainForm.value.emailIds.trim().length > 0 ? this.domainForm.value.emailIds.trim().split(',') : [];
    lstSelectedEmails.forEach(e => {
      if (regex.test(e) && e != '')
        lstEmails.push(e);
      else
        lstWrongEmails.push(e);
    });
    this.domainForm.patchValue({ emailIds: lstEmails.join(',') });
    if (lstWrongEmails.length > 0) {
      this.toastr.warning('Please enter valid emails, emails are ' + lstWrongEmails.toString());
      return;
    }
    //#endregion end email Validate
    let entity = this.domainForm.getRawValue();
    // emails.push(entity.emailIds);
    entity.createdBy = this.createrById;
    // entity.emailIds = emails;
    entity.emailIds = entity.emailIds.split(',');
    this.loading = true;
    await super.FetchData('Post', `${this.apiMethod.saveOrUpdateDomain}`, entity, 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          if (this.result.status == 'OK') {
            this.toastr.success(this.result.message);
            $(".close").click();
            this.getAllDomain(); this.onClear();
          }
        }
        this.loading = false;
      });
  }

  async onSelectFile(event: any) {
    this.formData = new FormData;
    let file = event.target.files[0];
    if (file) {
      this.excelFile = file.name;
      this.excelUploadForm.get('excelFile').setValue(event.target.files[0].name);
      this.formData.append('file', file);
      await this.excelToJson(file);
    }
  }

  async excelToJson(file: any) {
    let validMail = []; this.sampleEmailExcel = [];
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (e: any) => {
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      validMail.push(data);
      validMail[0].forEach(e => {
        if (e.email != '' && e.email != undefined)
          this.sampleEmailExcel.push(e.email)
      });
      let lstExistEmails: any[] = this.domain.emailIds.trim().length > 0 ? this.domain.emailIds.trim().split(';') : [];
      lstExistEmails.push(...this.sampleEmailExcel);
      this.domain.emailIds = lstExistEmails.join(',');
    };
  }

  downloadEmailExcelFormatFile() {
    var jsonArray = [];
    jsonArray.push({
      sno: 1,
      email: '',
    });
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonArray);
    const workbook: XLSX.WorkBook = { Sheets: { 'Sheet1': worksheet }, SheetNames: ['Sheet1'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    });
    const url = window.URL.createObjectURL(data);
    window.location.href = url
  }

  onClear() {
    this.btnText = 'Save';
    this.domainInit();
    this.formData = new FormData();
    this.excelFile = '';
    this.excelUploadForm.patchValue({ excelFile: '' });
  }
  validateEmails() {
    var regex = /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/;
    let lstEmails = [], lstWrongEmails = [];
    let lstSelectedEmails = this.domain.emailIds.trim().length > 0 ? this.domain.emailIds.trim().split(',') : [];
    lstSelectedEmails.forEach(e => {
      if (regex.test(e) && e != '')
        lstEmails.push(e);
      else
        lstWrongEmails.push(e);
    });
    this.domain.emailIds = lstEmails.join(',');
    if (lstWrongEmails.length > 0) {
      this.toastr.warning('Please enter valid emails, emails are ' + lstWrongEmails.toString());
      return;
    }

  }

  nameValidate(event: any) {
    event.target.value = event.target.value.replace(/[^A-Za-z0-9. ]*/g, '');
  }

}
