import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private logInSource = new BehaviorSubject<boolean>(sessionStorage.loginData ? true : false);
  private spinnerSource = new BehaviorSubject<boolean>(false);
  private messageSource = new BehaviorSubject<string>('Please Wait..................');
  currentLogIn = this.logInSource.asObservable();
  loaderSpinner = this.spinnerSource.asObservable();
  loader_Message = this.messageSource.asObservable();

  constructor() { }

  changeMessage(isLogin: boolean) {
    this.logInSource.next(isLogin);
  }

  isSpinner(isSpinner: boolean) {
    this.spinnerSource.next(isSpinner);
  }

  loaderMessage(msg: string) {
    this.messageSource.next(msg);
  }
}
