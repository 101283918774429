import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Schedule } from 'src/app/Entities/entity';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/Services/http.service';
import { EntityService } from 'src/app/Services/entity.service';
import { BaseComponent } from 'src/app/Components/BaseComponent';
import { Subject } from 'rxjs';
import { ProHeaderComponent } from '../pro-header/pro-header.component';
import { PaginationService } from 'src/app/Services/pagination.service';
import { LoginService } from 'src/app/Services/login.service';
declare const $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [ProHeaderComponent]
})
export class LoginComponent extends BaseComponent implements OnInit {

  public loading = false;
  username = ''; password = ''; userId = '';
  off: boolean = false; validUser: boolean = true;
  query: any = {}; lstSamvaadFeatures = [];
  loginUser: FormGroup; forgotPwd: FormGroup;
  fieldTextType: boolean = false;

  constructor(private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected entitysvc: EntityService,
    private activeroute: ActivatedRoute,
    private home: ProHeaderComponent,
    protected pageSvc: PaginationService,
    private isLogin: LoginService
  ) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
  }

  ngOnInit() {
    this.contentLoad();
    this.loginUser = this.formBuilder.group({
      'username': ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"), Validators.maxLength(100)]],
      'password': ['', [Validators.required, Validators.maxLength(20), Validators.minLength(5)]]
    });

    this.forgotPwd = this.formBuilder.group({
      'userId': ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]]
    });

    this.loginFocus();
    ['token', 'logUsrData'].forEach(e => { sessionStorage.removeItem(e); });
    $('.modal-backdrop').remove();
    //this.setFocus('username');


    this.activeroute.queryParams.subscribe(p => {
      this.query = p;
      if (Object.keys(p).length == 0)
        return;
      else if (this.query.errors) {
        const errorMessages: Array<{ key: string, message: string }> = JSON.parse(this.query.errors);
        // this.toastr.error('', errorMessages[0].message);
        // alert(errorMessages[0].message);
        sessionStorage.error = JSON.stringify(errorMessages);
        this.route.navigate(['error-msg']);
      }
      else if (this.query.token) {
        this.getUserData(this.query.token);
      }
      else {
        // let queryString = window.atob(p.query).split('&').map(e => e.substring(e.indexOf('=') + 1, e.length));
        // [this.username, this.password] = ['rajesh98y@gmail.com', '12345'];
        [this.username, this.password] = [p.username, p.password];
        this.onSubmit();
      }
    });

  }
  get l() {
    return this.loginUser.controls;
  }

  get f() {
    return this.forgotPwd.controls;
  }

  async onSubmit() {
    if (!this.validateLogin())
      return;
    this.loading = true; sessionStorage.clear();
    let payLoad = `{"email":"${this.username.trim()}","password":"${this.password.trim()}"}`;
    super.FetchData('Post', this.apiMethod.login, payLoad)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result.data && this.result.data != []) {
        this.isLogin.changeMessage(true);

          this.httpSvc.isLogin = true;
          localStorage.setItem('samvaadUserLogin', JSON.stringify(payLoad));
          // document.getElementById('isShowSignSignUp1').style.display = 'none';
          // document.getElementById('isShowSignSignUp2').style.display = 'none';
          localStorage.username = this.result.data.name;

          sessionStorage.setItem('loginData', JSON.stringify(this.result.data));
          sessionStorage.setItem('jwtToken', JSON.stringify(this.result.data.jwtToken));
          this.httpSvc.loginUserDetails = this.result.data;
          this.httpSvc.userName = this.result.data.name;
          this.result.data.role.permission.forEach(e => this.httpSvc.permissionArry.push(e.name));
          if (this.result.data && this.result.data.email == 'admin@dhanushinfotech.net')
            this.httpSvc.permissionArry.push(...['Privileges', 'Role', 'Privilege', 'Role Privilege Mapping']);
          if (Object.keys(this.query).length != 0) {
            this.router.navigate(['/Home/subsclist']);
            this.loading = false;
          }
          else {
            if (this.result.data.role.name == 'admin')
              this.router.navigate(['/Home/schedule']);
            else if (this.result.data.role.name == 'IS')
              this.router.navigate(['/Home/approvalDomail']);
            else {
              if (this.result.data.subscribed || this.result.data.userType == 'EH')
                this.router.navigate(['/Home/schedule']);
              else
                this.router.navigate(['/Home/subsclist']);
            }
          }
        }
        this.loading = false;

      });
  }

  generatePwd() {
    this.loading = true;
    super.FetchData('Get', `${this.apiMethod.forgotPwd}/${this.userId}`, {})
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != [] && this.result.status == 'OK') {
          this.toastr.success(this.result.message);
          //this.toastr.success('Password generated successfully.')
          this.userId = '', $(".close").click();//$('#myModal').modal('hide');
        }
        this.loading = false;
        this.forgotPwd.reset();
      });
  }

  cancelFPassword() {
    this.userId = '';
  }

  contentLoad() {
    this.loading = true;
    super.FetchData('Get', `${this.apiMethod.getSamvaadFeatures}`, {})
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != [] && this.result.status == 'OK') {
          this.lstSamvaadFeatures = this.result.data;
        }
        this.loading = false;
      });
  }

  validateLogin(): boolean {
    this.validUser = true;
    if (this.username.trim() == '') {
      this.toastr.error('Please enter User Name.');
      return this.validUser = false;
    }
    if (this.username.trim() != '') {
      var regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!regex.test(this.username.trim())) {
        // if (this.loginUser.get('username').status == 'INVALID') {
        this.toastr.error('Please enter valid User Name.');
        this.username = ''; this.password = '';
        return this.validUser = false;
      }
    }
    if (this.password.trim() == '') {
      this.toastr.error('Please enter Password.');
      return this.validUser = false;
    }
    return this.validUser;
  }

  loginFocus() {

    (function ($) {
      "use strict";

      $('.input100').each(function () {
        $(this).on('blur', function () {
          if ($(this).val().toString().trim() != "") {
            $(this).addClass('has-val');
          }
          else {
            $(this).removeClass('has-val');
          }
        })
      })
      var input = $('.validate-input .input100');

      $('.validate-form').on('submit', function () {
        var check = true;

        for (var i = 0; i < input.length; i++) {
          if (validate(input[i]) == false) {
            showValidate(input[i]);
            check = false;
          }
        }
        return check;
      });


      $('.validate-form .input100').each(function () {
        $(this).focus(function () {
          hideValidate(this);
        });
      });

      function validate(input) {
        if ($(input).attr('type') == 'email' || $(input).attr('name') == 'email') {
          if ($(input).val().toString().trim().match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null) {
            return false;
          }
        }
        else {
          if ($(input).val().toString().trim() == '') {
            return false;
          }
        }
      }

      function showValidate(input) {
        var thisAlert = $(input).parent();
        $(thisAlert).addClass('alert-validate');
      }

      function hideValidate(input) {
        var thisAlert = $(input).parent();
        $(thisAlert).removeClass('alert-validate');
      }
    })(jQuery);
  }

  setFocus(elementName: string) {
    (<HTMLTextAreaElement>(document.getElementById(elementName))).focus();
  }

  getUserData(token: any) {
    this.loading = true;
    super.FetchData('Get', `${this.apiMethod.getUserData}?token=${token}`, {})
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result.data && this.result.data != []) {
          this.httpSvc.isLogin = true;

          sessionStorage.setItem('loginData', JSON.stringify(this.result.data));
          sessionStorage.setItem('jwtToken', JSON.stringify(this.result.data.jwtToken));
          this.httpSvc.loginUserDetails = this.result.data;
          this.httpSvc.userName = this.result.data.name;
          this.result.data.role.permission.forEach(e => this.httpSvc.permissionArry.push(e.name));
          if (this.result.data && this.result.data.email == 'admin@dhanushinfotech.net')
            this.httpSvc.permissionArry.push(...['Privileges', 'Role', 'Privilege', 'Role Privilege Mapping']);
          if (Object.keys(this.query).length != 0) {
            this.router.navigate(['/Home/subsclist']);
            this.loading = false;
          }
          else {
            if (this.result.data.role.name == 'admin')
              this.router.navigate(['/Home/user']);
            else if (this.result.data.role.name == 'IS')
              this.router.navigate(['/Home/approvalDomail']);
            else {
              if (this.result.data.subscribed || this.result.data.userType == 'EH')
                this.router.navigate(['/Home/schedule']);
              else
                this.router.navigate(['/Home/subsclist']);
            }
          }
        }
        this.loading = false;
      });
  }


  validateEmail(event: any) {
    let val = event.target.value;
    let exp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    // let exp = /^([\w\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    if (new RegExp(exp).test(val)) {
      this.username = val;
    } else {
      this.username = null;
      this.toastr.warning('Please enter valid email Id');
    }
  }

  get passMin() {
    return this.loginUser.get('password');
  }
  get passMax() {
    return this.loginUser.get('password');
  }

}
