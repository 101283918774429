import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/Services/http.service';
import { EntityService } from 'src/app/Services/entity.service';
import { BaseComponent } from 'src/app/Components/BaseComponent';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { TimepickerConfig } from 'ngx-bootstrap/timepicker';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PaginationService } from 'src/app/Services/pagination.service';

@Component({
  selector: 'app-owndomain',
  templateUrl: './owndomain.component.html',
  styleUrls: ['./owndomain.component.css'],
  providers: [DatePipe]
})

export class OwndomainComponent extends BaseComponent implements OnInit, OnDestroy {

  public loading = false; ownDomainForm: FormGroup; domain = ''; logo: File = null; fileName = 'Choose file'; fromData: FormData;
  jwttoken = ''; brandingPayment: any = {}; payUrl = ''; approvalStatus = ''; sub: any; isThereUserLogo = false;
  public approvalStatusList = ["Rejected", "Pending", "InProgress", "Completed"];
  userID: number = 0;
  isValidLogo: boolean = false;
  constructor(private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected entitysvc: EntityService,
    private datepipe: DatePipe,
    protected pageSvc: PaginationService,
    private http: HttpClient,
  ) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
  }
  ngOnDestroy(): void {
    clearInterval(this.sub);
  }

  ngOnInit() {
    let user = JSON.parse(sessionStorage.getItem('loginData'));
    this.userID = user.id;
    this.jwttoken = (user == undefined) ? '' : user.jwtToken;
    this.ownDomainForm = this.formBuilder.group({
      'userID': 0,
      'domain': [''],//['', [Validators.required, Validators.minLength(5), Validators.maxLength(10)]],
      'logo': ['']//['', [Validators.required]],
    });
    $(".custom-file-label").addClass("selected").html(this.fileName);
    this.getBrandingPayment();
    this.isThereUserLogo = user.customLogo != null ? true : false;
    this.ownDomainForm.controls['domain'].enable();
    if (user.customDomain != null)
      this.domain = user.customDomain;
    if (user.domainPayment) {
      this.ownDomainForm.controls['domain'].disable();
      document.getElementById('btnDomain').style.display = 'none';
    }
    // if (user.customLogo != null) {
    if (user.logoApproval) {
      // this.domain = user.customDomain;
      this.ownDomainForm.controls['logo'].disable();
      document.getElementById('btnCustomFile').style.display = 'none';
    }
    if (JSON.parse(sessionStorage.getItem('loginData')).customLogo != '' && JSON.parse(sessionStorage.getItem('loginData')).customLogo != null) {
      this.getDomainApprovalStatus();
      this.sub = setInterval(() => this.getDomainApprovalStatus(), 60000);
    } else {
      clearInterval(this.sub);
    }
  }

  get f() { return this.ownDomainForm.controls; }

  handleFileInput(files: FileList) {
    this.isValidLogo = false;
    this.logo = files.item(0);
    let _URL = window.URL || (window as any).webkitURL;
    // $("#file").change(function (e) {
    let img, isValid: boolean = false;
    if ((files[0])) {
      img = new Image();
      var objectUrl = _URL.createObjectURL(files[0]);
      img.onload = function () {
        // alert(this.width + " " + this.height);
        if (this.width == 150 && this.height == 33) {
          //isValid = true;
         }
        else
          isValid = false;
        // _URL.revokeObjectURL(objectUrl);
      };
      setTimeout(() => {
        if (isValid)
          this.toastr.error('File width and height should be 150px by 33px');
        else {
          // this.logo = files.item(0);
          this.isValidLogo = true;
          $(".custom-file-label").addClass("selected").html(files.item(0).name);
        }

      }, 50);
      img.src = objectUrl;
    }
    // });
    // this.logo = files.item(0);
    // $(".custom-file-label").addClass("selected").html(files.item(0).name);
  }

  async createOwnDomain(type: string = 'domain') {
    if (type == 'domain') {
      if (this.domain == '') {
        this.toastr.error('Please enter domain name');
        (<HTMLInputElement>document.getElementById('txtDomain')).focus();
        return;
      }
      else if (this.domain.length < 5) {
        this.toastr.error('Please enter minimum 5 digit Character');
        (<HTMLInputElement>document.getElementById('txtDomain')).focus();
        return;
      }
    }
    else {
      if (this.logo == null) {
        this.toastr.error('Please upload logo file');
        (<HTMLInputElement>document.getElementById('customFile')).focus();
        return;
      }
    }

    this.fromData = new FormData;
    this.fromData.append('userID', this.userID.toString());
    if (type == 'domain')
      this.fromData.append('domain', this.domain);
    else {
      if (this.isValidLogo)
        this.fromData.append('logo', this.logo);
      else
        return;
    }
    this.fromData.append('type', type);
    await this.http.post(`${this.httpSvc.BaseUrl}conference/jwt/user/brandinglogoanddomain`, this.fromData,
      { headers: new HttpHeaders({ Authorization: this.jwttoken }) }).
      subscribe(res => {
        let result: any = res;
        if (result.status == 'OK') {
          this.fromData = new FormData;
          this.domain = '', this.logo = null;
          if (type == 'logo')
            $(".custom-file-label").addClass("selected").html(this.fileName);
          this.toastr.success(result.message);

          this.payUrl = `${this.httpSvc.BaseUrl}conference/nojwt/usercustomize/payment/${this.userID}/${type}`;
          http://localhost:9991/conference/nojwt/usercustomize/payment/12/logo
          console.log(this.payUrl);
          (<HTMLAnchorElement>document.getElementById('payId')).href = this.payUrl;
          document.getElementById('payId').click();

          //this.paymentforDomain();
        }
        else this.toastr.error(result.message);
        this.loading = false;
      },
        err => {
          if (err.status == 401) {
            this.toastr.error(err.error.message);
            sessionStorage.clear();
            this.jwttoken = '';
            setTimeout(() => { this.router.navigate(['']); }, 1000);
          }
          else {
            this.toastr.error(err.error.message);
          }
          this.loading = false;
        }
      );
  }

  async getBrandingPayment() {
    this.loading = true; this.brandingPayment = {};
    await super.FetchData('Get', `user/getbrandingpayment`, {}, 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != {}) {
          this.brandingPayment = this.result.data;
        }
        this.loading = false;
      });
  }

  async paymentforDomain() {
    this.loading = true;
    await super.FetchData('Get', `usercustomize/payment/${this.userID}`, {})
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != {}) {
          this.toastr.success(this.result.message);
        }
        this.loading = false;
      });
  }

  async getDomainApprovalStatus() {
    await super.FetchData('Get', `user/getdomainapprovalstatus/${this.userID}`, {}, 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result.data != {}) {
          this.approvalStatus = this.result.data.domainApproval;
        }
      });
  }


}
