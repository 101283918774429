import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.css']
})
export class SocialLoginComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  socialLogin(type: string) {
    let socialurl = '';
    let url = location.hostname != 'localhost' ? `${location.origin}` : environment.apiUrl;
    if (url && url.includes('samvaad.pro')) {
      socialurl = url.replace('samvaad.pro', 'samvaadpro.com');
      // socialurl=url.includes('dev.samvaad.pro')?url.replace('dev.samvaad.pro','dev.samvaadpro.com'):url.replace('samvaad.pro','samvaadpro.com');
    }
    window.location.replace(`${socialurl}/conference/oauth2/authorize/${type}?redirect_uri=${type}`);
  }

}
