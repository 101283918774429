import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { User } from "src/app/Entities/entity";
import { ToastrService } from "ngx-toastr";
import {
  FormGroup,
  Validators,
  FormControl,
  FormBuilder,
} from "@angular/forms";
import { Router } from "@angular/router";
import { HttpService } from "src/app/Services/http.service";
import { EntityService } from "src/app/Services/entity.service";
import { BaseComponent } from "src/app/Components/BaseComponent";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { PaginationService } from "src/app/Services/pagination.service";
declare let $: any;

@Component({
  selector: "app-subscribe-user",
  templateUrl: "./subscribe-user.component.html",
  styleUrls: ["./subscribe-user.component.css"],
})
export class SubscribeUserComponent extends BaseComponent implements OnInit {
  public loading = false;
  subcUserForm: FormGroup;
  name: string = "";
  password: string = "";
  confpassword = "";
  hederText = "";
  roleId = "0";
  createrId = 0;
  subscUser = new User();
  lstUser = [];
  lstRole = [];
  lstUserRole = [];
  btnText = "Save";
  otp = "";
  validOTP = "";
  generateOtp = false;
  subscriptionPackList = [];
  subscriptionPackDetails = [];
  subscriptionPack = "";
  payUrl = "";
  currentTab = 0; lstSamvaadFeatures = [];
  isMonthely: boolean = false; isSelectedPack: boolean = false;
  lstNewSubscriptionPack: Array<any> = [];
  constructor(
    private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected pageSvc: PaginationService,
    protected entitysvc: EntityService
  ) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
  }

  ngOnInit() {
    this.currentTab = 0;
    this.validateSubUser(); this.contentLoad();
    //this.validateForm();
    this.subcUserForm = this.formBuilder.group({
      id: 0,
      name: '',
      password: ["", [Validators.minLength(8)]],
      // password: ["", [Validators.minLength(8), Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*([^a-zA-Z\d\s])).{8,}$')]],
      confpassword: ["", [Validators.minLength(8)]],
      role: {
        id: [0, [Validators.required]],
      },
      mobileNO: ["", [Validators.required, Validators.minLength(10)]],
      status: true,
      address: ["", [Validators.maxLength(100)]],
      email: new FormControl("", [
        Validators.required, Validators.maxLength(100),
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$"),
      ]),
      createdBy: {
        id: 0,
      },
      otp: ["", [Validators.required, Validators.minLength(4)]],
      validOTP: ["", [Validators.required]],
    });
    this.validatePattern();
    this.getAllRole();
    this.getSubscribePack();
    this.subscUser.role.id = 2;
  }

  get f() {
    return this.subcUserForm.controls;
  }

  async getAllRole() {
    this.loading = true;
    this.lstRole = [];
    await super
      .FetchData("Get", this.apiMethod.getAllRole, {})
      .catch(() => {
        this.httpSvc.handleError, (this.loading = false);
      })
      .then(() => {
        if (this.result != []) {
          this.lstRole = this.result.data;
          this.lstUserRole = this.result.data;
        }
        this.loading = false;
      });
  }

  async onUserIdCheck() {
    if (this.subscUser.email != "") {
      await super
        .FetchData(
          "Get",
          `${this.apiMethod.checkExistEmail
          }${this.subscUser.email.toLowerCase()}/${this.subscUser.id}`,
          {}
        )
        .catch(() => {
          this.httpSvc.handleError, (this.loading = false);
        })
        .then(() => {
          if (this.result != []) {
            if (this.result.status != "OK") {
              this.subscUser.email = "";
              return false;
            }
          }
          this.loading = false;
        });
    }
  }

  async generateOTP() {
    if (this.subscUser.email == '') {
      this.toastr.warning('Please enter email.');
      this.subscUser.mobileNO == '';
      this.subcUserForm.patchValue({ mobileNO: '' });
      document.getElementById('inputEmail').focus();
      return;
    }

    if (this.subscUser.mobileNO == '') return;

    if (!window.confirm("Do you want to send OTP")) return;
    this.loading = true;
    this.generateOtp = false;
    await super
      .FetchData(
        "Get",
        `${this.apiMethod.generateOtp}${this.subscUser.mobileNO}/${this.subscUser.email}`,
        {}
      )
      .catch(() => {
        this.httpSvc.handleError, (this.loading = false);
      })
      .then(() => {
        if (this.result != []) {
          if (this.result.status == "OK") {
            this.toastr.success(this.result.message);
            this.generateOtp = true;
          }
        }
        this.loading = false;
      });
  }

  async validateOTP() {


    if (!this.subcUserForm.controls["otp"].valid) return;

    if (!this.generateOtp) {
      this.toastr.warning("Please generate OTP.");
      this.otp = "";
      return;
    }
    this.loading = true;
    await super
      .FetchData(
        "Get",
        `${this.apiMethod.validateOtp}${this.subscUser.mobileNO}/${this.otp}`,
        {}
      )
      .catch(() => {
        this.httpSvc.handleError, (this.loading = false);
      })
      .then(() => {
        if (this.result != []) {
          if (this.result.status == "OK") {
            this.toastr.success(this.result.message);
            this.validOTP = "OK";
          } else {
            ////this.toastr.error(this.result.message);
            this.otp = "";
          }
        }
        this.loading = false;
      });
  }

  async subscribeUserSaveAndPayment(item: any) {
    debugger
    if (!this.validatePass) return;
    this.loading = true;
    this.subscUser.createdBy = null;
    this.subscUser.name = this.subcUserForm.get("name").value;//this.subscUser.name.trim();
    this.subscUser.email = this.subscUser.email.toLowerCase();
    await super
      .FetchData(
        "Post",
        this.apiMethod.saveUser,
        JSON.parse(JSON.stringify(this.subscUser))
      )
      .catch(() => {
        this.httpSvc.handleError, (this.loading = false);
      })
      .then(() => {
        if (this.result != []) {
          if (this.result.data != null && this.result != undefined) {
            if (this.result.status == "OK") {
              this.toastr.success(this.result.message);
              this.httpSvc.subscribeUser = this.result.data;
              sessionStorage.setItem(
                "loginData",
                JSON.stringify(this.result.data)
              );
              this.startedPayment(item);
              this.subscUser = new User();
              this.confpassword = "";
              this.otp = "";
              this.validOTP = "";
              this.generateOtp = false;
              // this.router.navigate(['/subsclist']);
            }
          }
        }
        this.loading = false;
      });
  }

  validatePass(): boolean {
    let result = true;
    if (this.subscUser.password == "") {
      this.toastr.error("Please enter Password");
      return (result = false);
    }
    if (this.confpassword == "") {
      this.toastr.error("Please enter Confirm Password");
      return (result = false);
    }
    if (this.subscUser.password != "" && this.confpassword != "") {
      if (this.subscUser.password != this.confpassword) {
        this.toastr.error("Password and Confirm password is mismatched.");
        this.confpassword = "";
      }
      return (result = false);
    }
    return result;
  }

  async getSubscribePack() {
    this.loading = true;
    this.subscriptionPackList = [];
    await super
      .FetchData("Get", `${this.apiMethod.getSubscriptionPack}`, {})
      .catch(() => {
        this.httpSvc.handleError, (this.loading = false);
      })
      .then(() => {
        if (this.result.data != []) {
          let subscribePack = this.result.data;
          Object.keys(subscribePack).forEach((e) => {
            this.subscriptionPackList.push({
              itemName: subscribePack[e][0].duration.replace(/([A-Z])/g, ' $1'),
              itemList: subscribePack[e],
            });
          });
        }
        this.loading = false;
      });
  }

  onChangeSubscribe(item: any) {
    if (item != "") {
      let lst = this.subscriptionPackList.filter((m) => m.itemName == item);
      this.subscriptionPackDetails = lst;
    } else this.subscriptionPackDetails = [];
  }

  async startedPayment(item: any) {
    debugger
    let [payload, userData] = [
      {},
      JSON.parse(sessionStorage.getItem("loginData")),
    ];
    let url =
      location.hostname != "localhost"
        ? `${location.origin}/conference/`
        : environment.apiUrl;
    if (item.duration == "TrialPeriod") {
      this.payUrl = `${url}nojwt/payment/activateFreeTrail/${userData.id}/${item.id}`;
      this.httpSvc.newGet(this.payUrl).subscribe(
        (res) => {
          const result = res.json();
          if (result.status == "OK") {
            this.toastr.success(result.message);
            this.route.navigate(["/login"]).then(() => {
              // window.location.reload();
              sessionStorage.removeItem("loginData");
            });
          }
        },
        (err) => {
          const errResult = err.json();
          this.toastr.error(errResult.message);
        }
      );
    } else {
      this.payUrl = `${url}nojwt/payment/startPaymentB/${userData.id}/${item.id}`;
      (<HTMLAnchorElement>document.getElementById("payId")).href = this.payUrl;
      document.getElementById("payId").click();
    }
  }

  validateSubUser() {
    //  document.addEventListener("DOMContentLoaded", function (event) {
    this.currentTab = 0;
    // this.showTab(this.currentTab);
    //});
  }

  showTab(n) {
    var x = document.getElementsByClassName("tab");
    // x[n].style.display = "block";
    //x[n].classList.add("block");
    $(x[n]).css({ 'display': 'block' });
    if (n == 0) {
      document.getElementById("prevBtn").style.display = "none";
    } else {
      document.getElementById("prevBtn").style.display = "inline";
    }
    if (n == x.length - 1) {
      document.getElementById("nextBtn").innerHTML = "Submit";
      document.getElementById("nextBtn").style.display = "none";
    } else {
      document.getElementById("nextBtn").style.display = "inline";
      document.getElementById("nextBtn").innerHTML = "Next";
    }
    this.fixStepIndicator(n);
  }

  fixStepIndicator(n) {
    var i,
      x = document.getElementsByClassName("step");
    for (i = 0; i < x.length; i++) {
      x[i].className = x[i].className.replace(" active", "");
    }
    x[n].className += " active";
  }

  validateForm() {
    var x,
      y,
      i,
      valid = true;
    x = document.getElementsByClassName("tab");
    y = x[this.currentTab].getElementsByTagName("input");
    for (i = 0; i < y.length; i++) {
      if (y[i].value == "") {
        y[i].className += " invalid";
        valid = false;
      }
    }
    if (valid) {
      document.getElementsByClassName("step")[this.currentTab].className +=
        " finish";
    }
    return valid;
  }

  nextPrev(n) {
    var x = document.getElementsByClassName("tab");
    if (n == 1 && !this.validateForm()) return false;
    // x[this.currentTab].style.display = "none";
    //x[this.currentTab].classList.add("none");
    $(x[this.currentTab]).css({ 'display': 'none' });
    this.currentTab = this.currentTab + n;
    if (this.currentTab >= x.length) {
      document.getElementById("nextprevious").style.display = "none";
      document.getElementById("all-steps").style.display = "none";
      document.getElementById("register").style.display = "none";
      document.getElementById("text-message").style.display = "block";
    }
    this.showTab(this.currentTab);
  }

  contentLoad() {
    this.loading = true;
    super.FetchData('Get', `${this.apiMethod.getSamvaadFeatures}`, {})
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != [] && this.result.status == 'OK') {
          this.lstSamvaadFeatures = this.result.data;
          console.log(this.lstSamvaadFeatures);
        }
        this.loading = false;
      });
  }

  validatePattern() {
    this.getName.valueChanges.subscribe(val => {
      let v = /[^a-zA-Z .\-\/]/
      if (v.test(val)) {
        this.subcUserForm.controls['name'].setValue(val.substr(0, val.length - 1));
        if (val.length > 50) {
          this.getName.setValue(val.substr(0, 50))
        }
      }
    });
    this.getPassword.valueChanges.subscribe(val => {
      if (val.length > 20) {
        this.getPassword.setValue(val.substr(0, 20))
      }
    });


    this.getMobile.valueChanges.subscribe(val => {
      let v = /[^0-9]+$/
      if (v.test(val)) {
        this.subcUserForm.controls['mobileNO'].setValue(val.substr(0, val.length - 1));
        if (val.length > 10) {
          this.getMobile.setValue(val.substr(0, 10))
        }

      }
    });
    this.getMobile.valueChanges.subscribe(val => {
      let v = /[^0-9]+$/
      if (v.test(val)) {
        this.subcUserForm.controls['otp'].setValue(val.substr(0, val.length - 1));
        if (val.length > 4) {
          this.getOtp.setValue(val.substr(0, 4))
        }

      }
    });
  }
  get getName() {
    return this.subcUserForm.controls['name'];
  }
  get getPassword() {
    return this.subcUserForm.controls['password'];
  }
  get getMobile() {
    return this.subcUserForm.controls['mobileNO'];
  }
  get getOtp() {
    return this.subcUserForm.controls['otp'];
  }

  toggelPack(event) {
    this.isSelectedPack = event.target.value == '' ? false : true;
    this.isMonthely = event.target.value == 'Annually' ? false : true;
    this.loading = true;
    let Payload = `{"duration":"${event.target.value}"}`
    super.FetchData('Post', `${this.apiMethod.getNewSubscriptionPack}`, Payload)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != [] && this.result.status == 'OK') {
          this.lstNewSubscriptionPack = this.result.data;
        }
        this.loading = false;
      });

  }

  numberWithCommas(price: any) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  checkPassword() {

    var myInput: HTMLInputElement = document.getElementById("psw") ? document.getElementById("psw") : {} as any;
    var letter: any = document.getElementById("letter");
    var capital: any = document.getElementById("capital");
    var number: any = document.getElementById("number");
    var length: any = document.getElementById("length");
    var special: any = document.getElementById('specialCharacter');


    var Character = /[!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/;
    if (myInput.value.match(Character)) {
      special.classList.remove("invalid");
      special.classList.add("valid");
    } else {
      special.classList.remove("valid");
      special.classList.add("invalid");
    }

    var lowerCaseLetters = /[a-z]/g;
    if (myInput.value.match(lowerCaseLetters)) {
      letter.classList.remove("invalid");
      letter.classList.add("valid");
    } else {
      letter.classList.remove("valid");
      letter.classList.add("invalid");
    }

    // Validate capital letters
    var upperCaseLetters = /[A-Z]/g;
    if (myInput.value.match(upperCaseLetters)) {
      capital.classList.remove("invalid");
      capital.classList.add("valid");
    } else {
      capital.classList.remove("valid");
      capital.classList.add("invalid");
    }

    // Validate numbers
    var numbers = /[0-9]/g;
    if (myInput.value.match(numbers)) {
      number.classList.remove("invalid");
      number.classList.add("valid");
    } else {
      number.classList.remove("valid");
      number.classList.add("invalid");
    }

    // Validate length
    if (myInput.value.length >= 8) {
      length.classList.remove("invalid");
      length.classList.add("valid");
    } else {
      length.classList.remove("valid");
      length.classList.add("invalid");
    }
  }

}

