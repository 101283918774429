import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { promise } from 'protractor';
import { Subject } from 'rxjs/Rx';
import { EntityService } from 'src/app/Services/entity.service';
import { HttpService } from 'src/app/Services/http.service';
import { PaginationService } from 'src/app/Services/pagination.service';
import { BaseComponent } from '../BaseComponent';
declare let $: any;

@Component({
  selector: 'app-role-permission-mapping',
  templateUrl: './role-permission-mapping.component.html',
  styleUrls: ['./role-permission-mapping.component.css']
})
export class RolePermissionMappingComponent extends BaseComponent implements OnInit {

  public loading = false;
  lstRoleHavePermission = []; lstRole = []; lstAllPermission = []; checkList = []; lstpermissionByRole = [];
  checkedList: number[] = []; permissionId = [];
  mode = 'Add'; roleName = ''; status: boolean = true; roleId = 0;
  rolePermissionForm: FormGroup;
  dtTrigger: Subject<any> = new Subject();
  constructor(private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected pageSvc: PaginationService,
    protected entitysvc: EntityService
  ) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
    this.formInet();
  }

  ngOnInit() {
    this.getRoleHavePermission();
  }

  async getRoleHavePermission() {
    this.loading = true; this.lstRoleHavePermission = [];
    jQuery('.dataTable').DataTable().destroy();
    jQuery('.dataTable').DataTable({ searching: false });
    await super.FetchData("Get", this.apiMethod.getRoleWithPermission, {}, 1)
      .catch(() => { this.httpSvc.handleError, (this.loading = false); })
      .then(() => {
        if (this.result != []) {
          this.lstRoleHavePermission = this.result.data;
          this.dtTrigger.next();
        }
        this.loading = false;
      });
  }

  async getRoleNotHavePermission() {
    this.loading = true; this.lstRole = [];
    await super.FetchData("Get", this.apiMethod.getRoleWithOutPermission, {}, 1)
      .catch(() => { this.httpSvc.handleError, (this.loading = false); })
      .then(() => {
        if (this.result != []) {
          this.lstRole = this.result.data;
        }
        this.loading = false;
      });
  }

  async getAllRole() {
    this.loading = true; this.lstRole = [];
    await super.FetchData("Get", this.apiMethod.getAllRole, {})
      .catch(() => { this.httpSvc.handleError, (this.loading = false); })
      .then(() => {
        if (this.result != []) {
          this.lstRole = this.result.data;
        }
        this.loading = false;
      });
  }

  async getAllPermission() {
    this.loading = true; this.lstAllPermission = [];
    await super.FetchData("Get", this.apiMethod.getAllPermission, {}, 1)
      .catch(() => { this.httpSvc.handleError, (this.loading = false); })
      .then(() => {
        if (this.result != []) {
          this.lstAllPermission = this.result.data;
        }
        this.loading = false;
      });
  }

  addEditRolePermission(item: any, mode: string) {
    $('#defaultChecked_Modal0').prop('checked', false);
    this.mode = mode; this.permissionId = []; this.checkList = [];
    this.getAllPermission();
    if (mode == 'Add') {
      this.getRoleNotHavePermission();
      this.formInet();
    }
    else {
      Promise.all([this.getAllRole()]);
      this.roleId = item.id;
      this.status = item.deleted ? false : true;
      this.lstpermissionByRole = item.permission;
      this.lstpermissionByRole.forEach(e => { this.permissionId.push(e.id) });
      this.checkList = this.permissionId;
    }
  }

  saveRolePermission() {
    if (this.mode == 'Add' && this.roleId == 0) {
      this.toastr.error('Please select Role');
      return;
    }
    if (this.checkList.length == 0) {
      this.toastr.error('Please select atleast one Privilege.');
      return;
    }
    this.loading = true;
    let entity = { 'roleID': this.roleId, 'permissions': this.checkList }
    super.FetchData('Post', this.apiMethod.saveRolePermission, entity, 1)
      .catch(() => { this.httpSvc.handleError, (this.loading = false); })
      .then(() => {
        if (this.result.status == 'OK') {
          this.toastr.success(this.result.message);
          this.getRoleHavePermission();
        }
        this.loading = false;
      });
    $('.close').click();
    this.checkList = []; this.permissionId = [];
  }

  formInet() {
    this.rolePermissionForm = this.formBuilder.group({
      id: [0],
      role: ['', [Validators.required]]
    });
  }

  check_AllPrivilages(status: any) {
    if (status.checked) {
      this.checkList = [];
      for (const iterator of this.lstAllPermission) {
        this.checkList.push(iterator.id);
      }
    }
    else {
      if (this.mode == 'Update') {
        setTimeout(() => {
          let [checkedArr, uncheckedArr] = [this.lstAllPermission.filter(v => this.checkList.includes(v.id)), this.lstAllPermission.filter(v => !this.checkList.includes(v.id))];
          this.lstAllPermission = JSON.parse(JSON.stringify([...checkedArr, ...uncheckedArr]));
        }, 100);
      }
    }
  }

  checkbox_checked(isCheck: boolean, id: any) {
    if (this.mode == 'Update' && isCheck == false)
      isCheck = this.permissionId.includes(id);
    return isCheck;
  }

  checkUncheckEntity(item: any, id: number) {
    if (item.checked) {
      this.checkList.push(id);
    } else if (this.checkList.includes(id)) {
      this.checkList.splice(this.checkList.indexOf(id), 1);
    }
    this.checkList.sort((a, b) => a - b);
  }

  // setDefaultValues() {
  //   this.checkList = (this.mode == 'Update') ? this.entity.permission_id.slice(0) : [];
  //   this.checkedList = (this.mode == 'Update') ? this.entity.id.slice(0) : [];
  //   if ((!this.records1) && (this.permissionList.length == 0))
  //     Promise.all([this.getAllPermission(), this.getAllRoles()]).then(() => { this.permissionList = JSON.parse(JSON.stringify(this.newRoleList)); this.includeUser(); });
  //   else {
  //     this.permissionList = JSON.parse(JSON.stringify(this.newRoleList));
  //     this.includeUser();
  //   }
  //   $('#myModal').modal('show');
  //   if (this.mode == 'Update') {
  //     setTimeout(() => {
  //       let [checkedArr, uncheckedArr] = [this.permissionList.filter(v => this.checkList.includes(v.id)), this.permissionList.filter(v => !this.checkList.includes(v.id))];
  //       this.permissionList = JSON.parse(JSON.stringify([...checkedArr, ...uncheckedArr]));
  //     }, 100);
  //   }
  // }

  ngOnDestroy() {
    $(".close").click();
  }


}
