import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.css']
})
export class ErrorMessageComponent implements OnInit {
  errorMsg: string = '';
  constructor() { }

  ngOnInit() {
    const err = sessionStorage.error || '';
    if (err) {
      this.errorMsg = JSON.parse(err)[0].message;

      if (this.errorMsg == 'The meeting ID that you supplied did not match any existing meetings')
        this.errorMsg = 'The Host has not joined the session yet!';
      sessionStorage.clear();
    }
  }

}
