import { Component, Input, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-instant-meeting',
  templateUrl: './instant-meeting.component.html',
  styleUrls: ['./instant-meeting.component.css']
})
export class InstantMeetingComponent implements OnInit,OnDestroy {
  @Output() closeInstantMeetingEvent: EventEmitter<any> = new EventEmitter<any>();
  enableAudio: boolean = false;
  enableVideo: boolean = false;
  localStream_Video: any;
  localStream_Audio: any;

  constructor(private toastr: ToastrService,) { }
  ngOnDestroy(): void {
    this.createMeeting(true);
    // throw new Error('Method not implemented.');
  }

  ngOnInit() {
    this.enableOrDisableVideo();
  }

  enableOrDisableAudio() {
    if (this.enableAudio) {
      this.localStream_Audio.getAudioTracks()[0].stop();
      (document.querySelector('#audio') as HTMLAudioElement).src = '';
      this.enableAudio = false;
    }
    else if (!this.enableAudio)
      this.enableOrDisableDevice({ audio: true }, false);
  }

  enableOrDisableVideo() {
    if (this.enableVideo) {
      this.localStream_Video.getVideoTracks()[0].stop();
      (document.querySelector('#video') as HTMLVideoElement).src = '';
      this.enableVideo = false;
    }
    else if (!this.enableVideo)
      this.enableOrDisableDevice({ video: true }, true);
  }

  enableOrDisableDevice(newObject, isVideo: boolean = true) {
    let videoOrAudio = null;
    if (isVideo)
      videoOrAudio = (document.querySelector('#video') as HTMLVideoElement);
    else
      videoOrAudio = (document.querySelector('#audio') as HTMLAudioElement);

    navigator.mediaDevices.getUserMedia(newObject)
      .then(stream => {
        if (isVideo) {
          this.enableVideo = !this.enableVideo;
          this.localStream_Video = stream;
        }
        else {
          this.enableAudio = !this.enableAudio;
          this.localStream_Audio = stream;
        }
        videoOrAudio.srcObject = stream;
      })
      .catch((err: any) => {
        console.log(err);
        this.toastr.warning(err.message, "Instant Meeting")
      });
  }

  createMeeting(cancelMeeting: boolean = false) {
    const meetingDetails = { showInstantMeeting: false, enableAudio: this.enableAudio, enableVideo: this.enableVideo, cancelMeeting: cancelMeeting };
    this.closeInstantMeetingEvent.emit(meetingDetails);

    if (this.enableVideo) {
      this.localStream_Video.getVideoTracks()[0].stop();
      (document.querySelector('#video') as HTMLVideoElement).src = '';
    }

    if (this.enableAudio) {
      this.localStream_Audio.getAudioTracks()[0].stop();
      (document.querySelector('#audio') as HTMLAudioElement).src = '';
    }

  }

  gotoBack() {
    this.createMeeting(true);
  }

}
