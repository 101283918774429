import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Key } from 'protractor';
import { EntityService } from 'src/app/Services/entity.service';
import { HttpService } from 'src/app/Services/http.service';
import { PaginationService } from 'src/app/Services/pagination.service';
import { BaseComponent } from '../BaseComponent';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.css']
})
export class ChatListComponent extends BaseComponent implements OnInit {
  public loading = false;
  chatList: Array<any> = [];
  meetingId: string = '';
  constructor(protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected pageSvc: PaginationService,
    protected entitysvc: EntityService,
    private activeroute: ActivatedRoute,
    private datepipe: DatePipe) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
  }

  ngOnInit() {
    this.activeroute.params.subscribe((params) => {
      this.meetingId = params["id"];
    });
    this.getChatList();
  }

  async getChatList() {
    this.loading = true; let lstChat = [];
    await super.FetchData("Get", `${this.apiMethod.getAllChat}${this.meetingId}`, {}, 5)
      .catch(() => { this.httpSvc.handleError; this.loading = false; })
      .then(() => {
        if (this.result.data) {
          this.chatList = this.result.data;
          // lstChat.forEach(m => {
          //   this.chatList.push({
          //     meetingDateTime: Object.keys(m)[0],
          //     internalmeetingid: Object.values(m)[0][0].internalmeetingid,
          //     chat: Object.values(m)[0][0].chat
          //   })
          // })
        }
        this.loading = false;
      });
  }
  getName(name: string) {
    return name.charAt(0).toUpperCase();
  }
}
