import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { count } from 'rxjs-compat/operator/count';
import { Subject } from 'rxjs/Rx';
import { EntityService } from 'src/app/Services/entity.service';
import { HttpService } from 'src/app/Services/http.service';
import { PaginationService } from 'src/app/Services/pagination.service';
import { BaseComponent } from '../BaseComponent';

declare let $: any;


@Component({
  selector: 'app-userdashboard',
  templateUrl: './userdashboard.component.html',
  styleUrls: ['./userdashboard.component.css'],
  providers: [DatePipe]

})
export class UserdashboardComponent extends BaseComponent implements OnInit {
  public loading = false;
  lstAudit = [];
  newusers: number = 0;
  notloginuser: number = 0;
  onetimeusers: number = 0;
  repeatusers: number = 0;
  fromDate: Date = new Date();
  toDate: Date = new Date();
  todayDate: Date = new Date();
  lstSubscribeHistor = [];

  isFirstTime: boolean = false;
   userdashboard: FormGroup;
   userType=''; userId=0;
  dtTrigger: Subject<any> = new Subject();
  constructor(private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected pageSvc: PaginationService,
    protected entitysvc: EntityService,
    private datepipe: DatePipe

  )
  {
   super(toastr, router, httpSvc, entitysvc, pageSvc);

  }


  ngOnInit() {
    this.userdashboard = this.formBuilder.group({
      'todate': ['', [Validators.required]],
      'fromdate': ['', [Validators.required]]
    });
    let user = JSON.parse(sessionStorage.getItem('loginData'));
    this.userType = user.userType;this.userId = user.id;
    console.log(this.userType+ ' ' + this.userId);
    this.getauditData();
    this.toDate = new Date();
    this.expiryDateTime = JSON.parse(sessionStorage.getItem('loginData')).expiryDateTime == null ? '' : JSON.parse(sessionStorage.getItem('loginData')).expiryDateTime;
  }
  dateChange(event: any) {
    if (this.isFirstTime)
      this.toDate = (this.toDate < (event)) ? null : this.toDate;
    this.isFirstTime = true;
  }
  async getauditData() {
    this.loading = true; this.lstSubscribeHistor = []; let payLoadEntUser :any;
    // jQuery('.dataTable').DataTable().destroy();
    // jQuery('.dataTable').DataTable({ searching: false });
    let url = this.userType=="EA"?this.apiMethod.getLogindataForEnterpriseUser:this.apiMethod.getLoginAudit;
    let payLoad = `{
  "fromdate":"${this.datepipe.transform(this.fromDate, 'yyyy-MM-dd')}",
  "todate":"${this.datepipe.transform(this.toDate, 'yyyy-MM-dd')}"}`;

  if(this.userType=="EA")
    payLoadEntUser = JSON.parse(payLoad),payLoadEntUser.userID = this.userId;
  else
    payLoadEntUser = JSON.parse(payLoad);

    await super.FetchData('Post', url, payLoadEntUser,1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          this.lstSubscribeHistor = this.result.data;
          this.newusers=this.result.data.newusers;
          this.notloginuser=this.result.data.notloginuser;
          this.repeatusers=this.result.data.repeatusers;
          this.onetimeusers=this.result.data.onetimeusers;

        }
        this.loading = false;
      });
  }


}
