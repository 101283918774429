import { Component, OnInit } from '@angular/core';
import { Subscribe, User } from 'src/app/Entities/entity';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/Services/http.service';
import { EntityService } from 'src/app/Services/entity.service';
import { BaseComponent } from 'src/app/Components/BaseComponent';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { PaginationService } from 'src/app/Services/pagination.service';
declare let $: any;

@Component({
  selector: 'app-subscribe-list',
  templateUrl: './subscribe-list.component.html',
  styleUrls: ['./subscribe-list.component.css'],
  providers: [DatePipe]
})
export class SubscribeListComponent extends BaseComponent implements OnInit {

  public loading = false;
  subcUserDetailsForm: FormGroup; confpassword = ''; lstUserRole = []; payUrl: string = '';
  lstSubscribePack = []; subscribe = new Subscribe(); subcUserDetails = new User();
  dtTrigger: Subject<any> = new Subject();
  constructor(private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected entitysvc: EntityService,
    protected pageSvc: PaginationService,
    private datepipe: DatePipe
  ) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
  }

  ngOnInit() {
    this.subcUserDetailsForm = this.formBuilder.group({
      'id': 0,
      'name': ['', [Validators.maxLength(100), Validators.required]],
      'password': '',
      'confpassword': '',
      'role': {
        'id': [0, [Validators.required]],
      },
      'mobileNO': ['', [Validators.required]],
      'status': true,
      'address': '',
      'email': new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]),
    });
    this.getSubscribePack();
    $('#collapseOne1').attr("aria-expanded", "true");
    //s$('#collapseOne1').removeClass('collapsed');
  }

  async getSubscribePack() {
    this.loading = true; this.lstSubscribePack = [];
    await super.FetchData('Get', `${this.apiMethod.getSubscriptionPack}/${JSON.parse(sessionStorage.getItem('loginData')).id}`, {})
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result.data != []) {
          let subscribePack = this.result.data;
          Object.keys(subscribePack).forEach(e => {
            this.lstSubscribePack.push({
              itemName: subscribePack[e][0].duration.replace(/([A-Z])/g, ' $1'),
              itemList: subscribePack[e],
            });
          });
        }
        this.loading = false;
        //console.log(this.lstSubscribePack);
      });
  }

  async getAllRole() {
    this.loading = true; this.lstUserRole = [];
    await super.FetchData('Get', this.apiMethod.getAllRole, {})
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          this.lstUserRole = this.result.data;
        }
        this.loading = false;
      });
  }

  changeClass() {
    $('.collapse').on('shown.bs.collapse', function () {
      $(this).parent().find('.fa-plus').removeClass('fa-plus').addClass('fa-minus');
    }).on('hidden.bs.collapse', function () {
      $(this).parent().find(".fa-minus").removeClass("fa-minus").addClass("fa-plus");
    });
  }

  getSubcUserDetails() {
    this.getAllRole();
    let user = this.httpSvc.subscribeUser ? this.httpSvc.subscribeUser : JSON.parse(sessionStorage.getItem('loginData'));
    this.subcUserDetails = user as User;
    $('#sunscUserModal').show();
  }

  async subscribePack(item: any) {
    // this.httpSvc.subscribeUser
    this.loading = true; let date = new Date();
    let day = item.duration == 'TwelveMonths' ? 365 : item.duration == 'SixMonths' ? 180 : item.duration == 'OneMonth' ? 30 : 1;
    this.subscribe.subscriptionPack = item;
    this.subscribe.user = this.httpSvc.subscribeUser ? this.httpSvc.subscribeUser : JSON.parse(sessionStorage.getItem('loginData'));
    this.subscribe.startDateTime = this.datepipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
    this.subscribe.endDateTime = this.datepipe.transform(new Date(date.setDate(date.getDate() + +day)), 'yyyy-MM-dd HH:mm:ss');
    await super.FetchData('Post', this.apiMethod.subscripePack, JSON.parse(JSON.stringify(this.subscribe)))
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          if (this.result.status == 'OK') {
            this.toastr.success(this.result.message);
            this.httpSvc.subscribeUser = '';
            this.router.navigate(['/login']);
          }
        }
        this.loading = false;
      });
  }

  async startedPayment(item: any) {
    let [payload, userData] = [{}, JSON.parse(sessionStorage.getItem('loginData'))];
    // payload = {amount:item.rate, description:'Subscription', name:userData.name, emailid:userData.email, phone:userData.mobileNO, loginid:userData.id,
    //  packageid:item.id};
    // this.entitySvc.post('https://lms.dhanushinfotech.com/lmstelecon/payment/startPaymentA',payload).subscribe(res=>{
    //   console.log('Saved Successfully');
    // },
    // err=>{
    //   console.log(err);
    // });
    //this.payUrl = `https://meet.dhanushinfotech.com/conference/nojwt/payment/startPaymentB/${userData.id}/${item.id}`;
    let url = location.hostname != 'localhost' ? `${location.origin}/conference/` : environment.apiUrl;
    if (item.duration == 'TrialPeriod') {
      this.payUrl = `${url}nojwt/payment/activateFreeTrail/${userData.id}/${item.id}`;
      this.httpSvc.newGet(this.payUrl).subscribe((res) => {
        const result = res.json();
        if (result.status == 'OK') {
          this.toastr.success(result.message);
          this.router.navigate(['/login']);
        }
      },
        (err) => {
          const errResult = err.json();
          this.toastr.error(errResult.message);
        }
      );
    }
    else {
      this.payUrl = `${url}nojwt/payment/startPaymentB/${userData.id}/${item.id}`;
      (<HTMLAnchorElement>document.getElementById('payId')).href = this.payUrl;
      document.getElementById('payId').click();
    }
  }
}
