import { Component, OnInit } from "@angular/core";
import { User } from "src/app/Entities/entity";
import { ToastrService } from "ngx-toastr";
import {
  FormGroup,
  Validators,
  FormControl,
  FormBuilder,
} from "@angular/forms";
import { Router } from "@angular/router";
import { HttpService } from "src/app/Services/http.service";
import { EntityService } from "src/app/Services/entity.service";
import { BaseComponent } from "src/app/Components/BaseComponent";
import { Subject } from "rxjs";
import { DatePipe } from "@angular/common";
import { TimepickerConfig } from "ngx-bootstrap/timepicker";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PaginationService } from "src/app/Services/pagination.service";
import * as commonJson from 'src/app/Shared/common.json';
declare let $: any;

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
  providers: [DatePipe],
})
export class ProfileComponent extends BaseComponent implements OnInit {
  createdId = 0;
  expiryDateTime = "";
  packType = "0";
  participants = "";
  expiryDate: any;
  userProfileForm: FormGroup;
  userDetails = new User();
  btnText = "Update";
  user = {};
  ownLogoImg = "";
  confpassword = "";
  lstUserDeRole = [];
  fileName = "Choose file";
  logo: File = null;
  fromData: FormData;
  jwttoken = "";
  logoApproval: boolean;
  isValidLogo: boolean = false;
  latArchiveDuration: Array<any> = [];
  constructor(
    private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected entitysvc: EntityService,
    private datepipe: DatePipe,
    protected pageSvc: PaginationService,
    private http: HttpClient
  ) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
    this.createdId = JSON.parse(sessionStorage.getItem("loginData")).id;
    this.userProfileForm = this.formBuilder.group({
      id: 0,
      name: ["", [Validators.maxLength(100), Validators.required]],
      password: "",
      confpassword: "",
      role: {
        id: 0,
        name: ''
      },
      mobileNO: ["", [Validators.required, Validators.minLength(10)]],
      status: true,
      address: "",
      recordByDefault: false,
      liveStreamByDefault: false,
      email: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$"),
      ]),
      packType: "0",
      noOfHosts: "",
      participants: "",
      expiryDate: "",
      logo: "",
      landLineNo: "",
      extn: "",
      archiveDuration: "",
    });
    this.validatePattern();
    this.latArchiveDuration = commonJson.archiveDuration;
  }


  ngOnInit() {
    // this.userProfileForm = this.formBuilder.group({
    //   id: 0,
    //   name: ["", [Validators.maxLength(100), Validators.required]],
    //   password: "",
    //   confpassword: "",
    //   role: {
    //     id: 0,
    //   },
    //   mobileNO: ["", [Validators.required, Validators.minLength(10)]],
    //   status: true,
    //   address: "",
    //   recordByDefault: false,
    //   liveStreamByDefault: false,
    //   email: new FormControl("", [
    //     Validators.required,
    //     Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$"),
    //   ]),
    //   packType: "0",
    //   noOfHosts: "",
    //   participants: "",
    //   expiryDate: "",
    //   logo: "",
    //   landLineNo: "",
    //   extn: ""
    // });

    this.getAllRole();
    this.user = JSON.parse(sessionStorage.getItem("loginData"));
    let userdata = JSON.parse(sessionStorage.getItem("loginData"));
    this.jwttoken = userdata == undefined ? "" : userdata.jwtToken;
    this.logoApproval = userdata.logoApproval;
    this.expiryDateTime =
      JSON.parse(sessionStorage.getItem("loginData")).expiryDateTime == null
        ? ""
        : JSON.parse(sessionStorage.getItem("loginData")).expiryDateTime;
    this.userDetails = this.user as User;
    this.userDetails.archiveDuration = this.userDetails.archiveDuration ? this.userDetails.archiveDuration : '';
    this.expiryDate = this.datepipe.transform(this.expiryDateTime, "dd-MM-yyyy");
    this.packType = userdata.subscribed ? "Subscription Pack" : "Trail";
    this.participants = userdata.trailParticipants;
    this.ownLogoImg = userdata.customLogo;
    $(".custom-file-label").addClass("selected").html(this.fileName);
  }

  get f() {
    return this.userProfileForm.controls;
  }

  async getAllRole() {
    this.loading = true;
    this.lstUserDeRole = [];
    await super
      .FetchData("Get", this.apiMethod.getAllRole, {})
      .catch(() => {
        this.httpSvc.handleError, (this.loading = false);
      })
      .then(() => {
        if (this.result != []) {
          this.lstUserDeRole = this.result.data;
        }
        this.loading = false;
      });
  }

  async onUserIdCheck() {
    if (this.userDetails.email != "") {
      await super
        .FetchData(
          "Get",
          `${this.apiMethod.checkExistEmail
          }${this.userDetails.email.toLowerCase()}/${this.userDetails.id}`,
          {}
        )
        .catch(() => {
          this.httpSvc.handleError, (this.loading = false);
        })
        .then(() => {
          if (this.result != []) {
            if (this.result.status != "OK") {
              this.userDetails.email = "";
              return false;
            }
          }
          this.loading = false;
        });
    }
  }

  async saveUpdateUserDetails() {
    if (this.userDetails.role.id == 1 && this.userDetails.archiveDuration == '') {
      this.toastr.warning('Please enter Archive Duration');
      return;
    }
    this.loading = true;
    this.userDetails.name = this.userDetails.name.trim();
    this.userDetails.email = this.userDetails.email.toLowerCase();
    await super
      .FetchData(
        "Post",
        this.apiMethod.saveUser,
        JSON.parse(JSON.stringify(this.userDetails))
      )
      .catch(() => {
        this.httpSvc.handleError, (this.loading = false);
      })
      .then(() => {
        if (this.result != []) {
          if (this.result.data != null && this.result != undefined) {
            sessionStorage.setItem("loginData", JSON.stringify(this.result.data));
            this.httpSvc.loginUserDetails = this.result.data;
            this.httpSvc.userName = this.result.data.name;
            if (this.result.status == "OK") {
              this.toastr.success(this.result.message);
              if (this.logo != null && this.isValidLogo) this.uploadLogo();
            }
            $(".close").click(); // $('#userModal').hide();
          }
        }
        this.loading = false;
      });
  }

  openLogo() {
    window.open(this.ownLogoImg);
  }

  handleFileInput(files: FileList) {
    this.isValidLogo = false;
    this.logo = files.item(0);
    let _URL = window.URL || (window as any).webkitURL;
    // $("#file").change(function (e) {
    let img, isValid: boolean = false;
    if ((files[0])) {
      img = new Image();
      var objectUrl = _URL.createObjectURL(files[0]);
      img.onload = function () {
        // alert(this.width + " " + this.height);
        if (this.width == 150 && this.height == 33) { }
        else
          isValid = false;
        // _URL.revokeObjectURL(objectUrl);
      };
      setTimeout(() => {
        if (isValid)
          this.toastr.error('File width and height should be 150px by 33px');
        else {
          // this.logo = files.item(0);
          this.isValidLogo = true;
          $(".custom-file-label").addClass("selected").html(files.item(0).name);
        }

      }, 50);
      img.src = objectUrl;
    }
    // });
    // this.logo = files.item(0);
    // $(".custom-file-label").addClass("selected").html(files.item(0).name);
  }

  async uploadLogo() {
    this.fromData = new FormData();
    this.fromData.append("userID", JSON.parse(sessionStorage.getItem("loginData")).id);
    this.fromData.append("logo", this.logo);
    this.fromData.append('type', 'logo');
    await this.http
      .post(`${this.httpSvc.BaseUrl}conference/jwt/user/brandinglogoanddomain`, this.fromData,
        { headers: new HttpHeaders({ Authorization: this.jwttoken }) }
      )
      .subscribe(
        (res) => {
          let result: any = res;
          if (result.status == "OK") {
            this.fromData = new FormData();
            this.logo = null;
            const loginDetails: any = JSON.parse(sessionStorage.getItem("loginData"));
            loginDetails.customLogo = result.data.customLogo;
            this.ownLogoImg = result.data.customLogo;
            sessionStorage.setItem("loginData", JSON.stringify(loginDetails));
            $(".custom-file-label").addClass("selected").html(this.fileName);
          } else this.toastr.error(result.message);
          this.loading = false;
        },
        (err) => {
          if (err.status == 401) {
            this.toastr.error(err.error.message);
            sessionStorage.clear();
            this.jwttoken = "";
            setTimeout(() => {
              this.router.navigate([""]);
            }, 1000);
          } else {
            this.toastr.error(err.error.message);
          }
          this.loading = false;
        }
      );
  }
  validatePattern() {
    this.getName.valueChanges.subscribe(val => {
      let v = /[^a-zA-Z .\-\/]/
      if (v.test(val)) {
        this.userProfileForm.controls['name'].setValue(val.substr(0, val.length - 1));
        if (val.length > 50) {
          this.getName.setValue(val.substr(0, 50))
        }
      }
    });

    this.getLandLineNo.valueChanges.subscribe(val => {
      let v = /[^0-9 \-]+$/
      if (val && v.test(val)) {
        this.userProfileForm.controls['landLineNo'].setValue(val.substr(0, val.length - 1));
        if (val.length > 12) {
          this.getLandLineNo.setValue(val.substr(0, 12))
        }
      }
    });
    this.getExtension.valueChanges.subscribe(val => {

      if (val && val.length > 5) {
        this.getExtension.setValue(val.substr(0, 5))
      }
    });

    this.getMobileNO.valueChanges.subscribe(val => {
      let v = /[^0-9]+$/
      if (v.test(val)) {

        this.getMobileNO.setValue(val.substr(0, val.length - 1));
        if (val.length > 10) {
          this.getMobileNO.setValue(val.substr(0, 10))
        }
      }
    });


  }

  get getName() {
    return this.userProfileForm.controls['name'];
  }
  get getLandLineNo() {
    return this.userProfileForm.controls['landLineNo'];
  }
  get getMobileNO() {
    return this.userProfileForm.controls['mobileNO'];
  }
  get getExtension() {
    return this.userProfileForm.controls['extn'];
  }
}
