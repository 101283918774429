import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sharescreen',
  templateUrl: './sharescreen.component.html',
  styleUrls: ['./sharescreen.component.css']
})
export class SharescreenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
