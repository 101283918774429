import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs-compat';
import { EntityService } from 'src/app/Services/entity.service';
import { HttpService } from 'src/app/Services/http.service';
import { PaginationService } from 'src/app/Services/pagination.service';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../BaseComponent';
declare let $: any;

@Component({
  selector: 'app-trashbin',
  templateUrl: './trashbin.component.html',
  styleUrls: ['./trashbin.component.css']
})
export class TrashbinComponent extends BaseComponent implements OnInit {
  public loading = false;
  minToDate: Date; maxToDate: Date; minDate: Date; count: number = 0; toDate: any = new Date();
  rowNo = 0; creatorId = 0; role = 1; selectUserId = 0; fromDate: any = new Date();
  searchForm: FormGroup;
  lstDeletedMeeting: Array<any> = [];
  dtOption: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  constructor(private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected entitysvc: EntityService,
    protected pageSvc: PaginationService,
    private datepipe: DatePipe,
    private http: HttpClient,
  ) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
    if (sessionStorage.getItem('loginData')) {
      this.creatorId = JSON.parse(sessionStorage.getItem('loginData')).id;
      this.role = JSON.parse(sessionStorage.getItem('loginData')).role.id;
    }
  }
  url = location.hostname != 'localhost' ? `${location.origin}/conference/` : environment.apiUrl + 'conference/';

  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      'user': 0,
      'toDate': ['', [Validators.required]],
      'fromDate': ['', [Validators.required]],
      'deleted': true,
    });
    this.minDate = new Date();
    this.onSearch();
  }

  async onSearch() {
    this.dtOption = {}, this.lstDeletedMeeting = [];
    let userId = ((this.role == 1 || this.role == 3) && this.selectUserId == 0) ? this.creatorId : (this.role == 1 || this.role == 3) ? this.selectUserId : this.creatorId;
    let zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    jQuery('.dataTable').DataTable().destroy();
    jQuery('.dataTable').DataTable({ searching: false });
    let payload = `{"creatorId":${this.creatorId},"role":${this.role},"deleted":true,"requesterID":${userId},"fromDate":"${this.datepipe.transform(this.fromDate, 'yyyy-MM-dd')}","toDate":"${this.datepipe.transform(this.toDate, 'yyyy-MM-dd')}","zoneName":"${zoneName}"}`;
    this.dtOption = {
      pagingType: 'first_last_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      destroy: true,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.http.post<DataTablesResponse>(
          `${this.url}jwt/meeting/getMeetingList`,
          Object.assign(dataTablesParameters, { payload: JSON.parse(payload) }), {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': JSON.parse(sessionStorage.getItem('jwtToken')),
          })
        }).
          subscribe(resp => {
            if (resp != undefined && resp != null) {
              let result: any = resp.data;
              if (result.data.length > 0) {
                this.lstDeletedMeeting = result.data, this.rowNo = dataTablesParameters.start;
                this.lstDeletedMeeting.forEach(e => {
                  if (e.startDateTime && e.endDateTime) {
                    if (e.startDateTime != null || e.startDateTime != "")
                      Object.assign(e, { startdatetime: `${this.datepipe.transform(e.startDateTime.split('T')[0], 'dd-MM-yyyy')} ${e.startDateTime.split('T')[1]}` });
                    if (e.endDateTime != null || e.endDateTime != "")
                      Object.assign(e, { enddatetime: `${this.datepipe.transform(e.endDateTime.split('T')[0], 'dd-MM-yyyy')} ${e.endDateTime.split('T')[1]}` });

                    Object.assign(e, { starttime: `${this.datepipe.transform(e.startDateTime, 'HH:mm')}` });
                    Object.assign(e, { endtime: `${this.datepipe.transform(e.endDateTime, 'HH:mm')}` });
                  }
                });
              }
              else
                this.lstDeletedMeeting = [], this.toastr.info(`No data available`);

              callback({
                recordsTotal: (this.lstDeletedMeeting.length == 0 ? 0 : result.recordsTotal),
                recordsFiltered: (this.lstDeletedMeeting.length == 0 ? 0 : result.recordsFiltered),
                data: []
              });
              this.loading = false;
            }
            else
              this.loading = false, this.toastr.error(`An error occurred while processing your request`);
          },
            error => {
              if (error.status == 401)
                setTimeout(() => { console.clear(), console.log(error.message); }, 500), this.getHomeUrl(), this.toastr.warning('Session Expired, kindly re-login');
            }
          );
      },
    };
    setTimeout(() => this.dtTrigger.next(), 100);
  }

  onFromDateChange(val: any) {
    this.count++;
    this.minToDate = new Date();
    this.maxToDate = new Date();
    let formdate = this.datepipe.transform(val, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.searchForm.get('toDate').value, 'yyyy-MM-dd');

    if (val != null) {
      if (new Date(formdate) > new Date(todate))
        this.toDate = this.count == 2 ? this.toDate : new Date(formdate);
      let edate = this.datepipe.transform(val, 'yyyy-MM-dd').split('-'), cnvDate = new Date(parseInt(edate[0]), parseInt(edate[1]) - 1, parseInt(edate[2]), 0, 0, 0, 0);
      this.minToDate = new Date(cnvDate);

      let mdate = this.datepipe.transform(val, 'yyyy-MM-dd').split('-'), cnvmDate = new Date(parseInt(mdate[0]), parseInt(mdate[1]) - 1, parseInt(mdate[2]), 0, 0, 0, 0);
      this.maxToDate = new Date(cnvmDate.setDate(cnvmDate.getDate() + 30));
    }
  }

  ngOnDestroy() {
    jQuery('.dataTable').DataTable().destroy();
    jQuery('.dataTable').DataTable({ searching: false });
  }

}
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
