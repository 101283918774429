import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs-compat';
import { EntityService } from 'src/app/Services/entity.service';
import { HttpService } from 'src/app/Services/http.service';
import { PaginationService } from 'src/app/Services/pagination.service';
import { BaseComponent } from '../../BaseComponent';

@Component({
  selector: 'app-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.css']
})
export class SubscriptionPlansComponent extends BaseComponent implements OnInit {

  loading: boolean = false;
  lstSubscriptionPlan: Array<any> = [];
  mode = 'Create';
  planForm: FormGroup;
  dtTrigger: Subject<any> = new Subject();
  lstOptionalFeatures: Array<any> = [];
  slectedFeatures: Array<any> = [];
  constructor(private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected pageSvc: PaginationService,
    protected entitysvc: EntityService) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
    this.planFormInet();
  }

  ngOnInit() {
    this.getAllPlan();
  }
  get f() { return this.planForm.controls; }

  planFormInet() {
    this.planForm = this.formBuilder.group({
      id: [0],
      description: ['', [Validators.required]],
      duration: ['', [Validators.required]],
      numberOfParticipants: ['', [Validators.required, Validators.min(1)]],
      numberOfUsers: ['', [Validators.required, Validators.min(1), Validators.max(10)]],
      rate: ['', [Validators.required]],
      meetings: ['', [Validators.required]],
      inDays: [0],
      orderCount: [3],
      deleted: [false],
      display: [true],
      cloudStorage: [''],
      webinars: [''],
      additionalFeatures: [''],
      createdBy: [''],
      modifiedBy: ['']
    });
  }

  editPlan(item: any, mode: string) {
    this.mode = mode;
    this.getAllFeatures();
    if (mode == 'Update') {
      if (item.additionalFeatures && item.additionalFeatures.length > 0)
        item.additionalFeatures.split(',').forEach(m => this.slectedFeatures.push(+m));
      // this.slectedFeatures = ;
      this.assignData(this.planForm, item);
    }
    //this.planForm.patchValue(item);
  }

  async saveUpdatePlan() {
    let payload = this.planForm.value;
    payload.inDays = payload.duration == 'monthly' ? 30 : payload.duration == 'annually' ? 365 : 0;
    let test = [];
    // this.lstOptionalFeatures.forEach(m => {
    //   debugger
    //   if (this.slectedFeatures.includes(m.id))
    //     test.push(m.featureName);
    // })
    //payload.additionalFeatures = test.join(',');
    payload.additionalFeatures = this.slectedFeatures.join(',');
    if (payload.id == 0)
      payload.createdBy = JSON.parse(sessionStorage.loginData).id;
    else payload.modifiedBy = JSON.parse(sessionStorage.loginData).id;
    await super.FetchData("Post", this.apiMethod.saveUpdatePlans, payload, 1)
      .catch(() => { this.httpSvc.handleError, (this.loading = false); })
      .then(() => {
        if (this.result != []) {
          if (this.result.status == 'OK') {
            this.toastr.success(this.result.message);
            this.close();
            this.getAllPlan();
          }
        }
        this.loading = false;
      });
  }


  async getAllPlan() {
    this.loading = true; this.lstSubscriptionPlan = [];
    jQuery('.dataTable').DataTable().destroy();
    jQuery('.dataTable').DataTable({ searching: false });
    await super
      .FetchData("Get", this.apiMethod.getAllSubscriptionPlans, {}, 1)
      .catch(() => { this.httpSvc.handleError, (this.loading = false); })
      .then(() => {
        if (this.result != []) {
          this.lstSubscriptionPlan = this.result.data;
          this.dtTrigger.next();
        }
        this.loading = false;
      });

  }

  async getAllFeatures() {
    this.loading = true; this.lstOptionalFeatures = [];
    await super
      .FetchData("Get", this.apiMethod.getAllSubcriptionFeatures, {}, 1)
      .catch(() => { this.httpSvc.handleError, (this.loading = false); })
      .then(() => {
        if (this.result != []) {
          let allFeatures = this.result.data;
          this.lstOptionalFeatures = allFeatures.filter(m => m.featureType == 'optional' && m.deleted == false);
        }
        this.loading = false;
      });

  }

  checkedFeatures(event: any, item: any) {
    if (event.target.checked)
      this.slectedFeatures.push(item.id);
    else {
      if (this.slectedFeatures.includes(item.id)) {
        this.slectedFeatures.splice(this.slectedFeatures.indexOf(item.id), 1);
      }
    }
  }

  checkbox_checked(id: number) {
    let isChecked: Boolean;
    if (this.mode == 'Update')
      isChecked = this.slectedFeatures.includes(id);
    return isChecked;

  }

  close() {
    // this.planForm.reset();
    this.slectedFeatures = [];
    this.planFormInet();
    $(".close").click();
  }


}
