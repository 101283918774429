import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-mobile-lunch',
  templateUrl: './mobile-lunch.component.html',
  styleUrls: ['./mobile-lunch.component.css']
})
export class MobileLunchComponent implements OnInit {

  meetingId: string = '';
  joinURL: string = '';
  iosJoinUrl: string = '';
  constructor(private router: Router,
    private activeroute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activeroute.params.subscribe((e) => { this.meetingId = e.id });
    //this.joinURL = `intent://${window.location.href.replace('mobile', 'aar').replace('https://', '')}/#Intent;scheme=dhanush;package=in.org.dhanush.samvaad;S.joinUrl=${window.location.href.replace('mobile', 'aar')};end`;
    this.joinURL = `intent://${window.location.href.replace('mobile', 'aar').replace('https://', '')}/#Intent;scheme=dhanush;package=in.org.dhanush.samvaad;end`;
    this.iosJoinUrl = `dhanush://${window.location.href.replace('mobile', 'aar').replace('https://', '')}`;
  }

  joinMeeting() {
    this.router.navigate(["./videoRoom", this.meetingId]);
  }

  checkDevice() {
    let isMobile = /Android/i.test(navigator.userAgent);
    let isIosMobile = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    if (isMobile) {
      document.getElementById("btnCofirmModal").click();
      setTimeout(() => this.linkOpen(this.joinURL), 3000);
    }
    if (isIosMobile) {
      document.getElementById("btnCofirmModal").click();
      setTimeout(() => this.linkOpen(this.iosJoinUrl), 3000);
    }
  }

  linkOpen(url: any) {
    window.location.assign(url);
    setTimeout(() => document.getElementById("btnClose").click(), 1000);
  }

}
