import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EntityService } from 'src/app/Services/entity.service';
import { HttpService } from 'src/app/Services/http.service';
import { PaginationService } from 'src/app/Services/pagination.service';
import { BaseComponent } from '../BaseComponent';
// import Sortable, { MultiDrag, Swap } from 'sortablejs';
import { identifierModuleUrl } from '@angular/compiler';

@Component({
  selector: 'app-breakout-room',
  templateUrl: './breakout-room.component.html',
  styleUrls: ['./breakout-room.component.css']
})
export class BreakoutRoomComponent extends BaseComponent implements OnInit {
  roomcount: number = 0; meetingId: string = '';
  roomCountList: Array<any> = [];
  roomList: Array<any> = []; unAssignPartList: Array<string> = []; unAllAssignPartList: Array<string> = [];
  brealoutRoom: any; breakoutRoomId: number = 0;
  constructor(protected toastr: ToastrService,
    private http: HttpService,
    protected router: Router,
    protected pageSvc: PaginationService,
    protected entitysvc: EntityService,
    private activeroute: ActivatedRoute,) {
    super(toastr, router, http, entitysvc, pageSvc);

  }

  ngOnInit() {
    this.activeroute.params.subscribe((params) => {
      this.meetingId = params["id"];
    });
    this.breakoutRoomId = 0;
    this.roomcount = 1;
    // Sortable.create(document.getElementById('unassignPart'), {
    //   animation: 100,
    //   MultiDrag: true,
    //   group: 'list-1',
    //   draggable: '.list-group-item',
    //   handle: '.list-group-item',
    //   sort: true,
    //   filter: '.sortable-disabled',
    //   chosenClass: 'active'
    // });
    Promise.all([this.getAllParticipant(), this.getBreakoutRoomByMeeting()]);
    // this.getAllParticipant(); this.getBreakoutRoomByMeeting();
  }

  generateRooms() {
    let count = +this.roomcount
    if (this.roomList.length > 0)
      if (this.roomList.length < count) {
        for (var i = 0; i <= count; i++) {
          if (this.roomList.length < count) {
            this.roomList.push({
              roomName: 'Breakout room #' + (this.roomList.length + 1),
              id: 'room_' + (this.roomList.length + 1),
              emails: []
            });
          }
        }
      }
      else {
        let removeRoom = [];
        for (var i = this.roomList.length; i >= count; i--) {
          if (this.roomList.length > count) {
            removeRoom = [...removeRoom, ...this.roomList[i - 1].emails];
            this.roomList.pop();
          }
        }
        this.unAssignPartList = [...this.unAssignPartList, ...removeRoom];
      }
    else {
      this.roomList.push({
        roomName: 'Breakout room #' + (this.roomList.length + 1),
        id: 'room_' + (this.roomList.length + 1),
        emails: []
      });
    }
    // setTimeout(() => {
    //   this.roomList.forEach(m => {
    //     this.assignSortable(m.id);
    //   })
    // }, 1000);

    // let list = document.getElementById('room_' + this.roomList.length).getElementsByClassName('list-group-item');
    // for (var i = 0; i < list.length; i++) {
    //   this.unAssignPartList.push(list[i].innerHTML)
    // }
    // console.log(this.unAssignPartList);

    //document.getElementById('room_2').getElementsByClassName('list-group-item') // array of list
  }

  // assignSortable(roomId: string) {
  //   Sortable.create(document.getElementById(roomId), {
  //     group: 'list-1',
  //     MultiDrag: true,
  //     handle: '.list-group-item'
  //   });
  // }

  async getAllParticipant() {
    this.loading = true; this.unAllAssignPartList = [];
    await super
      .FetchData("Get", `${this.apiMethod.getParticipantByMeetingId}/${this.meetingId}`, {})
      .catch(() => {
        this.httpSvc.handleError, (this.loading = false);
      })
      .then(() => {
        if (this.result != []) {
          this.unAllAssignPartList = this.result.data;
          this.unAllAssignPartList.forEach((m, i) => { this.roomCountList.push({ count: i + 1 }) })
        }
        this.loading = false;
      });
  }

  async getBreakoutRoomByMeeting() {
    this.loading = true; let allRoomEmail = [];
    await super.FetchData("Get", `${this.apiMethod.getBreakoutRoom}/${this.meetingId}`, {}, 1)
      .catch(() => {
        this.httpSvc.handleError, (this.loading = false);
      })
      .then(() => {
        if (this.result != []) {
          this.brealoutRoom = this.result.data;
          if (this.brealoutRoom.length > 0) {
            this.breakoutRoomId = this.brealoutRoom[0].id;
            this.roomcount = this.brealoutRoom[0].roomCount;
            let roomlist = this.brealoutRoom[0].rooms;
            if (roomlist.length > 0) {
              roomlist.forEach(e => {
                this.roomList.push({
                  roomName: e.roomName,
                  id: 'room_' + (this.roomList.length + 1),
                  emails: e.mailids.split(',')
                })
                let arr = e.mailids.split(',');

                arr.forEach(m => {
                  allRoomEmail.push(m);
                  // this.unAllAssignPartList.splice(this.unAllAssignPartList.indexOf(m), 1);
                  // this.unAllAssignPartList = this.unAllAssignPartList.filter(v => v !== m);
                })
              });
            }
            else
              this.roomList.push({
                roomName: 'Breakout room #' + (this.roomList.length + 1),
                id: 'room_' + (this.roomList.length + 1),
                emails: []
              });
          } else
            this.roomList.push({
              roomName: 'Breakout room #' + (this.roomList.length + 1),
              id: 'room_' + (this.roomList.length + 1),
              emails: []
            });
          //this.unAssignPartList = this.unAllAssignPartList;
          setTimeout(() => { this.unAssignPartList = this.unAllAssignPartList.filter((el) => !allRoomEmail.includes(el)) }, 500);
          //this.unAssignPartList = this.unAllAssignPartList.filter((el) => !allRoomEmail.includes(el));
        }
        this.loading = false;
      });
  }

  saveBreakoutRoom() {
    let rooms = [], emails = [];
    this.roomList.forEach(m => {
      // emails = [];
      // let list = document.getElementById(m.id).getElementsByClassName('list-group-item');
      // for (var i = 0; i < list.length; i++) {
      //   emails.push(list[i].innerHTML);
      // }
      rooms.push({
        "roomName": m.roomName,
        "mailIds": m.emails
      });
    });
    let payload = {
      "id": this.breakoutRoomId,
      "meetingId": this.meetingId,
      "roomCount": rooms.length,
      "rooms": rooms
    };
    super.FetchData("Post", `${this.apiMethod.saveUpdateBreakoutRoom}`, payload, 1)
      .catch(() => {
        this.httpSvc.handleError, (this.loading = false);
      })
      .then(() => {
        if (this.result != []) {
          if (this.result.status == 'OK') {
            this.toastr.success(this.result.message);
            this.router.navigate(['/Home/schedule']);
          }
        }
        this.loading = false;
      });
  }


}
