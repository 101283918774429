import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sharevideo',
  templateUrl: './sharevideo.component.html',
  styleUrls: ['./sharevideo.component.css']
})
export class SharevideoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
