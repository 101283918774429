import { Component, OnInit } from '@angular/core';
import { Schedule, User } from 'src/app/Entities/entity';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/Services/http.service';
import { EntityService } from 'src/app/Services/entity.service';
import { BaseComponent } from 'src/app/Components/BaseComponent';
import { Subject, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { PaginationService } from 'src/app/Services/pagination.service';
declare let $: any;


@Component({
  selector: 'app-userhistory',
  templateUrl: './smshistory.component.html',
  styleUrls: ['./smshistory.component.css'],
  providers: [DatePipe]
})
export class SMShistoryComponent extends BaseComponent implements OnInit {
  requeterID = 0; lstSubscribeHistor = []; expiryDateTime = ''; userRole = '';
  fromDate: any = new Date().getDate() - 5; toDate: any = '';
  count: number = 0;
  minToDate: Date; maxToDate: Date; minDate: Date; time = ''; minute = ''; minTime: Date = new Date(); maxTime: Date = new Date();
  searchForm: FormGroup
  dtTrigger: Subject<any> = new Subject();
  constructor(private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected entitysvc: EntityService,
    protected pageSvc: PaginationService,
    private datepipe: DatePipe
  ) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
    this.requeterID = JSON.parse(sessionStorage.getItem('loginData')).id;
    this.userRole = JSON.parse(sessionStorage.getItem('loginData')).role.name;
  }


  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      'user': 0,
      'toDate': ['', [Validators.required]],
      'fromDate': ['', [Validators.required]],
    });
    this.toDate = new Date();
    this.expiryDateTime = JSON.parse(sessionStorage.getItem('loginData')).expiryDateTime == null ? '' : JSON.parse(sessionStorage.getItem('loginData')).expiryDateTime;
    // this.smsInfoHistory();
  }

  async smsInfoHistory() {
    this.loading = true; this.lstSubscribeHistor = [];
    jQuery('.dataTable').DataTable().destroy();
    jQuery('.dataTable').DataTable({ searching: false });
    let payLoad = `{"requesterID":${JSON.parse(sessionStorage.getItem('loginData')).id},
  "fromDate":"${this.datepipe.transform(this.fromDate, 'yyyy-MM-dd')}",
  "toDate":"${this.datepipe.transform(this.toDate, 'yyyy-MM-dd')}"}`;
    await super.FetchData('Post', this.apiMethod.getsmsinfo, JSON.parse(payLoad), 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          this.lstSubscribeHistor = this.result.data;
          this.dtTrigger.next();
        }
        this.loading = false;
      });

  }
  ngOnDestroy() {
    $(".close").click();
  }


  onFromDateChange(val: any) {
    this.count++;
    let formdate = this.datepipe.transform(val, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.searchForm.get('toDate').value, 'yyyy-MM-dd');

    if (val != null) {
      this.minToDate = new Date();
      this.maxToDate = new Date();

      if (new Date(formdate) > new Date(todate))
        this.toDate = this.count == 2 ? this.toDate : '';
      let edate = this.datepipe.transform(val, 'yyyy-MM-dd').split('-'), cnvDate = new Date(parseInt(edate[0]), parseInt(edate[1]) - 1, parseInt(edate[2]), 0, 0, 0, 0);
      this.minToDate = new Date(cnvDate);
      let mdate = this.datepipe.transform(val, 'yyyy-MM-dd').split('-'), cnvmDate = new Date(parseInt(mdate[0]), parseInt(mdate[1]) - 1, parseInt(mdate[2]), 0, 0, 0, 0);
      this.maxToDate = new Date(cnvmDate.setDate(cnvmDate.getDate() + 30));
    }
  }
}

