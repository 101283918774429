import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-connectivitystatus',
  templateUrl: './connectivitystatus.component.html',
  styleUrls: ['./connectivitystatus.component.css']
})
export class ConnectivitystatusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
