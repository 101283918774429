import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs/Rx';
import { EntityService } from 'src/app/Services/entity.service';
import { HttpService } from 'src/app/Services/http.service';
import { PaginationService } from 'src/app/Services/pagination.service';
import { BaseComponent } from '../BaseComponent';
declare let $: any;
@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.css']
})
export class PermissionsComponent extends BaseComponent implements OnInit {

  public loading = false;
  lstPermission = []; mode = 'Add'; permissionName = ''; status: boolean = true;
  permissionForm: FormGroup;
  dtTrigger: Subject<any> = new Subject();
  constructor(private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected pageSvc: PaginationService,
    protected entitysvc: EntityService
  ) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
    this.formInet();
  }

  ngOnInit() {
    this.getAllPermission();
  }

  async getAllPermission() {
    this.loading = true; this.lstPermission = [];
    jQuery('.dataTable').DataTable().destroy();
    jQuery('.dataTable').DataTable({ searching: false });
    await super
      .FetchData("Get", this.apiMethod.getAllPermission, {}, 1)
      .catch(() => { this.httpSvc.handleError, (this.loading = false); })
      .then(() => {
        if (this.result != []) {
          this.lstPermission = this.result.data;
          this.dtTrigger.next();
        }
        this.loading = false;
      });
  }

  addEditPermission(item: any, mode: string) {
    this.mode = mode;
    if (mode == 'Add')
      this.formInet();
    else
      this.permissionForm.patchValue({ id: item.id, name: item.name });
    $('#myModal').show();
  }

  formInet() {
    this.permissionForm = this.formBuilder.group({
      id: [0],
      name: ['', [Validators.maxLength(30), Validators.required]]
    });
  }

  async savePermission() {
    let flag = false, count = 0;
    this.lstPermission.forEach(e => {
      if ((e.name as string).toLowerCase() == this.permissionName.trim().toLowerCase() && e.id == this.permissionForm.value.id) {
        // count++;
      } else if ((e.name as string).toLowerCase() == this.permissionName.trim().toLowerCase()) {
        flag = true;
        return;
      }
    });
    if (flag) {
      this.toastr.error('Privilege name already exist');
      this.permissionName = '';
      return;
    }

    this.loading = true;
    this.permissionForm.patchValue({ name: this.permissionName.trim() });
    let entity = this.permissionForm.getRawValue();
    await super.FetchData("Post", this.apiMethod.savePermission, entity, 1)
      .catch(() => { this.httpSvc.handleError, (this.loading = false); })
      .then(() => {
        if (this.result != []) {
          if (this.result.status == 'OK') {
            this.toastr.success(this.result.message);
            $(".close").click(); this.getAllPermission();
          }
        }
        this.loading = false;
      });
  }

  ngOnDestroy() {
    $(".close").click();
  }

}
