import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
  isMobileBrowser: boolean = window.orientation > -1;

  constructor() { }

  ngOnInit() {
    const { origin } = location;
    // [`${origin}/assets/js/jquery.sticky.js`, `${origin}/assets/js/main123.js`, `${origin}/assets/js/calender.js`].forEach(e => {
    [`${origin}/assets/js/jquery.sticky.js`, `${origin}/assets/js/calender.js`].forEach((e, i) => {
      this.loadScript(e).then(data => { setTimeout(() => { if (i == 1 && this.isMobileBrowser) this.MobileMenus() }, 1000); console.log("Script loaded successfully", data); }).catch(err => console.error(err));
    });
  }

  loadScript(FILE_URL, async = true, type = "text/javascript") {
    return new Promise((resolve, reject) => {
      try {
        const scriptEle = document.createElement("script");
        scriptEle.type = type;
        scriptEle.async = async;
        scriptEle.src = FILE_URL;

        scriptEle.addEventListener("load", (ev) => {
          resolve({ status: true });
        });

        scriptEle.addEventListener("error", (ev) => {
          reject({
            status: false,
            message: `Failed to load the script ＄{FILE_URL}`
          });
        });

        document.body.appendChild(scriptEle);
      } catch (error) {
        reject(error);
      }
    });
  }

  MobileMenus() {
    const len: number = document.getElementsByClassName('site-mobile-menu-body')[0].children.length;
    if (len > 0)
      return;

    $('.js-clone-nav').each(function () {
      var $this = $(this);
      $this.clone().attr('class', 'site-nav-wrap').appendTo('.site-mobile-menu-body');
    });


    setTimeout(function () {

      let counter = 0;
      $('.site-mobile-menu .has-children').each(function () {
        var $this = $(this);

        $this.prepend('<span class="arrow-collapse collapsed">');

        $this.find('.arrow-collapse').attr({
          'data-bs-toggle': 'collapse',
          'data-bs-target': '#collapseItem' + counter,
        });

        $this.find('> ul').attr({
          'class': 'collapse',
          'id': 'collapseItem' + counter,
        });

        counter++;

      });

    }, 1000);

  }
}
