import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-upload-participants-details-excel',
  templateUrl: './upload-participants-details-excel.component.html',
  styleUrls: ['./upload-participants-details-excel.component.css']
})
export class UploadParticipantsDetailsExcelComponent implements OnInit {
  formGroup: FormGroup;
  formData: FormData;
  excelFile: any = ''; jwttoken = '';
  public loading: boolean = false; lstError = [];
  dtTrigger: Subject<any> = new Subject();
  url = location.hostname != 'localhost' ? `${location.origin}/conference/` : environment.apiUrl + 'conference/';
  constructor(protected toastr: ToastrService,
    protected router: Router,
    private http: HttpClient,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.jwttoken = JSON.parse(sessionStorage.getItem('loginData')).jwtToken;
    this.formGroup = this.formBuilder.group({
      file: ['', [Validators.required]],
      excelFile: ['', [Validators.required]]
    });
    this.onRefresh();
  }


  onSelectFile(event) {
    this.formData = new FormData;
    let file = event.target.files[0];
    if (file) {
      this.excelFile = file.name;
      this.formGroup.get('excelFile').setValue(event.target.files[0].name)
      this.formData.append('file', file);
    }
  }

  async UploadExcelFile() {
    this.formData.append("userID", JSON.parse(sessionStorage.getItem('loginData')).id)
    this.loading = true;
    jQuery('.dataTable').DataTable().destroy();
    jQuery('.dataTable').DataTable({ searching: false });
    await this.http.post(`${this.url}jwt/mails/uploademailsexcel`, this.formData, { headers: new HttpHeaders({ Authorization: this.jwttoken }) }).
      subscribe(res => {
        let result: any = res;
        if (result.status == 'OK') {
          if (result.data != [] && result.data != null)
            this.lstError = result.data;
          this.dtTrigger.next();
          this.toastr.success(result.message);
          sessionStorage.setItem("lstError", JSON.stringify(result.data))
        }
        else this.toastr.error(result.message);
        this.loading = false;
        this.formData = new FormData;
        this.formGroup.reset();
      },
        err => {
          if (err.status == 401) {
            this.toastr.error(err.error.message);
            this.formData = new FormData;
            this.formGroup.reset();
            sessionStorage.clear();
            this.jwttoken = '';
            setTimeout(() => { this.router.navigate(['']); }, 1000);
          }
          else {
            this.toastr.error(err.error.message);
          }
          this.loading = false;
        }
      );
  }

  async downloadUserExcelFormatFile() {
    window.open(`${this.url}nojwt/login/usersexcelformat`);
    //   this.loading = true;
    //  await  this.http.get(`${this.url}nojwt/login/usersexcelformat`,
    //     { headers: new HttpHeaders({ Authorization: this.jwttoken }), responseType: 'blob' }).
    //     subscribe(res => {
    //       let url = window.URL.createObjectURL(res), a = document.createElement('a');
    //       document.body.appendChild(a), a.setAttribute('style', 'display: none'), a.href = url,
    //         a.download = 'participantsDataExcelFormat.xlsx', a.click();
    //       window.URL.revokeObjectURL(url), a.remove(), this.loading = false;
    //     },
    //       err => {
    //         if (err.status == 401) {
    //           this.loading = false;
    //           this.toastr.error('Session expired');
    //           sessionStorage.clear();
    //           this.jwttoken = '';
    //           setTimeout(() => { this.router.navigate(['']); }, 1000);
    //         }
    //       });
  }



  onRefresh() {
    if (sessionStorage.getItem("lstError")) {
      jQuery('.dataTable').DataTable().destroy();
      jQuery('.dataTable').DataTable({ searching: false });
      this.lstError = JSON.parse(sessionStorage.getItem("lstError"));
      setTimeout(() => $('.table').DataTable());

    }
  }
}
