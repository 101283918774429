import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Response } from "@angular/http";
import { ToastrService } from "ngx-toastr";
import { HttpErrorResponse } from "@angular/common/http";
import { HttpService } from "../Services/http.service";
import { EntityService } from "../Services/entity.service";
import { apiMethods } from "src/app/Shared/apiMethods";
import { Messages } from "src/app/Shared/message";
import { PaginationService } from "src/app/Services/pagination.service";
import { environment } from "src/environments/environment";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-base",
  template: "",
  styles: [],
})
export class BaseComponent implements OnInit {
  expiryDateTime = "";
  loading = false;
  frmData = new FormData();
  apiMethod = apiMethods;
  message = Messages;
  result: any = [];
  jwtTkn = "";
  permissionsArray: String[] = [];
  totalPages: number = 0;
  pageNum: number = 0;
  recordsPerPage: number = 10;
  pageNumber: number = 0;
  changePageNo: number;
  payload: any = {};
  apiType: string = "";
  svcTagIndx: number = 0;
  isLcl: boolean = false;
  mthdname: string = "";
  entity: any = [];
  recordingServerUrl: any = '';
  constructor(
    protected toastr: ToastrService,
    protected route: Router,
    protected httpSvc: HttpService,
    protected entitySvc: EntityService,
    protected pageSvc: PaginationService
  ) {
    const user =
      sessionStorage.getItem("loginData") != "undefined"
        ? JSON.parse(sessionStorage.getItem("loginData"))
        : {};
    if (user && user.role && user.role.permission) {
      // if (user.role)
      //     if (user.role.permission)
      user.role.permission.forEach((e) => this.permissionsArray.push(e.name));
    }

    this.httpSvc.isLogin = user ? true : false;
    this.pageSvc.page_Number.subscribe((s) => (this.pageNumber = s));
    this.pageSvc.mthd_Name.subscribe((s) => (this.mthdname = s));
    this.pageSvc._payload.subscribe((s) => (this.payload = s));
    this.pageSvc._apiType.subscribe((s) => (this.apiType = s));
    this.pageSvc._svcTagIndx.subscribe((s) => (this.svcTagIndx = s));
    this.pageSvc._isLcl.subscribe((s) => (this.isLcl = s));
    this.pageSvc.table_Data.subscribe((s) => (this.result = s));
    this.pageSvc.entity_data.subscribe((s) => (this.entity = s));
  }

  ngOnInit() {
    this.expiryDateTime =
      JSON.parse(sessionStorage.getItem("loginData")).expiryDateTime == null
        ? ""
        : JSON.parse(sessionStorage.getItem("loginData")).expiryDateTime;
  }

  async FetchData(
    apiType: string,
    apiMethod: string,
    payLoad: any,
    svcTagIndx: number = 0,
    isLcl: boolean = false,
    isData: boolean = false
  ) {
    if (apiType != "") {
      this.result = [];
      if (apiType == "Get") {
        await this.entitySvc
          .GetData(apiMethod, this.jwtTkn, svcTagIndx, isLcl)
          .catch((err: Response) => {
            if (err.status == 401)
              setTimeout(() => {
                console.clear(), this.toastr.warning(err.json().message);
              }, 500),
                this.route.navigate([""]);
            else
              setTimeout(() => {
                console.clear(), this.toastr.error(err.json().message);
              }, 500);
          })
          .then((res: Response) => {
            if (res != undefined)
              this.result = isData ? res.json().data : res.json();
            else (this.result = []), (this.loading = false);
          });
      } else {
        await this.entitySvc
          .PostData(apiMethod, payLoad, this.jwtTkn, svcTagIndx, isLcl)
          .catch((err: Response) => {
            if (err.status == 401)
              setTimeout(() => {
                console.clear(), this.toastr.warning(err.json().message);
              }, 500),
                this.route.navigate([""]);
            else
              setTimeout(() => {
                console.clear(), this.toastr.error(err.json().message);
              }, 500);
          })
          .then((res: Response) => {
            if (res != undefined)
              this.result = isData ? res.json().data : res.json();
            else (this.result = []), (this.loading = false);
          });
      }
    }
  }

  async FetchDataByPagination(
    apiType: string,
    apiMethod: string,
    payLoad: any,
    svcTagIndx: number = 0,
    isLcl: boolean = false,
    isData: boolean = false
  ) {
    this.pageSvc.setMethod = apiMethod;
    this.pageSvc.setPayload = payLoad;
    this.pageSvc.setApiType = apiType;
    this.pageSvc.setSvcTagIndx = svcTagIndx;
    this.pageSvc.setIsLcl = isLcl;
    // alert('FetchDataByPagination base component');
    if (apiType != "") {
      this.result = [];
      if (apiType == "Get") {
        //let qearyParm = Object.keys(payLoad).length === 0?'':payLoad;
        //${qearyParm!=''?'?status='+qearyParm:''}
        await this.entitySvc
          .GetData(
            `${apiMethod}/${this.pageNumber}/${this.recordsPerPage}`,
            this.jwtTkn,
            svcTagIndx,
            isLcl
          )
          .catch((err: Response) => {
            if (err.status == 401)
              setTimeout(() => {
                console.clear(), this.toastr.warning(err.json().message);
              }, 500),
                this.route.navigate([""]);
            else
              setTimeout(() => {
                console.clear(), this.toastr.error(err.json().message);
              }, 500);
          })
          .then((res: Response) => {
            if (res != undefined) {
              this.result = isData ? res.json().data : res.json();
              this.totalPages = this.result.data.totalPages;
              this.pageSvc.setPages = this.totalPages;
              this.pageSvc.setPageNumber = this.pageNumber;
              this.pageSvc.setPages = this.totalPages;
              this.pageSvc.setPageNumber = this.pageNumber;
              this.pageSvc.setMethod = this.mthdname;
              this.pageSvc.setPayload = this.payload;
              this.pageSvc.setApiType = this.apiType;
              this.pageSvc.setSvcTagIndx = this.svcTagIndx;
              this.pageSvc.setIsLcl = this.isLcl;
              this.pageSvc.setTableData = this.result.data.content;
            } else (this.result = []), (this.loading = false);
          });
      } else {
        // alert('FetchDataByPagination base component 222 '+payLoad);
        let objPayload = JSON.parse(payLoad);
        // alert('FetchDataByPagination base component 222');
        objPayload["pageNo"] = this.pageNumber;
        objPayload["pageSize"] = this.recordsPerPage;
        // alert('FetchDataByPagination base component 3333');
        await this.entitySvc
          .PostData(apiMethod, objPayload, this.jwtTkn, svcTagIndx, isLcl)
          .catch((err: Response) => {
            if (err.status == 401)
              setTimeout(() => {
                console.clear(), this.toastr.warning(err.json().message);
              }, 500),
                this.route.navigate([""]);
            else
              setTimeout(() => {
                console.clear(), this.toastr.error(err.json().message);
              }, 500);
          })
          .then((res: Response) => {
            if (res != undefined) {
              this.result = isData ? res.json().data : res.json();
              this.totalPages = this.result.data.totalPages;
              this.pageSvc.setPages = this.totalPages;
              this.pageSvc.setPageNumber = this.pageNumber;
              this.pageSvc.setPages = this.totalPages;
              this.pageSvc.setPageNumber = this.pageNumber;
              this.pageSvc.setMethod = this.mthdname;
              this.pageSvc.setPayload = this.payload;
              this.pageSvc.setApiType = this.apiType;
              this.pageSvc.setSvcTagIndx = this.svcTagIndx;
              this.pageSvc.setIsLcl = this.isLcl;
              this.pageSvc.setTableData = this.result.data.content;
            } else (this.result = []), (this.loading = false);
          });
      }
    }
  }

  async LogOut() {
    this.loading = true;
    // document.getElementById('isShowSignSignUp1').style.display = 'block';
    // document.getElementById('isShowSignSignUp2').style.display = 'block';
    this.FetchData("Get", this.apiMethod.logOut, {}, 1)
      .catch(() => {
        this.httpSvc.handleError, (this.loading = false);
      })
      .then(() => {
        if (this.result != undefined) {
          sessionStorage.clear();
          localStorage.clear();
          if (this.getHomeUrl() === 'https://www.samvaadpro.com')
            window.location.replace(this.getHomeUrl());
          else this.route.navigate([`${this.getHomeUrl()}`]);
        }
        else this.result = [];
        this.loading = false;
        this.httpSvc.isLogin = false;
        this.httpSvc.loginUserDetails = "";
        this.httpSvc.permissionArry = [];
      });
  }

  checkPermission(permission: string, userName: string = "") {
    let returnVal: boolean = false;
    let user =
      sessionStorage.getItem("loginData") != "undefined"
        ? JSON.parse(sessionStorage.getItem("loginData"))
        : {};
    if (user && user.email == "admin@dhanushinfotech.net")
      returnVal = [
        "Privileges",
        "Role",
        "Privilege",
        "Role Privilege Mapping",
      ].includes(permission)
        ? true
        : this.permissionsArray.includes(permission);
    else returnVal = this.permissionsArray.includes(permission);
    return returnVal;
  }

  getDownloadServerUrl() {
    this.FetchData("Get", this.apiMethod.getSchemeUrl, {}, 5)
      .catch(() => {
        this.httpSvc.handleError;
      })
      .then(() => {
        this.recordingServerUrl = this.result.data;
      });
  }

  // this.httpSvc.newGet(`${this.getBaseUrl() +this.httpSvc.svcTag[5] + this.apiMethod.getSchemeUrl}`)
  //   .subscribe((res) => {const result = res.json(); this.recordingServerUrl = result.data;});

  //#region Pagination

  async firstPage() {
    this.pageNumber = 0;
    await this.FetchDataByPagination(
      this.apiType,
      this.mthdname,
      this.payload,
      this.svcTagIndx,
      this.isLcl
    );
  }

  async previousPage() {
    if (this.pageNumber > 0) {
      this.pageNumber--;
      await this.FetchDataByPagination(
        this.apiType,
        this.mthdname,
        this.payload,
        this.svcTagIndx,
        this.isLcl
      );
    }
  }

  async nextPage() {
    if (this.pageNumber < this.totalPages - 1) {
      this.pageNumber++;
      this.pageSvc.setRecordsPerPage = this.recordsPerPage;
      await this.FetchDataByPagination(
        this.apiType,
        this.mthdname,
        this.payload,
        this.svcTagIndx,
        this.isLcl
      );
    }
  }

  async lastPage() {
    this.pageNumber = this.totalPages - 1;
    await this.FetchDataByPagination(
      this.apiType,
      this.mthdname,
      this.payload,
      this.svcTagIndx,
      this.isLcl
    );
  }

  async changePage(id: number) {
    if (id > 0 && this.totalPages >= id && id <= this.totalPages) {
      this.pageNumber = id - 1;
      await this.FetchDataByPagination(
        this.apiType,
        this.mthdname,
        this.payload,
        this.svcTagIndx,
        this.isLcl
      );
    } else if (id <= 0) alert(`Please enter greater than 0`);
    else alert(`Please enter 1 to ${this.totalPages}`);
  }

  onActivate() {
    window.scroll(0, 0);
  }

  getBaseUrl() {
    return location.hostname != "localhost"
      ? `${location.origin}/`
      : environment.apiUrl;
  }

  getHomeUrl(): string {
    /*
    // return location.origin.includes('scheduling')
    //   ? `${location.origin.replace('scheduling.samvaad.pro', 'www.samvaadpro.com')}` : '/';
    return location.origin.includes('scheduling.samvaad.pro')
      ? `${location.origin.replace('scheduling.samvaad.pro', 'www.samvaadpro.com')}` :
      location.origin.includes('scheduling.samvaadpro.com')
        ? `${location.origin.replace('scheduling.samvaadpro.com', 'www.samvaadpro.com')}` : '/';
    //: location.origin.includes('dev.samvaad.pro') ? `${location.origin}/` : '/';
    */
    return '/';
  }

  checkNumber(event: any) {
    event.target.value = event.target.value.replace(/[^0-9]*/g, '');
  }
  checkCharacterDotSpace(event: any) {
    event.target.value = event.target.value.replace(/[^A-Za-z. ]*/g, '');
  }

  assignData(form: FormGroup, data: any) {
    let ctrls = form.controls;
    Object.keys(ctrls).map(key => {
      let ctrl = ctrls[key];
      ctrl.setValue(data[key])
    });
  }

  // async getPage() {
  //     this.loading = true;
  //     await this.FetchDataByPagination(this.apiType, this.mthdname, this.payload, this.svcTagIndx, this.isLcl)
  //         .catch((err: HttpErrorResponse) => {
  //             // console.error('An error occurred:', err.error);
  //         })
  //         .then((res: any) => {
  //             if (res) {
  //                 this.loading = false;
  //                 let result = res;
  //                 if (result.data) {
  //                     this.result = result.data;
  //                     this.totalPages = result.data.totalPages;
  //                     this.pageSvc.table_Data = this.result;
  //                     this.pageSvc.setPages = this.totalPages;
  //                     this.pageSvc.setPageNumber = this.pageNumber;
  //                     this.pageSvc.setMethod = this.mthdname;
  //                     this.pageSvc.setPayload = this.payload;
  //                     this.pageSvc.setApiType = this.apiType;
  //                     this.pageSvc.setSvcTagIndx = this.svcTagIndx;
  //                     this.pageSvc.setIsLcl = this.isLcl;
  //                 }
  //             }
  //             //  else
  //             //     (this.loading = false),
  //             //         this.toastr.error(
  //             //             `An error occurred while processing your request`
  //             //         );
  //         });
  // }

  //#endregion
  //  googleTranslateElementInit() {
  //     new google.translate.TranslateElement(
  //       {
  //         pageLanguage: 'en',
  //         includedLanguages: 'hi,te,or,en',
  //         layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
  //         autoDisplay: false,
  //         // gaTrack: true,
  //         //gaId: 'UA-35158556-1'
  //         // multilanguagePage: false
  //       },
  //       'google_translate_element'
  //     );
  //   }
}
