import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-header',
  templateUrl: './new-header.component.html',
  styleUrls: ['./new-header.component.css']
})
export class NewHeaderComponent implements OnInit {
  isMobileBrowser: boolean = window.orientation > -1;

  constructor(private route: Router) { }

  ngOnInit() {
    setTimeout(() => { if (this.isMobileBrowser) this.MobileMenus() }, 1000);
  }

  MobileMenus() {
    const len: number = document.getElementsByClassName('site-mobile-menu-body')[0].children.length;
    if (len > 0)
      return;

    $('.js-clone-nav').each(function () {
      var $this = $(this);
      $this.clone().attr('class', 'site-nav-wrap').appendTo('.site-mobile-menu-body');
    });


    setTimeout(function () {

      let counter = 0;
      $('.site-mobile-menu .has-children').each(function () {
        var $this = $(this);

        $this.prepend('<span class="arrow-collapse collapsed">');

        $this.find('.arrow-collapse').attr({
          'data-bs-toggle': 'collapse',
          'data-bs-target': '#collapseItem' + counter,
        });

        $this.find('> ul').attr({
          'class': 'collapse',
          'id': 'collapseItem' + counter,
        });

        counter++;

      });

    }, 1000);

  }

  @HostListener('window:click', ['$event.target'])
  onClick(targetElement: any) {
    switch (targetElement.id) {
      case "signup":
      case "login":
      case "plan-service":
      case "home":
        const new_route: string = targetElement.getAttribute('attrname') || "";
        //if (new_route) {
        this.changeMenu();
        this.route.navigate([new_route]);
        // }
        break;

      default:
        break;
    }
  }

  changeMenu() {
    if (this.isMobileBrowser) {
      const [body, offcanvasmenu] = [$("body"), "offcanvas-menu"];
      body.hasClass(offcanvasmenu) ? body.removeClass(offcanvasmenu) : body.addClass(offcanvasmenu);
    }
  }
}
