import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EntityService } from 'src/app/Services/entity.service';
import { HttpService } from 'src/app/Services/http.service';
import { BaseComponent } from '../BaseComponent';
import { PaginationService } from 'src/app/Services/pagination.service';
import { LoginService } from 'src/app/Services/login.service';
import { Subscription } from 'rxjs';

declare const $: any;
@Component({
  selector: 'app-pro-header',
  templateUrl: './pro-header.component.html',
  styleUrls: ['./pro-header.component.css'],
})
export class ProHeaderComponent extends BaseComponent implements OnInit, OnDestroy {
  isShowSignSignUp: boolean = true; userEmail: ''; user: any; userName = ''; profileName = ''; showChangePwd = true;
  // @ViewChild('profileDropdown', null) navdrop: ElementRef;

  userType = '';
  expiryDateTime = ''; rolename = '';

  showLanguage: boolean = false;
  subscription: Subscription;
  is_Login: boolean = false;
  isMobileBrowser: boolean = window.orientation > -1;

  constructor(
    private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    public httpSvc: HttpService,
    protected entitysvc: EntityService,
    protected pageSvc: PaginationService,
    private http: HttpClient,
    private isLogin: LoginService
  ) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);

  }

  ngOnInit() {
    this.subscription = this.isLogin.currentLogIn.subscribe(message => {
      this.is_Login = message; console.log(message);
      if (message) {
        this.user = sessionStorage.getItem('loginData') != 'undefined' ? JSON.parse(sessionStorage.getItem('loginData')) : {};
        this.httpSvc.loginUserDetails = this.httpSvc.loginUserDetails == undefined ? this.user : this.httpSvc.loginUserDetails;
        if (this.httpSvc.permissionArry.length == 0 && sessionStorage.getItem('loginData') && sessionStorage.getItem('loginData') != 'undefined' && JSON.parse(sessionStorage.getItem('loginData')).role.permission) {
          JSON.parse(sessionStorage.getItem('loginData')).role.permission.forEach(e => this.httpSvc.permissionArry.push(e.name));
          if (JSON.parse(sessionStorage.getItem('loginData')).email == 'admin@dhanushinfotech.net')
            this.httpSvc.permissionArry.push(...['Privileges', 'Role', 'Privilege', 'Role Privilege Mapping']);
        }
      }
    });

    this.mobilemenu();
    this.showChangePwd = this.router.url.split('/')[1] == 'subsclist' ? false : true;
    //$('body').not("#myDropdown").off('click').on('click', function () { $("#myDropdown").removeClass("show") });
    // this.showLanguage = (window.orientation > -1);
    if (sessionStorage.getItem('loginData')) {
      this.userName = JSON.parse(sessionStorage.getItem('loginData')).name;
      this.userEmail = JSON.parse(sessionStorage.getItem('loginData')).email;
      this.rolename = JSON.parse(sessionStorage.getItem('loginData')).role.name;
      this.expiryDateTime = JSON.parse(sessionStorage.getItem('loginData')).expiryDateTime == null ? '' : JSON.parse(sessionStorage.getItem('loginData')).expiryDateTime;
      this.userType = JSON.parse(sessionStorage.getItem('loginData')).userType;
    }
    this.showChangePwd = this.router.url.split('/')[1] == 'subsclist' ? false : true;

    $(function () {
      'use strict'
      $('[data-toggle="offcanvas"]').on('click', function () {
        $('.offcanvas-collapse').toggleClass('open')
      });
    });

  }

  profileDropClick(event) {
    if (event.detail == 1)
      if ($('#profileDropdown').css('display') == 'none') {
        $('#profileDropdown').attr("style", "display: block;");
      } else
        $("#profileDropdown").removeAttr("style", "display: none;");
  }

  routeHost(page: string) {
    let user = JSON.parse(sessionStorage.getItem('loginData'));
    if (page == 'Host') {
      if (this.httpSvc.isLogin && user && user.role.id == 2) {
        this.router.navigate(['/Home/schedule']);
      }
      else
        this.router.navigate(['/login']);
    }
    else if (page == 'Meeting') {
      if (this.httpSvc.isLogin) {
        this.router.navigate(['/Home/schedule']);
      }
      else
        this.router.navigate(['/login']);
    }
  }

  routePlan() {
    let user = JSON.parse(sessionStorage.getItem('loginData'));
    if (this.httpSvc.isLogin && user && user.role.id != 1 && user.userType != 'EH')
      this.router.navigate(['/Home/subsclist']);
    else
      // window.location.href = '#plan-service';
      this.route.navigate(['/Home/subsclist']);
    //document.getElementById("#plan-service").();
    //.router.navigate(['#plan-service']);
  }
  ChangeLanguage(code: string) {
    //new google.translate.TranslateElement({pageLanguage: 'en'}, 'app-pro-header');
  }

  // menuClick(event) {
  //   if (event.detail == 1)
  //     if ($(this).siblings('.dropmenu').css('display') == 'block') {
  //       $(this).siblings('.dropmenu').css('display', 'none');
  //     } else
  //       $(this).siblings('.dropmenu').css('display', 'block');
  // }


  mobilemenu() {
    (function ($) {
      $(function () {
        $('nav ul li a').on('click', function (e) {
          // if (e.target.classList.contains('dropdown-toggle'))
          //   $('.dropmenu').toggle();
          // else
          //   $(this).siblings('.dropmenu').toggle();
          // $('.nav-dropdown').not($(this).siblings()).hide();
          // $(this).next(".nav-dropdown").slideToggle();
          this.classList.toggle('active');
          if (e.target.classList.contains('dropdown-toggle') && e.target.classList.contains('active'))
            $('.dropmenu').css('display', 'block');
          else {
            $('.dropmenu').css('display', 'none');
          }
          $('.dropmenu').not($(this).siblings()).hide();
          if (e.target.classList.contains('parentmenu'))
            $('#nav-toggle').click(), $('.dropdown-toggle').removeClass('active');
          if (e.target.classList.contains('dropmenuclose'))
            $('#nav-toggle').click(), $('.dropdown-toggle').removeClass('active');
          e.stopPropagation();
        });

        $('html').on('click', function (e) {
          $('.dropdown-toggle').removeClass('active');
          if (e.target.classList.contains('parentmenu'))
            $('#nav-toggle').click();
          if (e.target.classList.contains('dropmenuclose'))
            $('#nav-toggle').click();
        });

        $('#nav-toggle').on('click', function (e) {
          $('nav ul').slideToggle();
          e.stopPropagation();
        });

      });
    })(jQuery);

  }

  loadMobileMenu() {
    //  (function ($) {
    "use strict";
    // Smooth scroll for the navigation menu and links with .scrollto classes
    var scrolltoOffset = $('#header').outerHeight() - 1;
    $(document).on('click', '.nav-menu a, .mobile-nav a, .scrollto', function (e) {
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        if (target.length) {
          e.preventDefault();
          var scrollto = target.offset().top - scrolltoOffset;
          if ($(this).attr("href") == '#header') {
            scrollto = 0;
          }
          $('html, body').animate({
            scrollTop: scrollto
          }, 1500, 'easeInOutExpo');
          if ($(this).parents('.nav-menu, .mobile-nav').length) {
            $('.nav-menu .active, .mobile-nav .active').removeClass('active');
            $(this).closest('li').addClass('active');
          }
          if ($('body').hasClass('mobile-nav-active')) {
            $('body').removeClass('mobile-nav-active');
            $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
            $('.mobile-nav-overly').fadeOut();
          }
          return false;
        }
      }
    });
    // Activate smooth scroll on page load with hash links in the url
    $(document).ready(function () {
      if (window.location.hash) {
        var initial_nav = window.location.hash;
        if ($(initial_nav).length) {
          var scrollto = $(initial_nav).offset().top - scrolltoOffset;
          $('html, body').animate({
            scrollTop: scrollto
          }, 1500, 'easeInOutExpo');
        }
      }
    });
    // Navigation active state on scroll
    var nav_sections = $('section');
    var main_nav = $('.nav-menu, .mobile-nav');

    $(window).on('scroll', function () {
      var cur_pos = $(this).scrollTop() + 200;

      nav_sections.each(function () {
        var top = $(this).offset().top,
          bottom = top + $(this).outerHeight();

        if (cur_pos >= top && cur_pos <= bottom) {
          if (cur_pos <= bottom) {
            main_nav.find('li').removeClass('active');
          }
          main_nav.find('a[href="#' + $(this).attr('id') + '"]').parent('li').addClass('active');
        }
        if (cur_pos < 300) {
          $(".nav-menu ul:first li:first, .mobile-nav ul:first li:first").addClass('active');
        }
      });
    });
    // Mobile Navigation
    if ($('.nav-menu').length) {
      var $mobile_nav = $('.nav-menu').clone().prop({
        class: 'mobile-nav d-lg-none'
      });
      $('body').append($mobile_nav);
      $('body').prepend('<button type="button" class="mobile-nav-toggle d-lg-none"><i class="fa fa-bars"></i></button>');
      $('body').append('<div class="mobile-nav-overly"></div>');
      $(document).on('click', '.mobile-nav-toggle', function (e) {
        $('body').toggleClass('mobile-nav-active');
        $('.mobile-nav-toggle i').toggleClass('fas fa-times');
        $('.mobile-nav-overly').toggle();
      });
      $(document).on('click', '.mobile-nav .drop-down > a', function (e) {
        e.preventDefault();
        $(this).next().slideToggle(300);
        $(this).parent().toggleClass('active');
      });
      $(document).click(function (e) {
        var container = $(".mobile-nav, .mobile-nav-toggle");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          if ($('body').hasClass('mobile-nav-active')) {
            $('body').removeClass('mobile-nav-active');
            $('.mobile-nav-toggle i').toggleClass('fas fa-times');
            $('.mobile-nav-overly').fadeOut();
          }
        }
      });
    } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
      $(".mobile-nav, .mobile-nav-toggle").hide();
    }
    // Toggle .header-scrolled class to #header when page is scrolled
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('#header').addClass('header-scrolled');
        $('#topbar').addClass('topbar-scrolled');
      } else {
        $('#header').removeClass('header-scrolled');
        $('#topbar').removeClass('topbar-scrolled');
      }
    });
    if ($(window).scrollTop() > 100) {
      $('#header').addClass('header-scrolled');
      $('#topbar').addClass('topbar-scrolled');
    }
    //});

    // When the user scrolls the page, execute myFunction
    window.onscroll = function () { myFunction() };
    function myFunction() {
      var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      var scrolled = (winScroll / height) * 100;
      document.getElementById("myBar").style.width = scrolled + "%";
    }

    // $(function () {
    // $('.bck').backToTop({
    //   iconName: 'fas fa-arrow-up',
    //   fxName: 'rightToLeft'
    // });
    // });

    // (function () {
    "use strict";
    // var carousels = function () {
    $.fn.owlCarousel = function (options) {
      $(".owl-carousel1").owlCarousel({
        loop: true,
        autoplay: true,
        autoplayTimeout: 2000,
        autoplayHoverPause: true,
        center: true,
        margin: 0,
        responsiveClass: true,
        nav: false,
        responsive: {
          0: {
            items: 1,
            nav: false
          },

          680: {
            items: 2,
            nav: false,
            loop: false
          },

          1000: {
            items: 3,
            nav: true
          }
        }
      });
    }
    //  };
    //  (function ($) {
    //  carousels();
    // });
    // });

    $.fn.jQuerySimpleCounter = function (options) {
      var settings = $.extend({
        start: 0,
        end: 100,
        easing: 'swing',
        duration: 400,
        complete: ''
      },
        options);
      var thisElement = $(this);
      $({ count: settings.start }).animate({ count: settings.end }, {
        duration: settings.duration,
        easing: settings.easing,
        step: function () {
          var mathCount = Math.ceil(this.count);
          thisElement.text(mathCount);
        },
        complete: settings.complete
      });
    };
    $('#number1').jQuerySimpleCounter({ end: 12, duration: 3000 });
    $('#number2').jQuerySimpleCounter({ end: 55, duration: 3000 });
    $('#number3').jQuerySimpleCounter({ end: 359, duration: 2000 });
    $('#number4').jQuerySimpleCounter({ end: 246, duration: 2500 });
    /* AUTHOR LINK */
    $('.about-me-img').hover(function () {
      $('.authorWindowWrapper').stop().fadeIn('fast').find('p').addClass('trans');
    }, function () {
      $('.authorWindowWrapper').stop().fadeOut('fast').find('p').removeClass('trans');
    });
    //# sourceURL=pen.js

    // $(function () {
    // $('#homeVideo').modal({
    //   show: false

    // }).on('hidden.bs.modal', function () {
    //   $(this).find('video')[0].pause();
    // });
    // });

    // var vid = document.getElementById("homeVideo");
    // function enableMute() {
    //   vid.muted = true;
    // }
    // function disableMute() {
    //   vid.muted = false;
    // }
    // function checkMute() {
    //   alert(vid.muted);
    // }

    $("#homeVideo").on('hidden.bs.modal', function () {
      $('.modal-backdrop').css('background-color', 'black', 'opacity', '0.9');
    });

  }

  async logOut() {
    if (JSON.parse(sessionStorage.getItem('jwtToken')))
      await super.LogOut();
    this.isLogin.changeMessage(false);
  }

  getLandingUrl() {
    if (this.httpSvc.isLogin) {
      let loguser = JSON.parse(sessionStorage.getItem('loginData'));
      if (loguser.role.name == 'admin')
        this.router.navigate(['/Home/user']);
      else if (loguser.role.name == 'IS')
        this.router.navigate(['/Home/approvalDomail']);
      else {
        if (loguser.subscribed || this.user.userType == 'EH')
          this.router.navigate(['/Home/schedule']);
        else
          this.router.navigate(['/Home/subsclist']);
      }
    } else {
      window.location.href = this.getHomeUrl();
      //this.router.navigate([this.getHomeUrl()]);
    }
  }


  ngOnDestroy() {
    // this.subscription.unsubscribe();
  }

  @HostListener('window:click', ['$event.target'])
  onClick(targetElement: any) {
    switch (targetElement.id) {
      case "subsclist":
        this.changeMenu();
        this.routePlan();
        break;
      case "elLogout":
        this.changeMenu();
        this.logOut();
        break;
      case "changePassword":
      case "profile":
      case "schedule":
      case "approvalDomail":
      case "userdashboard":
      case "dashboard":
      case "rooms":
      case "smshistory":
      case "userManual":
      case "trashbin":
      case "payhistory":
      case "user":
      case "uploadexcel":
      case "settings":
      case "domain":
      case "owndomain":
      case "planfeaturesMap":
      case "subscription-plans":
      case "subscription-features":
      case "rolePermissionMapping":
      case "permission":
      case "role":
        const new_route: string = targetElement.getAttribute('attrname') || "";
        if (new_route) {
          this.changeMenu();
          this.route.navigate([new_route]);
        }
        break;

      default:
        break;
    }
  }

  changeMenu() {
    if (this.isMobileBrowser) {
      const [body, offcanvasmenu] = [$("body"), "offcanvas-menu"];
      body.hasClass(offcanvasmenu) ? body.removeClass(offcanvasmenu) : body.addClass(offcanvasmenu);
    }
  }

}
