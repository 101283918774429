import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/Services/http.service';
import { EntityService } from 'src/app/Services/entity.service';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

declare let $: any;
@Component({
  selector: 'app-server-rooms',
  templateUrl: './server-rooms.component.html',
  styleUrls: ['./server-rooms.component.css'],
  providers: [DatePipe]
})
export class ServerRoomsComponent implements OnInit {
  dtTrigger: Subject<any> = new Subject();
  serverRoomList: Array<any> = [];
  public loading = false;
  bbb_url: string = '';
  url = location.hostname != 'localhost' ? `${location.origin}/conference/` : environment.apiUrl + 'conference/';
  constructor(
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected entitysvc: EntityService,
    private datepipe: DatePipe
  ) { }

  ngOnInit() {
    this.getAllServerRooms();
  }

  getAllServerRooms() {
    let request: any = {
      "action": "getMeetings"
      // "paratmeters": "meetingID=uma-bjt-uxo-krv",
    }

    this.loading = true; this.serverRoomList = [];
    jQuery('.dataTable').DataTable().destroy();
    jQuery('.dataTable').DataTable({ searching: false });
    let user = JSON.parse(sessionStorage.getItem('loginData'));
    let apiUrl = user.role.id == 3 ? `getCheckSumUrls` : `getCheckSumUrls?userID=${user.id}`;
    // this.entitysvc.PostData1('login/getCheckSumUrl', request).subscribe(
    // this.httpSvc.newPost('https://demo.dhanushsamvaad.com/conclave/nojwt/session/getCheckSumUrls', request).subscribe(
    this.httpSvc.newPost(`${this.url}conclave/nojwt/session/${apiUrl}`, request).subscribe(
      (res) => {
        const result = res.json();
        console.log(result);
        if (result.status === 'OK' && result.message === 'Checksum has generated' && result.data.response.returncode === 'SUCCESS') {
          if (result.data.response.hasOwnProperty('meetings')) {
            if (result.data.response.meetings === '' && result.data.response.messageKey === 'noMeetings')
              this.toastr.warning('', result.data.response.message);
            else {
              if (Array.isArray(result.data.response.meetings.meeting))
                this.serverRoomList = result.data.response.meetings.meeting;
              else
                this.serverRoomList.push(result.data.response.meetings.meeting);
            }
            console.log()
          }
          else {
            this.toastr.info('', '')
          }
        }
      },
      (err) => {
        this.loading = false;
        this.dtTrigger.next();
      },
      () => {
        this.loading = false;
        this.dtTrigger.next();
      }
    );
  }

  getPresenter(attendees: any) {
    let presenterName: string = '';
    if (attendees) {
      if (Array.isArray(attendees.attendee)) {
        const item = attendees.attendee.find(e => e.isPresenter == true);
        if (item)
          presenterName = item.fullName;
      }
      else
        presenterName = attendees.attendee.fullName;
    }
    return presenterName;
  }

  // getBBB_Api() {
  //   //https://meet.dhanushinfotech.com/conference/nojwt/login/getCheckSumUrl;
  //   this.entitysvc.GetData1('login/getAVServiceUrl').subscribe(res => {
  //     const result = res.json();
  //     if (result.message === 'getAVServiceUrl' && result.status === 'OK') {
  //       this.bbb_url = result.data.avserviceurl;
  //       this.getAllServerRooms();
  //     }
  //     console.log(result);
  //   });
  // }

}
