import { Messages } from './../../Shared/message';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Recurrence, Schedule, User } from 'src/app/Entities/entity';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/Services/http.service';
import { EntityService } from 'src/app/Services/entity.service';
import { BaseComponent } from 'src/app/Components/BaseComponent';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { TimepickerConfig } from 'ngx-bootstrap/timepicker';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { PaginationService } from 'src/app/Services/pagination.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import * as commonJson from 'src/app/Shared/common.json';
import { HtmlTagDefinition } from '@angular/compiler';
import { promise } from 'protractor';
import { style } from '@angular/animations';

declare let $: any;

export function getTimepickerConfig(): TimepickerConfig {
  return Object.assign(new TimepickerConfig(), {
    hourStep: 1,
    minuteStep: 1,
    showMeridian: true,
    readonlyInput: false,
    mousewheel: true,
    showMinutes: true,
    showSeconds: false,
    labelHours: 'Hours',
    labelMinutes: 'Minutes',
    labelSeconds: 'Seconds',
    showSpinners: false
  });
}

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css'],
  providers: [DatePipe, { provide: TimepickerConfig, useFactory: getTimepickerConfig }],
  encapsulation: ViewEncapsulation.None
})
export class ScheduleComponent extends BaseComponent implements OnInit {
  public loading = false; subscribe: boolean = false; moderatorsValue: any; defaultModerators: string = '3';
  scheduleForm: FormGroup; userDetailsForm: FormGroup; searchForm: FormGroup; excelUploadForm: FormGroup; schedule = new Schedule(); lstSchedule = []; lstUserDeRole = [];
  attachmentSearchForm: FormGroup; videoSearchForm: FormGroup; anotationSearchForm: FormGroup;
  duration = ''; creatorId = 0; userDetails = new User(); btnText = 'Update'; user = {}; confpassword = ''; dropdownSettings = {};
  minToDate: Date; maxToDate: Date; minDate: Date; time = ''; minute = ''; minTime: Date = new Date(); maxTime: Date = new Date();
  lstSubscribeHistor = []; expiryDateTime = ''; scDate: any;
  btnQuickCreateScdul = 'Quick Schedule';
  MeetingModerators = '';
  btnCreateScdul = 'Create Schedule'; maxFromDate: Date; lst = []; lstLiveStrem = [];
  fromDate: any = new Date(); toDate: any = ''; count: number = 0; passwdShow = false; trailperiod = false; lstMail = []; selectMail = [];
  lstAdminCreateUser = []; attendeeDetailsList = []; selectUserId = 0; anotationList: Array<any> = []; timeZoneList: Array<any> = [];
  deleted = false; createrById = 0; zoneName = '';
  role = 1; eMail = ''; bbb_url: string = ''; multiRecordViewList = []; attachmentList = []; attachVideoList = [];
  dtTrigger: Subject<any> = new Subject();
  dtTriggerAttendee: Subject<any> = new Subject();
  dtTriggerMultiRecord: Subject<any> = new Subject();
  dtTriggerLiveStrem: Subject<any> = new Subject();
  dtTriggerAttachVideoLink: Subject<any> = new Subject();
  dtTriggerAnotation: Subject<any> = new Subject();
  dtOption: DataTables.Settings = {}; jwtTkn = ''; rowNo = 0;
  lstHrs: Array<any> = [];
  lstMin: Array<any> = [];
  lstReminder: Array<any> = [];
  lstLockTime: Array<any> = [];
  deleteRecordList: Array<any> = [];
  lstDomain: Array<any> = [];

  // lstHrs = [{ name: '00' }, { name: '01' }, { name: '02' }, { name: '03' }, { name: '04' }, { name: '05' }, { name: '06' }, { name: '07' }, { name: '08' }, { name: '09' }, { name: '10' }, { name: '11' }, { name: '12' }, { name: '13' }, { name: '14' }, { name: '15' }, { name: '16' }, { name: '17' }, { name: '18' }, { name: '19' }, { name: '20' }, { name: '21' }, { name: '22' }, { name: '23' }];
  // lstMin = [{ name: '00' }, { name: '01' }, { name: '02' }, { name: '03' }, { name: '04' }, { name: '05' }, { name: '06' }, { name: '07' }, { name: '08' }, { name: '09' }, { name: '10' }, { name: '11' }, { name: '12' }, { name: '13' }, { name: '14' }, { name: '15' }, { name: '16' }, { name: '17' }, { name: '18' }, { name: '19' }, { name: '20' },
  // { name: '21' }, { name: '22' }, { name: '23' }, { name: '24' }, { name: '25' }, { name: '26' }, { name: '27' }, { name: '28' }, { name: '29' }, { name: '30' }, { name: '31' }, { name: '32' }, { name: '33' }, { name: '34' }, { name: '35' }, { name: '36' }, { name: '37' }, { name: '38' }, { name: '39' }, { name: '40' },
  // { name: '41' }, { name: '42' }, { name: '43' }, { name: '44' }, { name: '45' }, { name: '46' }, { name: '47' }, { name: '48' }, { name: '49' }, { name: '50' }, { name: '51' }, { name: '52' }, { name: '53' }, { name: '54' }, { name: '55' }, { name: '56' }, { name: '57' }, { name: '58' }, { name: '59' }];
  repeat_minDate: Date = new Date();
  repeatedDate: any = new Date();
  results = []; serchText = '';
  isExistPrtspMtng = true; lstUserOnMeeting = []; meetingId: any;
  //#region RecurrenceObject
  recurrenceShow: boolean = false; repetEvery = 'Day'; endShow = 'never'; showDay = 'days'
  recurrence = new Recurrence(); recurrenceForm: FormGroup; selectedWeekList = []; untilDate: any;
  weekIdList = [{ id: 'btnSunday', value: 'SU' }, { id: 'btnMonday', value: 'MO' }, { id: 'btnTuesday', value: 'TU' }, { id: 'btnWednesday', value: 'WE' }, { id: 'btnThursday', value: 'TH' }, { id: 'btnFriday', value: 'FR' }, { id: 'btnSaturday', value: 'SA' }]
  lockTimeRequired: boolean = false;
  emailRequiredFor: boolean = true;
  //#endregion
  //#region Email excel upload
  excelFile: any = ''; formData: FormData; sampleEmailExcel = [];
  //#endregion
  moderatorPermission: Array<any> = [];
  sessionId: string = '';
  showInstantMeeting: boolean = false;
  joinLink: string = '';
  isJoinScreen: boolean = true;
  startWithAudioMuted: boolean = true; startWithVideoMuted: boolean;
  meetingSearch: string = "";

  page = 1;
  totalCount = 0;
  pageSize = 10;

  todayMeetingCount: number = 0;

  constructor(private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected entitysvc: EntityService,
    protected pageSvc: PaginationService,
    private datepipe: DatePipe,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
    if (sessionStorage.getItem('loginData')) {
      this.creatorId = JSON.parse(sessionStorage.getItem('loginData')).id;
      this.role = JSON.parse(sessionStorage.getItem('loginData')).role.id;
      this.createrById = JSON.parse(sessionStorage.getItem('loginData')).createdBy.id;
      let permissions = JSON.parse(sessionStorage.loginData).packpermissions;
      this.moderatorPermission = permissions.includes("NumberOfModerators");
      // console.log(this.moderatorPermission);

    }
    this.jwtTkn = JSON.parse(sessionStorage.getItem('jwtToken'));
    this.pageSvc.setPageNumber = 0; this.pageNumber = 0
    this.pageSvc.table_Data.subscribe((s) => { this.results = s, this.AllowEditMeetingAndCancel() });
    this.pageSvc.page_Number.subscribe((s) => (this.pageNumber = s));
    this.pageSvc.mthd_Name.subscribe((s) => (this.mthdname = s));
    this.pageSvc._payload.subscribe((s) => (this.payload = s));
    this.pageSvc._apiType.subscribe((s) => (this.apiType = s));
    this.pageSvc._svcTagIndx.subscribe((s) => (this.svcTagIndx = s));
    this.pageSvc._isLcl.subscribe((s) => (this.isLcl = s));
    this.pageSvc.entity_data.subscribe((s) => (this.entity = this.results));
    // [this.lstHrs, this.lstMin, this.lstLockTime, this.timeZoneList] = [commonJson.lstHrs, commonJson.lstMin, commonJson.lockTime, commonJson.timeZone];
    [this.lstHrs, this.lstMin, this.lstLockTime] = [commonJson.lstHrs, commonJson.lstMin, commonJson.lockTime];
    this.getTimeZone();
  }
  url = location.hostname != 'localhost' ? `${location.origin}/conference/` : environment.apiUrl + 'conference/';
  ngOnInit() {
    const isMeetingLink: boolean = location.href.split('aar/').length > 1;
    if (isMeetingLink) {
      this.isJoinScreen = false;
      this.joinLink = location.href;
      return;
    }
    const time = this.convertTimezone(new Date(), 'America/New_York');
    console.log('timezone time: ', time);
    this.searchForm = this.formBuilder.group({
      'user': 0,
      'toDate': ['', [Validators.required]],
      'fromDate': ['', [Validators.required]],
      'deleted': false,
    });
    this.attachSearchInit(), this.videoSearchInit(); this.anotationSearchInit();
    this.scheduleForm = this.formBuilder.group({
      'session': '',
      'name': ['', [Validators.maxLength(100), Validators.required]],
      'description': '',
      'date': [null, [Validators.required]],
      'startDate': '',
      'startTime': ['', [Validators.required, Validators.min(0), Validators.max(23)]],
      'endTime': ['', [Validators.required, Validators.min(0), Validators.max(59)]],
      'duration': ['', [Validators.required]],
      'time': '',
      'attendeePW': ['', [Validators.required, Validators.minLength(4)]],
      'guest': '',
      'passwordrequired': '',
      'record': [false],
      'maxParticipants': [''],
      'participantEmails': [''],
      'repeat': [false],
      'repeatTilldate': [false],
      'eMail': ['', Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")],
      'hidePresentation': '',
      'recurrence': '',
      'requiredWebinarLink': [false],
      'reminder': [false],
      'intervalId': 0,
      'emailRequired': [false],
      'breakOutRoom': [false],
      'lockPeriod': [false],
      'lockTime': 0,
      'domainId': 0,
      'zoneName': '',
      'zoneId': 0,
      'enabledParticipantAudioControl': [false],
      'saveRecordingsLocally': [false],
      'emailRequiredWithoutValidation': [false],
      'meetingModerators': ''

    });
    this.userDetailsForm = this.formBuilder.group({
      'id': 0,
      'name': ['', [Validators.maxLength(100), Validators.required]],
      'password': '',
      'confpassword': '',
      'role': {
        'id': [0, [Validators.required]],
      },
      'mobileNO': ['', [Validators.required]],
      'status': true,
      'address': '',
      'email': new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]),
    });
    //#region RecurrenceFrom
    this.recurrenceForm = this.formBuilder.group({
      'repeat': 'daily',
      'repeatEveryCount': '',
      'end': {
        'repeatEnd': '',
        'until': '',
        'count': '',
      },
      'repeatOn': {
        'daysOnWeek': [],
        'dayOrOther': '',
        'dayCount': '',
        'otherWeekDays': '',
        'otherDays': '',
        'monthOfYear': ''
      }
    });
    //#endregion
    this.excelUploadForm = this.formBuilder.group({
      'excelFile': ['', [Validators.required]],
      'file': ['', [Validators.required]],
    });
    this.dropdownSettings = {
      singleSelection: false,
      enableCheckAll: true,
      idField: 'email',
      textField: 'email',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      selectAllText: "All",
      unSelectAllText: "All",
      closeDropDownOnSelection: true,
    };

    this.getUserListByAdmin();
    let newdate = new Date();
    if (this.role == 2)
      setTimeout(() => { this.toDate = new Date(newdate.setDate(newdate.getDate() + 7)); this.onSearch() }, 100);
    else
      this.toDate = new Date(), this.onSearch();//this.getSchedule();
    // this.user = JSON.parse(sessionStorage.getItem('loginData'));
    this.getUserByID();
    this.minDate = new Date();
    let datenow = new Date();
    this.minTime.setHours(datenow.getHours());
    this.minTime.setMinutes(datenow.getMinutes());
    this.maxTime.setHours(24);
    this.maxTime.setMinutes(0);
    // this.expiryDateTime = JSON.parse(sessionStorage.getItem('loginData')).expiryDateTime == null ? '' : JSON.parse(sessionStorage.getItem('loginData')).expiryDateTime;
    // this.subscribe = JSON.parse(sessionStorage.getItem('loginData')).subscribed;
    // this.trailperiod = JSON.parse(sessionStorage.getItem('loginData')).trailPeriod;
    // this.getBBB_Api();
    $('#createSchedule').hide();

    this.validateCollaps();
    [`${location.origin}/assets/js/calender.js`].forEach(e => {
      this.loadScript(e).then(data => console.log("Script loaded successfully", data)).catch(err => console.error(err));
    });
    this.todaySearch();


    setTimeout(() => {
      // $('#calendar').datepicker().on('changeDate', function (ev) {
      //   const val: string = $("#calendar").data('datepicker').getFormattedDate('yyyy-mm-dd');
      //   const el = (document.getElementById("btnMonth") as HTMLInputElement);
      //   el.value = val;
      //   el.click();
      //   alert(val);
      // });

      // $('#calendar').datepicker().on('changeMonth', (ev) => {
      //   console.log(ev);
      //   alert(ev.date);
      //   // console.log(new Date(ev.date).getMonth() + 1);
      // });

      this.loadJQuery();
      this.todaySearch("Calender");

    }, 100);

  }
  get f() { return this.scheduleForm.controls; }
  // restrictZero(event: any) {
  //   this.moderatorsValue =event.target.value;
  //   if (event.target.value.length == 0 && event.key == '0')
  //     event.preventDefault();
  //     if (this.moderatorsValue > 10) {
  //       this.scheduleForm.controls['meetingModerators'].setValue('');
  //       return this.toastr.warning("please enter value less than 10");
  //     }

  // }
  digitKeyOnly(e: any) {
    var keyCode = e.keyCode == 0 ? e.charCode : e.keyCode;
    var value = Number(e.target.value + e.key) || 0;

    if ((keyCode >= 37 && keyCode <= 40) || (keyCode == 8 || keyCode == 9 || keyCode == 13) || (keyCode >= 48 && keyCode <= 57)) {
      return this.isValidNumber(value);
    }
    return false;
  }

  isValidNumber(number) {
    return (3 <= number && number <= 10)
  }

  async getSchedule() {
    this.loading = true; this.lstSchedule = [];
    jQuery('.dataTable').DataTable().destroy();
    jQuery('.dataTable').DataTable({ searching: false });
    await super.FetchData('Get', `${this.apiMethod.getMeetingByUserId}/${JSON.parse(sessionStorage.getItem('loginData')).id}`, {}, 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          this.lstSchedule = this.result.data;
          this.AllowEditMeetingAndCancel();
          // this.lstSchedule.forEach(e => {
          //   if (e.date == this.datepipe.transform(new Date(), 'yyyy-MM-dd')) {
          //     if (e.endTime >= this.datepipe.transform(new Date(), 'HH:mm:ss'))
          //       Object.assign(e, { ischk: true });
          //     else
          //       Object.assign(e, { ischk: false });
          //   } else if (e.date > this.datepipe.transform(new Date(), 'yyyy-MM-dd')) {
          //     Object.assign(e, { ischk: true });
          //   } else Object.assign(e, { ischk: false });
          // });
          this.dtTrigger.next();
        }
        this.loading = false;
      });
  }

  async resendMail(session: String) {
    if (!window.confirm('Are you sure you want to re-send the meeting links?'))
      return;
    this.loading = true;
    await super.FetchData('Get', `${this.apiMethod.resendMail}${session}`, {}, 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          if (this.result.status == 'OK')
            this.toastr.success(this.result.message);
        }
        this.loading = false;
      });
  }

  addEntity() {
    this.getParticipantMail();
    this.scheduleForm.enable(); this.recurrenceForm.enable();
    $('#btnSunday,#btnMonday,#btnTuesday,#btnWednesday,#btnThursday,#btnFriday,#btnSaturday,#monthOfYear,#repeatEnd,#repetCount,#untilDate,#dayOrOther,#dayCount,#otherWeekDays,#otherDays').prop("disabled", false);
    // this.scheduleForm.controls['participantEmails'].disable();
    this.btnCreateScdul = 'Create Schedule';
    this.btnQuickCreateScdul = 'Quick Schedule';
    this.schedule = new Schedule();
    this.MeetingModerators = this.defaultModerators;
    this.duration = '';
    this.untilDate = null;
    this.repeatedDate = new Date();
    // this.scDate = this.datepipe.transform( new Date(),'dd-MM-yyyy');
    this.scDate = new Date();
    this.minDate = new Date();
    this.excelFile = '';
    this.excelUploadForm.patchValue({ excelFile: '' });
    this.sampleEmailExcel = [];
    // if (new Date().getMinutes() > 30 && new Date().getHours() == 23) {
    //   this.repeatedDate = new Date();
    //   this.repeatedDate.setDate(this.repeatedDate.getDate() + 1);
    //   this.scDate = new Date();
    //   this.scDate.setDate(this.scDate.getDate() + 1);
    //   this.minDate = new Date();
    //   this.minDate.setDate(this.minDate.getDate() + 1);
    // }

    this.schedule.zoneName = this.timeZoneList.find(m => m.utctimeZoneName == Intl.DateTimeFormat().resolvedOptions().timeZone).utctimeZoneName;
    this.schedule.zoneId = this.timeZoneList.find(m => m.utctimeZoneName == Intl.DateTimeFormat().resolvedOptions().timeZone).id;
    if (new Date().getMinutes() > 30 && new Date().getHours() == 23) {
      this.time = '00';
      this.repeatedDate.setDate(this.repeatedDate.getDate() + 1);
      this.scDate.setDate(this.scDate.getDate() + 1);
    } else {
      this.time = new Date().getMinutes() < 30 ? this.datepipe.transform(new Date(), 'HH')
        : (new Date().getHours() < 9 ? '0' : '') + (new Date().getHours() + 1);
    }
    // this.time = this.datepipe.transform(new Date(), 'HH');
    this.minute = new Date().getMinutes() < 30 ? '30' : '00';

    this.schedule.attendeePW = this.autoGeneratePassword(10);
    this.schedule.record = false;
    if (this.trailperiod) {
      this.duration = '60';
      this.schedule.maxParticipants = '100';
      // this.scheduleForm.controls['maxParticipants'].disable();
      this.scheduleForm.controls['duration'].disable();
    } else {
      this.duration = '30';
      // this.scheduleForm.controls['maxParticipants'].enable();
      this.scheduleForm.controls['duration'].enable();
    }
    this.scheduleForm.controls['repeatTilldate'].disable();
    this.scheduleForm.controls['attendeePW'].disable();
    this.scheduleForm.controls['lockTime'].disable();

    this.schedule.duration = this.duration;

    this.recurrenceShow = false; this.endShow = 'never'; this.selectedWeekList = []; this.repetEvery = 'Day';
    this.recurrence = new Recurrence(); this.validateChange('add');
    // $('#myModal').show();
    $('#ScheduleList').hide(); $('#createSchedule').show();

  }

  enableOrDisableReatDate(status: any) {
    if (status.checked) {
      this.scheduleForm.controls['repeatTilldate'].enable();
      this.scheduleForm.controls['reminder'].disable();
      this.recurrenceShow = true; this.changeRepetText();
      this.schedule.reminder = false;
      this.schedule.intervalId = 0;
      this.schedule.breakOutRoom = false;
      this.scheduleForm.controls['breakOutRoom'].disable();
    }
    else {
      this.scheduleForm.controls['repeatTilldate'].disable();
      this.scheduleForm.controls['reminder'].enable();
      this.recurrenceShow = false; this.changeRepetText();
      this.scheduleForm.controls['breakOutRoom'].enable();
    }
  }

  async EditEntity(item: any) {
    this.excelUploadForm.patchValue({ excelFile: '' });
    Promise.all([this.getParticipantMail(), this.getDomain()]);
    // await this.onSearch().then(() => {
    //   let item1 = this.lstSchedule.find(m => m.id == item.id);
    this.scheduleForm.enable(); this.recurrenceForm.enable();
    $('#btnSunday,#btnMonday,#btnTuesday,#btnWednesday,#btnThursday,#btnFriday,#btnSaturday,#monthOfYear,#repeatEnd,#repetCount,#untilDate,#dayOrOther,#dayCount,#otherWeekDays,#otherDays').prop("disabled", false);
    this.schedule = JSON.parse(JSON.stringify(item as Schedule));
    if (+this.schedule.meetingModerators > 0)
      this.MeetingModerators = this.schedule.meetingModerators;
    else
      this.MeetingModerators = this.defaultModerators;
    //this.schedule.zoneName = this.schedule.zoneName ? this.schedule.zoneName : '';
    if (item.zoneId && item.zoneId > 0) {
      this.scDate = new Date(item.meetingStartDateTimeWithZone);
      this.time = item.meetingStartDateTimeWithZone.split('T')[1].split(':')[0];
      this.minute = item.meetingStartDateTimeWithZone.split('T')[1].split(':')[1];
    } else {
      this.scDate = new Date(this.schedule.startDateTime);
      this.time = this.schedule.startTime.split(':')[0];
      this.minute = this.schedule.startTime.split(':')[1];
    }
    this.repeatedDate = this.schedule.repeatTilldate ? new Date(this.schedule.repeatTilldate) : new Date();
    // this.schedule.repeatTilldate = this.datepipe.transform(this.schedule.repeatTilldate, 'dd-MM-yyyy');
    // let duration = ((parseInt(this.schedule.endTime.split(':')[0]) * 60) + parseInt(this.schedule.endTime.split(':')[1])) -
    //   ((parseInt(this.schedule.startTime.split(':')[0]) * 60) + parseInt(this.schedule.startTime.split(':')[1]));
    // var dif = Math.round(new Date(this.schedule.endDateTime).getTime() - new Date(this.schedule.startDateTime).getTime());
    // var duration = Math.round((dif / 1000) / 60);
    this.duration = this.schedule.duration;
    if (this.trailperiod) {
      // this.scheduleForm.controls['maxParticipants'].disable();
      this.scheduleForm.controls['duration'].disable();
    } else {
      // this.scheduleForm.controls['maxParticipants'].enable();
      this.scheduleForm.controls['duration'].enable();
    }
    if (this.schedule.repeat) {
      this.scheduleForm.controls['repeatTilldate'].enable();
      this.recurrenceShow = true;
    }
    else {
      this.scheduleForm.controls['repeatTilldate'].disable();
      this.recurrenceShow = false;
    }

    if (!this.schedule.passwordrequired) {
      this.scheduleForm.controls['attendeePW'].disable();
    }

    if (!this.schedule.lockPeriod) {
      this.scheduleForm.controls['lockTime'].disable();
    }
    if (this.schedule.domainId && this.schedule.domainId > 0) this.emailRequiredFor = false; else this.emailRequiredFor = true;

    this.btnCreateScdul = 'Update Schedule';
    this.btnQuickCreateScdul = 'Quick Schedule';
    // });
    // this.schedule.guest = item.guest == true ? 'true' : 'false';
    if (this.role == 2) {
      if (!item.ischk) {
        this.scheduleForm.disable(); this.recurrenceForm.disable();
        $(function () {
          $('#btnSunday,#btnMonday,#btnTuesday,#btnWednesday,#btnThursday,#btnFriday,#btnSaturday,#monthOfYear,#repeatEnd,#repetCount,#untilDate,#dayOrOther,#dayCount,#otherWeekDays,#otherDays').prop("disabled", true);
        });
      }
    }
    else {
      this.scheduleForm.disable(); this.recurrenceForm.disable();
      $(function () {
        $('#btnSunday,#btnMonday,#btnTuesday,#btnWednesday,#btnThursday,#btnFriday,#btnSaturday,#monthOfYear,#repeatEnd,#repetCount,#untilDate,#dayOrOther,#dayCount,#otherWeekDays,#otherDays').prop("disabled", true);
      });
    }

    if (this.schedule.session != '' && new Date(this.schedule.startDateTime) < new Date()) {
      this.minDate = this.scDate;
    }

    if (this.schedule.recurrence != null && this.schedule.recurrence != "{}") {
      this.recurrence = JSON.parse(this.schedule.recurrence);
      this.endShow = this.recurrence.end.repeatEnd;
      this.untilDate = this.recurrence.end.until != null ? new Date(this.recurrence.end.until) : this.recurrence.end.until;
      this.selectedWeekList = this.recurrence.repeatOn.daysOnWeek;
      // if (this.recurrence.repeat == 'weekly') {
      //   let arr = this.recurrence.repeatOn.daysOnWeek;
      //   this.weekIdList.forEach(e => {
      //     $('#' + e.id).removeClass("btnWeekColor");
      //   });
      //   arr.forEach(e => {
      //     let obj = this.weekIdList.find(m => m.value == e);
      //     $('#' + obj.id).addClass("btnWeekColor");
      //   });
      // }
      this.showDay = this.recurrence.repeatOn.dayOrOther;
      this.repetEvery = 'Day';
      this.onChangeRepeat(this.recurrence.repeat, 'edit');
    } else {
      this.recurrence = new Recurrence();
    }
    if (this.schedule.participantEmails)
      this.selectMail = this.schedule.participantEmails.split(';');
    // $('#myModal').show();
    $('#ScheduleList').hide(); $('#createSchedule').show();
  }

  getUserDetails() {
    this.userDetails = new User()
    this.getAllRole();
    this.user = JSON.parse(sessionStorage.getItem('loginData'));
    this.userDetails = this.user as User;

    $('#userModal').show();
  }

  async getAllRole() {
    this.loading = true; this.lstUserDeRole = [];
    await super.FetchData('Get', this.apiMethod.getAllRole, {})
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          this.lstUserDeRole = this.result.data;
        }
        this.loading = false;
      });
  }

  async onUserIdCheck() {
    this.loading = true;
    if (this.userDetails.email != '') {
      await super.FetchData('Get', `${this.apiMethod.checkExistEmail}${this.userDetails.email}/${this.userDetails.id}`, {})
        .catch(() => { this.httpSvc.handleError, this.loading = false; })
        .then(() => {
          if (this.result != []) {
            if (this.result.status != 'OK') {
              this.userDetails.email = '';
              return false;
            }
          }
          this.loading = false;
        });
    }
  }

  checkRecurrance() {

    if (this.schedule.repeat) {
      if (this.recurrence.end.repeatEnd == 'until') {
        if (this.untilDate != '' && this.untilDate != null) {
          if (new Date(this.untilDate).getTime() < new Date(this.scDate).getTime()) {
            this.toastr.error('Repeated Date should be greater than Start Date');
            (<HTMLInputElement>document.getElementById('untilDate')).value = '';
            (<HTMLInputElement>document.getElementById('untilDate')).focus();
            return false;
          }
        } else {
          this.toastr.error('Please select repeated end Date');
          return false;
        }
      }
    }

    let schedule = new Schedule(); schedule = this.schedule; let date = new Date(this.scDate);
    schedule.date = this.datepipe.transform(date, 'yyyy-MM-dd');
    schedule.startTime = `${this.time}:${this.minute}:00`; //this.datepipe.transform(dat, 'HH:mm:ss');
    schedule.startDateTime = `${schedule.date}T${schedule.startTime}`; //dat.toLocaleString('sv-SE').replace(' ', 'T');
    schedule.creator.id = this.creatorId;
    this.scDate = new Date(schedule.date);
    if (this.duration == '' || +this.duration == 0) {
      this.toastr.error('Please enter duration');
      return false;
    } else schedule.duration = this.duration;

    // if (!this.schedule.repeat) {
    //   if (schedule.date == this.datepipe.transform(new Date(), 'yyyy-MM-dd')) {
    //     if (schedule.startTime < this.datepipe.transform(new Date(), 'HH:mm:ss')) {
    //       this.toastr.error('Please enter future time');
    //       // this.time = '', this.minute = '',
    //       //this.loading = false;
    //       return false;
    //     }
    //   }
    // }

    if (!this.schedule.repeat) {
      let zone_name = this.timeZoneList.find(m => m.id == this.schedule.zoneId).utctimeZoneName;
      if (schedule.date == this.datepipe.transform(new Date().toLocaleString('en-US', { timeZone: zone_name, hour12: false }), 'yyyy-MM-dd')) {
        if (schedule.startTime < this.datepipe.transform(new Date().toLocaleString('en-US', { timeZone: zone_name, hour12: false }), 'HH:mm:00')) {
          this.toastr.error(`Please enter future time for ${zone_name}`);
          // this.time = '', this.minute = '',
          //this.loading = false;
          return false;
        }
      }
    }

    schedule.name = schedule.name.trim().replace(/[$']/g, "");
    if (schedule.name.length == 0) {
      this.toastr.error('Please enter meeting name');
      return false;
    }
    var regex = /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/;
    let lstEmails = [], lstWrongEmails = [];
    let lstSelectedEmails = this.schedule.participantEmails.trim().length > 0 ? this.schedule.participantEmails.trim().split(';') : [];
    lstSelectedEmails.forEach(e => {
      if (regex.test(e) && e != '')
        lstEmails.push(e);
      else
        lstWrongEmails.push(e);
    });
    this.schedule.participantEmails = lstEmails.join(';');
    if (lstWrongEmails.length > 0) {
      this.toastr.warning('Please enter valid emails, emails are ' + lstWrongEmails.toString());
      return false;
    }

    //Check for E-mail validation required

    if (this.schedule.emailRequired && this.schedule.participantEmails.length == 0) {
      this.toastr.warning('Please enter emails');
      return false;
    }
    this.formData = new FormData;

    //#region Recurrence
    if (this.recurrence.end.repeatEnd == 'until') {
      this.recurrence.end.until = this.datepipe.transform(this.untilDate, 'yyyy-MM-dd');
      this.repeatedDate = this.recurrence.end.until;
      this.recurrence.end.count = '';
    } else if (this.recurrence.end.repeatEnd == 'count') {
      this.recurrence.end.until = null;
      this.recurrence.end.count = this.recurrence.end.count == '' ? '1' : this.recurrence.end.count;
    }
    else {
      this.recurrence.end.count = '';
      this.recurrence.end.until = null;
    }
    this.recurrence.repeatEveryCount = this.recurrence.repeatEveryCount == '' ? '1' : this.recurrence.repeatEveryCount;
    if ((this.recurrence.repeat == 'yearly' || this.recurrence.repeat == 'monthly')) {
      if (this.recurrence.repeatOn.dayOrOther == 'days')
        this.recurrence.repeatOn.dayCount = this.recurrence.repeatOn.dayCount == '' ? '1' : this.recurrence.repeatOn.dayCount;
      else
        this.recurrence.repeatOn.dayCount = '';
    } else
      this.recurrence.repeatOn.dayCount = '';

    if (this.recurrence.repeat == 'monthly' || this.recurrence.repeat == 'yearly') {
      if (this.recurrence.repeatOn.dayOrOther == 'days') {
        if (+this.recurrence.repeatOn.dayCount > 31) {
          this.toastr.error('Please enter valid days of month');
          this.recurrence.repeatOn.dayCount = '';
          return false;
        }
        if (new Date(this.recurrence.end.until).getFullYear() == new Date(this.scDate).getFullYear() && new Date(this.recurrence.end.until).getMonth() == new Date(this.scDate).getMonth())
          if (+this.recurrence.repeatOn.dayCount > new Date(this.recurrence.end.until).getDate()) {
            this.toastr.error('Please enter valid recurrence pattern');
            this.recurrence.end.until = null; this.untilDate = null;
            return false;
          }
      }
    }
    if (this.recurrence.repeat == 'yearly') {
      if (this.recurrence.repeatOn.dayOrOther == 'days') {
        if (this.leapYear(new Date(this.scDate).getFullYear())) {
          if (this.recurrence.repeatOn.monthOfYear == '02' && +this.recurrence.repeatOn.dayCount > 29) {
            this.toastr.error('Please enter valid days of month');
            this.recurrence.repeatOn.dayCount = '';
            return false;
          }
        }
        else {
          if (this.recurrence.repeatOn.monthOfYear == '02' && +this.recurrence.repeatOn.dayCount > 28) {
            this.toastr.error('Please enter valide days of month');
            this.recurrence.repeatOn.dayCount = '';
            return false;
          }
        }
        if (['04', '06', '09', '11'].includes(this.recurrence.repeatOn.monthOfYear)) {
          if (+this.recurrence.repeatOn.dayCount > 30) {
            this.toastr.error('Please enter valid days of month');
            this.recurrence.repeatOn.dayCount = '';
            return false;
          }
        }
      }
      if (this.recurrence.end.until != null && +this.recurrence.repeatOn.dayCount > 0)
        if (new Date(this.recurrence.end.until) < new Date(`${new Date(this.scDate).getFullYear()}-${this.recurrence.repeatOn.monthOfYear}-${this.recurrence.repeatOn.dayCount}`)) {
          this.toastr.error('Please enter valid recurrence pattern');
          return false;
        }
    }

    //this.recurrence.repeatOn.dayCount = ((this.recurrence.repeat == 'yearly' || this.recurrence.repeat == 'monthly') && this.recurrence.repeatOn.dayOrOther == 'days' && this.recurrence.repeatOn.dayCount == '') ? '1' : this.recurrence.repeatOn.dayCount;
    //if (this.recurrence.repeat == 'weekly')

    schedule.recurrence = JSON.stringify(this.recurrence);
    //#endregion
    return true;
  }

  async generateSchedule() {
    if (this.moderatorsValue > 10) {
      this.scheduleForm.controls['meetingModerators'].setValue('');
      return this.toastr.warning("please enter meeting moderators value less than 10");
    }
    if (!this.checkRecurrance()) {
      return;
    }
    if (this.schedule.lockPeriod && this.schedule.lockTime == 0) {
      this.toastr.error('Please select lock time');
      return;
    }
    this.schedule.breakOutRoom = this.schedule.repeat ? false : this.schedule.breakOutRoom;
    this.schedule.zoneName = this.timeZoneList.find(m => m.id == this.schedule.zoneId).utctimeZoneName;
    if (this.moderatorPermission) {
      // this.scheduleForm.controls['MeetingModerators'].setValue(this.MeetingModerators);
      this.schedule.meetingModerators = this.MeetingModerators || this.defaultModerators;
    } else {
      this.schedule.meetingModerators = '';
    }
    let entity = Object.assign({}, JSON.parse(JSON.stringify(this.schedule)));
    entity.intervalId = +entity.intervalId;
    if (this.schedule.session != '')
      delete entity.ischk;
    entity.repeatTilldate = (this.schedule.repeat) ? this.datepipe.transform(new Date(this.repeatedDate), 'yyyy-MM-dd') : '';
    this.loading = true;
    await super.FetchData('Post', this.apiMethod.saveMeeting, JSON.stringify(entity), 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          if (this.result.status == 'OK') {
            this.toastr.success(this.result.message);
            this.schedule = new Schedule(); this.duration = '';
            //$(".close").click();  //  $('#myModal').hide();
            $('#ScheduleList').show(); $('#createSchedule').hide();
            document.getElementById('md_close').click();
            this.onSearch();// this.getSchedule();
            this.todaySearch();
            this.todaySearch("Calender", this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'));
          }
        } //else {
        this.loading = false;
      });
  }

  async saveUpdateUserDetails() {
    this.loading = true;
    await super.FetchData('Post', this.apiMethod.saveUser, JSON.parse(JSON.stringify(this.userDetails)))
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          if (this.result.data != null && this.result != undefined) {
            sessionStorage.setItem('loginData', JSON.stringify(this.result.data));
            this.httpSvc.loginUserDetails = this.result.data;
            this.httpSvc.userName = this.result.data.name;
            if (this.result.status == 'OK')
              this.toastr.success(this.result.message);
            // if (this.btnText == 'Save')
            //   this.toastr.success(this.message.saveSuccess);
            // else
            // this.toastr.success(this.message.updateSuccess);
            $(".close").click(); // $('#userModal').hide();
          }
        } //else {
        //   this.toastr.error(this.message.saveFailed);
        // }
        this.loading = false;
      });
  }

  onFromDateChange(val: any) {
    this.count++;
    console.log("this.count", this.count);
    this.minToDate = new Date();
    this.maxToDate = new Date();
    let formdate = this.datepipe.transform(val, 'yyyy-MM-dd');
    let todate = this.datepipe.transform(this.searchForm.get('toDate').value, 'yyyy-MM-dd');

    if (val != null) {
      if (new Date(formdate) > new Date(todate))
        this.toDate = this.count == 2 ? this.toDate : new Date(formdate);
      let edate = this.datepipe.transform(val, 'yyyy-MM-dd').split('-'), cnvDate = new Date(parseInt(edate[0]), parseInt(edate[1]) - 1, parseInt(edate[2]), 0, 0, 0, 0);
      this.minToDate = new Date(cnvDate);
      //  this.toDate = new Date(cnvDate.setDate(cnvDate.getDate() + 7));

      let mdate = this.datepipe.transform(val, 'yyyy-MM-dd').split('-'), cnvmDate = new Date(parseInt(mdate[0]), parseInt(mdate[1]) - 1, parseInt(mdate[2]), 0, 0, 0, 0);
      this.maxToDate = new Date(cnvmDate.setDate(cnvmDate.getDate() + 30));
    }
  }

  async onSearch(isReload: boolean = false) {
    this.serchText = '';
    // $('#chk_DeleteAllMeeting').prop('checked', false);
    // if ((this.role == 1 || this.role == 3) && this.selectUserId == 0)
    //   await this.();
    // else {
    //this.loading = true;
    this.dtOption = {}, this.results = [];
    let userId = ((this.role == 1 || this.role == 3) && this.selectUserId == 0) ? this.creatorId : (this.role == 1 || this.role == 3) ? this.selectUserId : this.creatorId;
    // let zoneName = this.timeZoneList.find(m => m.utctimeZoneName == Intl.DateTimeFormat().resolvedOptions().timeZone).utctimeZoneName;
    let zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
    console.log(zoneName);
    // jQuery('.dataTable').DataTable().destroy();
    // jQuery('.dataTable').DataTable({ searching: false });
    let payload = `{"creatorId":${this.creatorId},"role":${this.role},"deleted":${this.deleted},"requesterID":${userId},"fromDate":"${this.datepipe.transform(this.fromDate, 'yyyy-MM-dd')}","toDate":"${this.datepipe.transform(this.toDate, 'yyyy-MM-dd')}","zoneName":"${zoneName}"}`;
    /*
    this.dtOption = {
      pagingType: 'first_last_numbers',
      pageLength: 10,
      serverSide: true,
      processing: true,
      destroy: true,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span> '
      },
      ajax: (dataTablesParameters: any, callback) => {
        $('#chk_DeleteAllMeeting').prop('checked', false);
        this.http.post<DataTablesResponse>(
          `${this.url}${this.apiMethod.jwtgetMeetingList}`,
          Object.assign(dataTablesParameters, { payload: JSON.parse(payload) }), {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': JSON.parse(sessionStorage.getItem('jwtToken')),
          })
        }).
          subscribe(resp => {
            if (resp != undefined && resp != null) {
              let result: any = resp.data;
              if (result.data.length > 0) {
                this.results = result.data, this.rowNo = dataTablesParameters.start;
                this.AllowEditMeetingAndCancel();
              }
              else
                this.results = [], this.toastr.info(`No data available`);

              callback({
                recordsTotal: (this.results.length == 0 ? 0 : result.recordsTotal),
                recordsFiltered: (this.results.length == 0 ? 0 : result.recordsFiltered),
                data: []
              });
              //this.loading = false;
            }
            else
              this.toastr.error(`An error occurred while processing your request`);
            //this.loading = false, 
          },
            error => {
              if (this.result.status == 401)
                setTimeout(() => { console.clear(), console.log(this.result.message); }, 500), this.route.navigateByUrl(`/`), this.toastr.warning('Session Expired, kindly re-login');
            }
          );
      },
    };
    */
    // setTimeout(() => this.dtTrigger.next(), 100);
    //}

    const searchText = this.meetingSearch.trim();
    if (searchText.length > 0 || isReload) {
      this.page = 1;
      this.totalCount = 0;
      this.pageSize = 10;
    }
    this.http.post<DataTablesResponse>(
      `${this.url}${this.apiMethod.jwtgetMeetingList}`,
      Object.assign({ draw: this.page, start: (this.page - 1) * this.pageSize, length: this.pageSize, search: { value: searchText.length == 0 ? "" : searchText, regex: false } }, { payload: JSON.parse(payload) }), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(sessionStorage.getItem('jwtToken')),
      })
    }).
      subscribe(resp => {
        if (resp != undefined && resp != null) {
          let result: any = resp.data;
          if (result.data.length > 0) {
            this.results = result.data, //this.rowNo = dataTablesParameters.start;
              this.AllowEditMeetingAndCancel();
            this.totalCount = result.recordsFiltered;
          }
          else {
            this.results = [], this.toastr.info(`No data available`);
            this.page = 1;
            this.totalCount = 0;
            this.pageSize = 10;
          }
          // callback({
          //   recordsTotal: (this.results.length == 0 ? 0 : result.recordsTotal),
          //   recordsFiltered: (this.results.length == 0 ? 0 : result.recordsFiltered),
          //   data: []
          // });
          //this.loading = false;
        }
        else
          this.toastr.error(`An error occurred while processing your request`);
        //this.loading = false, 
      },
        error => {
          if (this.result.status == 401)
            setTimeout(() => { console.clear(), console.log(this.result.message); }, 500), this.route.navigateByUrl(`/`), this.toastr.warning('Session Expired, kindly re-login');
        }
      );
  }
  closeDelete() {
    this.sessionId = '';
  }

  async cancelMetting(session: String) {
    // if (!window.confirm('Are you sure you want to cancel the meeting?'))
    //   return;
    this.loading = true;
    await super.FetchData('Get', `${this.apiMethod.meetingCancel}${this.sessionId}`, {}, 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          if (this.result.status == 'OK') {
            this.toastr.success(this.result.message);
            this.closeDelete();
            this.onSearch(true);// this.getSchedule();
            this.todaySearch();
            this.todaySearch("Calender", this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'));
          }
        }
        this.loading = false;
      });
  }

  meetingDateChange(date: any) {
    if (this.expiryDateTime != '') {
      if (new Date(this.datepipe.transform(date, 'yyyy-MM-dd')) > new Date(this.datepipe.transform(this.expiryDateTime, 'yyyy-MM-dd'))) {
        this.toastr.error('Please select less then expiry date.');
        this.scheduleForm.get('date').patchValue(null);
      }
    }
    if (date)
      if ((new Date(this.datepipe.transform(date, 'yyyy-MM-dd'))).getTime() == (new Date(this.datepipe.transform(new Date(), 'yyyy-MM-dd'))).getTime())
        this.repeat_minDate = date;
      else
        this.repeat_minDate = new Date();
    this.loadReminderInterval();
  }

  clearSchesule() {
    this.schedule = new Schedule();
    this.scDate = new Date(), this.time = '', this.minute = '', this.duration = '';
    this.selectMail = [];
    $('#ScheduleList').show(); $('#createSchedule').hide();
  }

  async getUserListByAdmin() {
    this.loading = true; this.lstAdminCreateUser = [];
    await super.FetchData('Get', `${this.apiMethod.fetchUsersForDropDown}${this.creatorId}`, {}, 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          this.lstAdminCreateUser = this.result.data != [] ? this.result.data.sort((a, b) => a.name.localeCompare(b.name)) : this.result.data;
        }
        this.loading = false;
      });
  }

  connectLink(link: string) {
    if (window.orientation > -1)
      window.location.replace(link);
    else
      window.open(link);
  }

  private AllowEditMeetingAndCancel() {
    if (this.results != []) {
      this.results.forEach(e => {
        if (e.endDateTime || e.meetingEndDateTimeWithZone) {
          //let date = e.endDateTime.split('T')[0];
          let date = (e.zoneId != null && e.zoneId > 0) ? e.meetingEndDateTimeWithZone.split('T')[0] : e.endDateTime.split('T')[0];
          //if (e.date == this.datepipe.transform(new Date(), 'yyyy-MM-dd')) {
          let time = e.endTime;
          //   if (e.deleted){
          //     Object.assign(e, { ischk: true });
          // }else
          if (e.startDateTime != null || e.startDateTime != "")
            Object.assign(e, { startdatetime: `${this.datepipe.transform(e.startDateTime.split('T')[0], 'dd-MM-yyyy')} ${e.startDateTime.split('T')[1]}` });
          if (e.endDateTime != null || e.endDateTime != "")
            Object.assign(e, { enddatetime: `${this.datepipe.transform(e.endDateTime.split('T')[0], 'dd-MM-yyyy')} ${e.endDateTime.split('T')[1]}` });
          if (e.zoneId && e.zoneId > 0) {
            Object.assign(e, { displayListDate: e.meetingStartDateTimeWithZone });
            Object.assign(e, { starttime: `${this.datepipe.transform(e.meetingStartDateTimeWithZone, 'HH:mm')}` });
            Object.assign(e, { endtime: `${this.datepipe.transform(e.meetingEndDateTimeWithZone, 'HH:mm')}` });
            //time = this.datepipe.transform(e.meetingEndDateTimeWithZone, 'HH:mm');
            let zoneTime = e.currentTimeZone.split('-')[1].split(' ')[0];
            time = zoneTime;
            //time = this.datepipe.transform(e.meetingEndDateTimeWithZone, 'HH:mm');
          } else {
            Object.assign(e, { displayListDate: e.date });
            Object.assign(e, { starttime: `${this.datepipe.transform(e.startDateTime, 'HH:mm')}` });
            Object.assign(e, { endtime: `${this.datepipe.transform(e.endDateTime, 'HH:mm')}` });
            time = this.datepipe.transform(e.endDateTime, 'HH:mm');
          }
          // Object.assign(e, { starttime: `${this.datepipe.transform(e.startDateTime, 'HH:mm')}` });
          // Object.assign(e, { endtime: `${this.datepipe.transform(e.endDateTime, 'HH:mm')}` });



          if (e.repeat && e.repeatTilldate > this.datepipe.transform(new Date(), 'yyyy-MM-dd')) {
            Object.assign(e, { ischk: true });
          } else if (date == this.datepipe.transform(new Date(), 'yyyy-MM-dd')) {
            // if (e.endTime >= this.datepipe.transform(new Date(), 'HH:mm:ss'))
            if (time >= this.datepipe.transform(new Date(), 'HH:mm:ss'))
              Object.assign(e, { ischk: true });
            else
              Object.assign(e, { ischk: false });
          } else if (date > this.datepipe.transform(new Date(), 'yyyy-MM-dd')) {
            Object.assign(e, { ischk: true });
          } else Object.assign(e, { ischk: e.repeat ? (new Date(e.repeatTilldate)).getTime() >= (new Date(this.datepipe.transform(new Date(), 'yyyy-MM-dd'))).getTime() : false });
        }
      });
    }
  }

  // playVideo(meeting_id: string) {
  //   this.multiRecordViewList = [];
  //   jQuery('.dataTable').DataTable().destroy();
  //   jQuery('.dataTable').DataTable({ searching: false });
  //   this.entitysvc.GetData1(`recording/getRecordingById/${meeting_id}`).subscribe(res => {
  //     const result = res.json();
  //     if (result.status === 'OK' && result.message === 'success' && result.data!=[]) {
  //       // if (result.data.response.hasOwnProperty('messageKey'))//(result.data.messageKey === 'noRecordings')
  //       //   this.toastr.error('', result.data.response.message);
  //       // else {
  //       if (result.data.length > 0) {
  //         this.multiRecordViewList = result.data.sort((a, b) => a.startTime - b.startTime);
  //       }
  //       else {
  //         this.multiRecordViewList.push(result.data);
  //       }
  //       this.dtTrigger.next();
  //       // $('#btnMultiRecord').click();
  //       //}
  //     }
  //   });
  // }
  playVideo(meeting_id: string) {
    this.meetingId = meeting_id;
    this.attachSearchInit(), this.videoSearchInit(); this.anotationSearchInit();
    Promise.all([this.getMultuVideo(meeting_id), this.getMeetingUser(meeting_id), this.getAttachments(meeting_id), this.getVideoList(meeting_id), this.getAnotationList(meeting_id)]);
  }

  getMultuVideo(meeting_id: any) {
    this.multiRecordViewList = [];
    jQuery('.dataTable').DataTable().destroy();
    jQuery('.dataTable').DataTable({ searching: false });
    this.entitysvc.GetData1(`${this.apiMethod.getRecordingById}/${meeting_id}`).subscribe(res => {
      const result = res.json();
      if (result.status === 'OK' && result.message === 'success' && result.data) {
        if (result.data.length > 0) {
          this.multiRecordViewList = result.data.sort((a, b) => a.startTime - b.startTime);
        }
        else {
          if (result.data === 'object' && result.data !== null)
            this.multiRecordViewList.push(result.data);
        }
        this.dtTrigger.next();
      }
    });

  }

  downloadRecording(item: any) {
    //let url = location.hostname != 'localhost' ? `${location.origin}/conference/` : environment.apiUrl + 'conference/';
    //window.open(`${url}nojwt/recording/getfile/${item.recordID}.mp4`);
    window.open(item.playbackurl.trim().replace("viewfile", "getfile"));

  }

  showRecordingView(item: any) {
    // window.open(item.playback.format.url.trim(), "_blank");
    //window.open(item.playbackUrl.trim(), "_blank");
    $('.closeMultiRecordingViewModal').click();
    const videoTag = document.getElementById('recorVideoPlay');
    let video = document.createElement('video') as HTMLVideoElement;
    video.src = item.playbackurl.trim();
    video.setAttribute('id', 'playVideos');
    video.setAttribute('class', 'modal-lg');
    video.setAttribute('controls', 'true');
    video.setAttribute('controlsList', 'nodownload');
    video.autoplay = true;
    video.load();
    videoTag.appendChild(video);
  }

  closeVideo() {
    const video = document.getElementById('playVideos') as HTMLVideoElement;
    const videoTag = document.getElementById('recorVideoPlay');
    setTimeout(() => {
      videoTag.removeChild(video);
    }, 10);
  }


  inviteLinkOld(item: any, type: string) {
    //  this.getMetaTag(item);
    let linkType = '';
    let link = '';
    if (type == 'participant') {
      linkType = 'Participant link';
      link = item.participantLink
    } else {
      linkType = 'Webinar link';
      link = item.listenOnlyLink
    }
    let timezone = item.zoneName.length > 0 ? item.zoneName : '';
    let withoutPassCodeTag = `    Meeting Name : ${item.name}
    Host	: ${item.creator.name}
    Date 	: ${item.date}
    Time 	: ${item.starttime} - ${item.endtime}${item.zoneName.length > 0 ? `(${item.zoneName})` : ''}
    IS Time  : ${item.currentTimeZone}
    ${linkType}: ${link}`;

    let withPassCodeTag = `    Meeting Name : ${item.name}
    Host	: ${item.creator.name}
    Date 	: ${item.date}
    Time  : ${item.starttime} - ${item.endtime}${item.zoneName.length > 0 ? `(${item.zoneName})` : ''}
    IS Time  : ${item.currentTimeZone}
    Password  : ${item.attendeePW}
    ${linkType}: ${link}`;

    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', item.passwordrequired ? withPassCodeTag : withoutPassCodeTag);
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    this.toastr.success('Copied link successfully');
  }

  inviteLink(item: any, type: string) {
    let invitationFormat = `    Meeting Name : ${item.name}
    Host	: ${item.creator.name}
    Date 	: ${item.date}`;
    if (item.zoneName && item.zoneName.length > 0)
      invitationFormat = invitationFormat + `\n    Time 	: ${item.starttime} - ${item.endtime}${(item.zoneName && item.zoneName.length > 0) ? `(${item.zoneName})` : ''}`;
    //invitationFormat = invitationFormat + `\n    IS Time  : ${item.currentTimeZone}`;
    invitationFormat = invitationFormat + `\n    IS Time  : ${this.datepipe.transform(item.startDateTime, 'HH:mm')}-${this.datepipe.transform(item.endDateTime, 'HH:mm')} (Asia/Kolkata)`;
    if (item.passwordrequired)
      invitationFormat = invitationFormat + `\n    Password  : ${item.attendeePW}`;
    if (type == 'participant')
      invitationFormat = invitationFormat + `\n    Participant link : ${item.participantLink}`;
    else
      invitationFormat = invitationFormat + `\n    Webinar link : ${item.listenOnlyLink}`;

    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', invitationFormat);
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    this.toastr.success('Copied link successfully');
  }

  copyParticipantLink(link: any) {
    if (link) {
      let listener = (e: ClipboardEvent) => {
        e.clipboardData.setData('text/plain', link);
        e.preventDefault();
      };
      document.addEventListener('copy', listener);
      document.execCommand('copy');
      document.removeEventListener('copy', listener);
      this.toastr.success('Copied link successfully');
    }
  }

  autoGeneratePassword(length) {
    var chars = "1234567890";
    var pass = "";
    for (var x = 0; x < length; x++) {
      var i = Math.floor(Math.random() * chars.length);
      pass += chars.charAt(i);
    }
    return pass;
  }

  changeCheckBox(status: any, type: string = 'PassRequired') {
    //this.scheduleForm.controls['attendeePW'].enable();
    if ('PassRequired' === type) {
      if (status.checked)
        this.scheduleForm.controls['attendeePW'].enable();
      else {
        this.scheduleForm.controls['attendeePW'].disable();
        if (this.scheduleForm.controls['attendeePW'].value == '') {
          this.schedule.attendeePW = this.autoGeneratePassword(10);
        }
      }
    }
    else if ('LockPeriod' === type) {
      if (status.checked) {
        this.scheduleForm.controls['lockTime'].enable();
        this.scheduleForm.controls['emailRequired'].disable();
        this.scheduleForm.controls['emailRequiredWithoutValidation'].disable();
        this.schedule.emailRequired = true;
        this.schedule.emailRequiredWithoutValidation = false;
      }
      else {
        this.schedule.lockTime = 0;
        this.scheduleForm.controls['lockTime'].disable();
        if (!this.checkEmailRequired()) {
          this.scheduleForm.controls['emailRequired'].enable();
          this.scheduleForm.controls['emailRequiredWithoutValidation'].enable();
        }
        //this.schedule.emailRequired = false;
        this.schedule.emailRequired = this.checkEmailRequired();
      }
    }
    else if ('BreakoutRoom' === type) {
      if (status.checked) {
        this.scheduleForm.controls['emailRequired'].disable();
        this.scheduleForm.controls['emailRequiredWithoutValidation'].enable();
        this.schedule.emailRequired = true;
        this.schedule.emailRequiredWithoutValidation = false;
      }
      else {
        if (!this.checkEmailRequired()) {
          this.scheduleForm.controls['emailRequired'].enable();
          this.scheduleForm.controls['emailRequiredWithoutValidation'].disable();
        }
        //this.schedule.emailRequired = false;
        this.schedule.emailRequired = this.checkEmailRequired();
        this.schedule.emailRequiredWithoutValidation = !this.checkEmailRequired();
      }
    }
  }

  validatePatispant() {
    let number = this.schedule.maxParticipants;
    let num = [1, 2];
    if (num.includes(+number)) {
      this.toastr.error('Please enter minimum 3 Participants');
      this.schedule.maxParticipants = '';
      return;
    }
  }

  addMail() {
    this.schedule.participantEmails += this.schedule.participantEmails == '' ? this.eMail : ' , ' + this.eMail;
    this.eMail = '';
  }

  validateEmail() {
    // var regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    var regex = /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/;
    var x = this.schedule.participantEmails;
    if (!regex.test(x)) {
      this.toastr.error('please enter valid Email');
      this.schedule.participantEmails = '';
      this.selectMail = [];
    }
    let valideMails = this.schedule.participantEmails

    // else
    // var emails = x.split(";");
    // emails.forEach(function (email) {
    //   if (regex.test(email.trim())) {
    //   }
    // });
  }

  // getBBB_Api() {
  //   const getCheckSumUrl$ = this.entitysvc.GetData1('login/getAVServiceUrl').subscribe(res => {
  //     const result = res.json();
  //     if (result.message === 'getAVServiceUrl' && result.status === 'OK') {
  //       this.bbb_url = result.data.avserviceurl;
  //     }
  //   });
  // }

  openAttendeeDetailsModal(session: string) {
    this.attendeeDetailsList = [];
    jQuery('.dataTable').DataTable().destroy();
    jQuery('.dataTable').DataTable({ searching: false });
    this.httpSvc.newGet(`${this.url}${this.apiMethod.fetchAttendeeDataByMeetingID}/${session} `).subscribe(res => {
      const result = res.json();
      if (result.status === 'OK') {
        // this.attendeeDetailsList = result.data;
        this.attendeeDetailsList = result.data != [] ? result.data.sort((a, b) => a.startdatetime.localeCompare(b.startdatetime)) : result.data;
        //this.dtTriggerAttendee.next();
        this.attendeeDetailsList = this.attendeeDetailsList.map(e => ({ ...e, newusername: e.meetingendedby ? `<strong>${e.username}</strong></br>(End By)` : e.username }));
        this.dtTrigger.next();
      }
    });
  }
  payUrl = '';

  async renewalPackOfUser() {
    let url = location.hostname != 'localhost' ? `${location.origin}/conference/` : environment.apiUrl;
    let [payload, userData] = [{}, JSON.parse(sessionStorage.getItem('loginData'))];
    if (userData.packId && userData.packId > 0) {
      this.payUrl = `${url}${this.apiMethod.startPayment}/${userData.id}/${userData.packId}`;
      (<HTMLAnchorElement>document.getElementById('payId')).href = this.payUrl;
      document.getElementById('payId').click();
    } else {
      this.toastr.info("No valid pack found ..")
    }
  }

  async getParticipantMail() {
    this.loading = true; this.lstUserDeRole = [];
    await super.FetchData('Get', `${this.apiMethod.getparticipantmails}/${JSON.parse(sessionStorage.getItem('loginData')).id}`, {}, 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          this.lstMail = this.result.data;
        }
        this.loading = false;
      });
  }

  onMultiSelect(type: string, item: any) {
    this.lst = this.schedule.participantEmails.trim().length > 0 ? this.schedule.participantEmails.trim().split(';') : [];
    if (type == 's') {
      this.lst.push(item);
    }
    else if (type == 'r') {
      this.lst.forEach((e: any, i: any) => {
        if (e == item)
          this.lst = this.lst.filter(m => m != item);
      });
    }
    else if (type == 'a') {
      this.selectMail = this.lstMail;
      item.forEach(e => { this.lst.push(e) });
    }
    else {
      this.selectMail = []; this.lstMail.forEach((e) => {
        //if(this.lst.includes(e.email))
        this.lst = this.lst.filter(m => m != e);
      });
    }
    // this.schedule.participantEmails = this.lst.toString();
    this.schedule.participantEmails = this.lst.join(";");
    this.isExistPrtspMtng = this.schedule.participantEmails.length > 0 ? false : true;
  }

  async joinLiveStrem(item: any) {
    let url = location.hostname != 'localhost' ? `${location.origin}/` : environment.apiUrl;
    let payload = `{"meetingID":${item.meetingID},"streamID":${item.id},"userID":${this.creatorId},"meetingName":"${item.meetingName}"}`;
    this.httpSvc.newPost(`${url}${this.apiMethod.goforbroadcast}`, payload).subscribe(res => {
      const result = res.json();
      if (result.status === 'OK') {
        this.toastr.success(result.message);
        $('.close').click();
      }
    });
  }

  updateStreming(item: any) {
    const streamData =
    {
      "id": item.id,
      "user": {
        "id": item.user.id
      },
      "master": {
        "id": item.master.id
      },
      "streamurl": item.streamurl,
      "streamKey": item.streamKey,
      "userName": item.userName,
      "liveStream": true
    }
    if (+streamData.id == 0)
      delete streamData.id;
    this.loading = true;
    super.FetchData('Post', `${this.apiMethod.addUserStream}/${item.meetingID}`, streamData, 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          if (this.result.status == 'OK') {
            this.toastr.success(this.result.message);
          } else {
            this.toastr.error(this.result.message);
          }
        }
        this.loading = false;
      });
  }

  async getAllStreams(item: any) {
    this.loading = true; this.lstLiveStrem = [];
    jQuery('.dataTable').DataTable().destroy();
    jQuery('.dataTable').DataTable({ searching: false });
    await super.FetchData('Get', `${this.apiMethod.getUserStreamData}${this.creatorId}`, {}, 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          this.lstLiveStrem = this.result.data;
          this.lstLiveStrem.forEach(m => { Object.assign(m, { meetingID: item.session, meetingName: item.name }) });
          this.dtTrigger.next();
        }
        this.loading = false;
      });
  }

  async forceEndMeeting(item: any) {
    if (!window.confirm('Are you sure you want to End this Meeting?'))
      return;
    this.loading = true;
    this.httpSvc.newGet(`${this.url}${this.apiMethod.noJwtforceEndMeeting}/${item.session}`)
      .subscribe(res => {
        if (this.result != []) {
          if (this.result.status == 'OK')
            this.toastr.success(this.result.message);
        }
        this.loading = false;
      },
        err => {
          this.toastr.success(this.result.message);
          this.loading = false;
        });
    //  await super.FetchData('Get', `${this.apiMethod.forceEndMeeting}${item.session}`, {}, 1)
    //  .catch(() => { this.httpSvc.handleError, this.loading = false; })
    //this.loading = false;
  }

  instantMeeting() {
    this.showInstantMeeting = true;
  }

  closeInstantMeeting(meetingDetails: { showInstantMeeting: boolean, enableAudio: boolean, enableVideo: boolean, cancelMeeting: boolean }) {
    this.showInstantMeeting = meetingDetails.showInstantMeeting;
    if (!meetingDetails.cancelMeeting) {
      this.startWithVideoMuted = !meetingDetails.enableVideo;
      this.startWithAudioMuted = !meetingDetails.enableAudio;
      this.createNow();
    }
    else
      setTimeout(() => this.onSearch());

    [`${location.origin}/assets/js/calender.js`].forEach(e => {
      this.loadScript(e).then(data => { console.log("Script loaded successfully", data); this.loadJQuery(); this.todaySearch("Calender", this.datepipe.transform(this.fromDate, 'yyyy-MM-dd')); }).catch(err => console.error(err));
    });

  }

  async createNow() {

    this.schedule = new Schedule(); this.duration = '';
    this.repeatedDate = new Date();
    this.scDate = new Date();
    this.time = this.datepipe.transform(new Date(), 'HH');
    this.minute = new Date().getMinutes() + '';
    this.schedule.attendeePW = this.autoGeneratePassword(10);
    this.schedule.zoneName = this.timeZoneList.find(m => m.utctimeZoneName == Intl.DateTimeFormat().resolvedOptions().timeZone).utctimeZoneName;
    this.schedule.zoneId = this.timeZoneList.find(m => m.utctimeZoneName == Intl.DateTimeFormat().resolvedOptions().timeZone).id;
    this.schedule.record = false;
    this.schedule.meetingModerators = '';
    if (this.trailperiod) {
      this.duration = '60';
      this.schedule.maxParticipants = '100';
    } else {
      this.duration = '30';
    }
    this.minDate = new Date();

    let schedule = new Schedule(); schedule = this.schedule; let date = new Date(this.scDate);
    schedule.date = this.datepipe.transform(date, 'yyyy-MM-dd');
    //#region commeted for safari issue
    // let dat = new Date(`${this.datepipe.transform(date, 'yyyy-MM-dd')} ${+this.time}:${+this.minute}:00`);
    // let startDate = new Date(`${this.datepipe.transform(date, 'yyyy-MM-dd')} ${+this.time}:${+this.minute}:00`);
    // if (dat)
    //   schedule.startTime = this.datepipe.transform(dat, 'HH:mm:ss');
    // else {
    //   schedule.startTime = this.datepipe.transform(new Date(), 'HH:mm:ss');
    //   dat = new Date();
    // }
    // schedule.startDateTime = dat.toLocaleString('sv-SE').replace(' ', 'T');
    // schedule.endDateTime = new Date(dat.setMinutes(startDate.getMinutes() + +this.duration)).toLocaleString('sv-SE').replace(' ', 'T');
    // schedule.endTime = this.datepipe.transform(new Date(startDate.setMinutes(startDate.getMinutes() + +this.duration)), 'HH:mm:ss');
    //#endregion

    //#region new added
    this.time = this.time.length == 1 ? `0${this.time}` : this.time;
    this.minute = this.minute.length == 1 ? `0${this.minute}` : this.minute;
    if (this.time != "" && this.minute != "") {
      schedule.startTime = `${this.time}:${this.minute}:00`;
      schedule.startDateTime = `${schedule.date}T${schedule.startTime}`;
    }
    else {
      schedule.startTime = this.datepipe.transform(new Date(), 'HH:mm:ss');
      schedule.startDateTime = `${this.datepipe.transform(new Date(), 'yyyy-MM-dd')}T${schedule.startTime}`;
    }
    //#endregion

    schedule.creator.id = this.creatorId;
    schedule.duration = this.duration;
    this.scDate = new Date(schedule.date);

    // schedule.name = this.datepipe.transform(date, 'yyyyMMddHHmmss');
    schedule.name = 'Quick Discussion';

    let entity = Object.assign({}, JSON.parse(JSON.stringify(schedule)));
    this.loading = true;
    await super.FetchData('Post', this.apiMethod.saveMeeting, JSON.stringify(entity), 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          if (this.result.status == 'OK') {
            this.toastr.success(this.result.message);
            this.schedule = new Schedule(); this.duration = '';
            $(".close").click();  //  $('#myModal').hide();
            this.onSearch();// this.getSchedule();
            this.todaySearch();
            this.todaySearch("Calender", this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'));
            const startWith: string = btoa(JSON.stringify({ startWithVideoMuted: this.startWithVideoMuted, startWithAudioMuted: this.startWithAudioMuted }));
            window.open(`${this.result.data.hostLink}_${startWith}`);
          }
        } //else {
        this.loading = false;
      });
  }

  //#region Recurrence
  onChangeRepeat(event: any, mode = 'add') {
    switch (event) {
      case 'weekly': {
        this.repetEvery = 'Week';
        if (mode != 'edit')
          this.validateChange(event);
      }
        break;
      case 'monthly': {
        this.repetEvery = 'Month';
        if (mode != 'edit')
          this.validateChange(event);
      }
        break;
      case 'yearly': {
        this.repetEvery = 'Year';
        if (mode != 'edit')
          this.validateChange(event);
      }
        break;
      default: {
        this.repetEvery = 'Day';
        if (mode != 'edit')
          this.validateChange(event);
      }
        break;
    }
  }

  validateChange(item: string) {
    this.selectedWeekList = [];
    this.recurrence.repeatOn.daysOnWeek = [];
    this.recurrence.repeatOn.dayCount = '1';
    this.recurrence.repeatOn.dayOrOther = 'days';
    this.recurrence.repeatOn.monthOfYear = '01';
    this.recurrence.repeatOn.otherDays = 'sunday';
    this.recurrence.repeatOn.otherWeekDays = 'first';
    this.recurrence.repeatEveryCount = '1';
    this.recurrence.end.repeatEnd = 'never';
    this.endShow = 'never'; this.recurrence.end.count = '1'; this.recurrence.end.until = null;
    this.untilDate = null; this.showDay = 'days';

  }

  validateNumber(eve: any) {
    var numver = /^[1-9][0-9]{1,2}$|^\d$/;
    if (!eve.target.value.match(numver))
      eve.target.value = '';
    if (eve.target.value.charAt(0) === '0')
      eve.target.value = '';
  }

  onClickWeek(event, value) {
    $('#' + event.target.id).toggleClass("btnWeekColor");
    if (this.selectedWeekList.includes(value)) {
      const index = this.selectedWeekList.indexOf(value);
      if (index > -1) {
        this.selectedWeekList.splice(index, 1);
      }
    } else {
      this.selectedWeekList.push(value);
    }
    this.recurrence.repeatOn.daysOnWeek = this.selectedWeekList;
  }
  //#endregion

  //#region Emails excel Upload
  async onSelectFile(event) {
    this.formData = new FormData;
    let file = event.target.files[0];
    if (file) {
      this.excelFile = file.name;
      this.excelUploadForm.get('excelFile').setValue(event.target.files[0].name);
      this.formData.append('file', file);
      await this.excelToJson(file);
    }
  }

  async excelToJson(file: any) {
    let validMail = []; this.sampleEmailExcel = [];
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (e: any) => {
      const binarystr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      validMail.push(data);
      validMail[0].forEach(e => {
        if (e.email != '' && e.email != undefined)
          this.sampleEmailExcel.push(e.email)
      });

      // var regex = /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/;
      // let lstEmails = [], lstWrongEmails = [];
      // this.sampleEmailExcel.forEach(e => {
      //   if (regex.test(e))
      //     lstEmails.push(e);
      //   else
      //     lstWrongEmails.push(e);
      // });
      let lstExistEmails: any[] = this.schedule.participantEmails.trim().length > 0 ? this.schedule.participantEmails.trim().split(';') : [];
      // lstExistEmails.push(...lstEmails);
      // this.toastr.warning('Please enter valid emails, emails are ' + lstWrongEmails.toString());
      lstExistEmails.push(...this.sampleEmailExcel);
      this.schedule.participantEmails = lstExistEmails.join(';');
    };
  }

  downloadEmailExcelFormatFile() {
    var jsonArray = [];
    jsonArray.push({
      sno: 1,
      email: '',
    });
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonArray);
    const workbook: XLSX.WorkBook = { Sheets: { 'Sheet1': worksheet }, SheetNames: ['Sheet1'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    });
    const url = window.URL.createObjectURL(data);
    window.open(url);

  }
  //#endregion

  validate(event) {
    if (event.target.value === ',')
      event.target.value = '';
  }

  fixDate(d: Date): Date {
    // d.setHours(d.getHours() - d.getTimezoneOffset() / 60);
    d.setTime(d.getTime() + d.getTimezoneOffset() * 60000);
    return d;
  }

  leapYear(year) {
    return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
  }

  exportTOExcel(list: any) {
    var jsonArray = [];
    var sno = 1;
    list.forEach(e => {
      jsonArray.push({
        'S No': sno,
        'User Name': e.meetingendedby ? `${e.username}(End By)` : e.username,
        'Role': e.role,
        'Start Date & Time': this.datepipe.transform(e.zoneid > 0 ? e.userJoinedWithTimeZone : e.startdatetime, 'yyyy-MM-dd HH:mm'),
        'End Date & Time': this.datepipe.transform(e.zoneid > 0 ? e.userLeftWithTimeZone : e.leftdatetime, 'yyyy-MM-dd HH:mm'),
        //'Duration': e.duration
        'Duration': this.getDuration(e.startdatetime, e.leftdatetime)
      });
      sno++;
    });
    //const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById('attendeeTable'), { raw: true });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonArray);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'AttendeeDetails_' + new Date().getTime() + '.xlsx');
  }

  changeRepetText() {
    this.repetEvery = this.recurrence.repeat == 'daily' ? 'Day' : this.recurrence.repeat == 'weekly' ? 'Week' : this.recurrence.repeat == 'monthly' ? 'Month' : 'Year';
  }

  colorChange(weekDay: string) {
    return (this.recurrence.repeatOn.daysOnWeek.includes(weekDay)) ? 'btnWeek btnWeekColor' : 'btnWeek';
  }

  async convertMp4(item: any) {
    this.loading = true;
    await super.FetchData('Get', `${this.apiMethod.convertMp4}/${item.recordID}`, {})
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result.status === 'OK') {
          this.toastr.success(this.result.message);
        }
        this.loading = false; $(".close").click();
      });
  }

  ngOnDestroy() {
    $(".close").click();
  }

  async getUserByID() {
    var id = JSON.parse(sessionStorage.getItem('loginData')).id;
    await super
      .FetchData("Get", `${this.apiMethod.getUserByID}${id}`, {}, 1)
      .catch(() => {
        this.httpSvc.handleError;
      })
      .then(() => {
        if (this.result.data != {}) {
          let userData = this.result.data;
          this.user = userData;
          this.expiryDateTime = userData.expiryDateTime == null ? '' : userData.expiryDateTime;
          this.subscribe = userData.subscribed;
          this.trailperiod = userData.trailPeriod;
        }
      });
  }

  joinPartispant(participantLink: string) {
    window.open(participantLink);
  }

  async checkSchedule() {

    if (!this.checkRecurrance()) {
      return;
    }
    this.loading = true;

    let schedule = new Schedule(); schedule = this.schedule; let date = new Date(this.scDate);
    schedule.date = this.datepipe.transform(date, 'yyyy-MM-dd');
    schedule.startTime = `${this.time}:${this.minute}:00`; //this.datepipe.transform(dat, 'HH:mm:ss');
    schedule.startDateTime = `${schedule.date}T${schedule.startTime}`; //dat.toLocaleString('sv-SE').replace(' ', 'T');
    schedule.creator.id = this.creatorId;

    // var regex = /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/;
    // let lstEmails = [], lstWrongEmails = [];
    // let lstSelectedEmails = this.schedule.participantEmails.trim().length > 0 ? this.schedule.participantEmails.trim().split(';') : [];
    // lstSelectedEmails.forEach(e => {
    //   if (regex.test(e) && e != '')
    //     lstEmails.push(e);
    //   else
    //     lstWrongEmails.push(e);
    // });
    // schedule.participantEmails = lstEmails.join(';');
    // if (lstWrongEmails.length > 0){
    //   this.toastr.warning('Please enter valid emails, emails are ' + lstWrongEmails.toString());
    //   return;
    // }

    let entity = Object.assign({}, JSON.parse(JSON.stringify(schedule)));
    entity.repeatTilldate = (this.schedule.repeat) ? this.datepipe.transform(new Date(this.repeatedDate), 'yyyy-MM-dd') : '';

    await super.FetchData('Post', `${this.apiMethod.checkMeetingAvailable}`, entity, 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        //  if (this.result.status === 'EXPECTATION_FAILED') {
        //    this.toastr.warning(this.result.data.status);
        //   }
        if (this.result.status === 'OK') {
          this.toastr.success(this.result.message);
          this.isExistPrtspMtng = true;
        }
        this.loading = false;
      });
  }

  async getAttachments(meetingId: any = '') {
    this.meetingId = meetingId ? meetingId : this.meetingId;
    this.attachmentList = [];
    let attFileList = []; let attdoc = [];
    this.loading = true;
    let payload = JSON.parse(JSON.stringify(this.attachmentSearchForm.getRawValue()));
    payload.toDate = this.datepipe.transform(payload.toDate, 'yyyy-MM-dd');
    payload.fromDate = this.datepipe.transform(payload.fromDate, 'yyyy-MM-dd');
    await super.FetchData('Post', `${this.apiMethod.getJoinUrlAndPresentationFileName}/${this.meetingId}`, payload, 3)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          attFileList = this.result.data;
          attFileList.forEach(m => { if (m.presentationfilename) attdoc.push(...m.presentationfilename.split(',')) });
          attdoc.forEach(m => {
            let type = m.split('.')[1]; this.attachmentList.push({
              'attachFileUrl': `${this.url}${this.apiMethod.getPresentationDocuments}/${m}`,
              'attachType': type,
              'fileName': m.split('/')[m.split('/').length - 1].split('.')[0].split(/_(.+)/)[1],
              'image': ['doc', 'docx'].includes(type) ? 'word.png' : type == 'pdf' ? 'pdficon.png' : ['xlsx', 'xls', 'xlsm', 'xlsb', 'xlt'].includes(type) ? 'excel.png' : ['jpg', 'jpeg', 'png', 'icon'].includes(type) ? 'image.png' : ['ppt', 'pptx', 'potx', 'ppsx', 'pot', 'pps', 'thmx'].includes(type) ? 'powerpoint.png' : 'txt.png'
            })
          });
        }
        this.loading = false;
      });
  }

  async getVideoList(meetingId: any = '') {
    this.meetingId = meetingId ? meetingId : this.meetingId;
    this.attachVideoList = []; let attFileList = []; let url = [];
    this.loading = true;
    let payload = JSON.parse(JSON.stringify(this.videoSearchForm.getRawValue()));
    payload.toDate = this.datepipe.transform(payload.toDate, 'yyyy-MM-dd');
    payload.fromDate = this.datepipe.transform(payload.fromDate, 'yyyy-MM-dd');
    jQuery('.dataTable').DataTable().destroy();
    jQuery('.dataTable').DataTable({ searching: false });
    await super.FetchData('Post', `${this.apiMethod.getJoinUrlAndPresentationFileName}/${this.meetingId}`, payload, 3)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          attFileList = this.result.data;
          attFileList.forEach(m => { if (m.url) url.push(...m.url.split(',')) });
          // url.forEach(m=>this.attachVideoList.push({'url':this.transformUrl(m.replace('watch?v=','embed/'))}));
          url.forEach(m => this.attachVideoList.push({ 'url': m }));
          this.dtTriggerAttachVideoLink.next();
          this.dtTrigger.next();
        }
        this.loading = false;
      });
  }

  async getMeetingUser(meetingId: any) {
    this.lstUserOnMeeting = [];
    this.loading = true;
    await super.FetchData('Get', `${this.apiMethod.getjoinUsersbymeetingid}/${meetingId}`, {}, 3)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          this.lstUserOnMeeting = this.result.data;
        }
        this.loading = false;
      });
  }

  downloadImage(img) {
    const imgUrl = img;
    const imgName = imgUrl.substr(imgUrl.lastIndexOf('/') + 1);
    this.http.get(imgUrl, { responseType: 'blob' as 'json' })
      .subscribe((res: any) => {
        const file = new Blob([res], { type: res.type });

        // IE
        if (window.navigator && window.navigator['msSaveOrOpenBlob']) {
          window.navigator['msSaveOrOpenBlob'](file);
          return;
        }

        const blob = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = blob;
        link.download = imgName;

        // Version link.click() to work at firefox
        link.dispatchEvent(new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        }));

        setTimeout(() => { // firefox
          window.URL.revokeObjectURL(blob);
          link.remove();
        }, 100);
      });
  }

  transformUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  validateCollaps() {
    $(".collapse.show").each(function () {
      $(this).prev(".card-header").find(".fa").addClass("fa-chevron-down").removeClass("fa-chevron-right");
    });

    $(".collapse").on('shown.bs.collapse', function () {
      $(this).prev(".card-header").find(".fa").removeClass("fa fa-chevron-right").addClass("fa fa-chevron-down");
    }).on('hidden.bs.collapse', function () {
      $(this).prev(".card-header").find(".fa").removeClass("fa fa-chevron-down").addClass("fa fa-chevron-right");
    });
  }

  closeRecordingModal() {
    this.meetingId = '';
  }

  videoSearchInit() {
    this.videoSearchForm = this.formBuilder.group({
      'userName': '',
      'toDate': [new Date(), [Validators.required]],
      'fromDate': [new Date(), [Validators.required]],
    });
  }

  attachSearchInit() {
    this.attachmentSearchForm = this.formBuilder.group({
      'userName': '',
      'toDate': [new Date(), [Validators.required]],
      'fromDate': [new Date(), [Validators.required]],
    });
  }
  anotationSearchInit() {
    this.anotationSearchForm = this.formBuilder.group({
      'userName': '',
      'toDate': [new Date(), [Validators.required]],
      'fromDate': [new Date(), [Validators.required]],
    });
  }

  async getAnotationList(meetingId: any = '') {
    this.meetingId = meetingId ? meetingId : this.meetingId;
    this.anotationList = []; let annotationFilelist = [];
    this.loading = true;
    let payload = JSON.parse(JSON.stringify(this.anotationSearchForm.getRawValue()));
    payload.toDate = this.datepipe.transform(payload.toDate, 'yyyy-MM-dd');
    payload.fromDate = this.datepipe.transform(payload.fromDate, 'yyyy-MM-dd');
    jQuery('.dataTable').DataTable().destroy();
    jQuery('.dataTable').DataTable({ searching: false });
    await super.FetchData('Post', `${this.apiMethod.getJoinAnnotatedFile}/${this.meetingId}`, payload, 3)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          let annotations = this.result.data;
          annotations.forEach(m => { if (m.annotatedfilename) annotationFilelist.push(...m.annotatedfilename.split(',')) });
          annotationFilelist.forEach(m => this.anotationList.push({ 'fileName': m }));
          // this.anotationList = annotations.length>0?annotations[0].annotatedfilename.split(','):[];
          this.dtTriggerAnotation.next();
          this.dtTrigger.next();
        }
        this.loading = false;
      });
  }

  viewAnnotationFile(fileName: string) {
    https://dev.dhanushsamvaad.com/conference/conclave/jwt/general/getFile/AnnotatedDocuments/filenamehere
    window.open(`${this.url}${this.apiMethod.getAnnotatedDocuments}/${fileName}`);
    //  this.loading=true;
    //     super.FetchData('Get', `general/getFile/AnnotatedDocuments/${fileName}`, {},4)
    //         .catch(() => { this.httpSvc.handleError, this.loading = false; })
    //         .then(() => {
    //           if (this.result != []) {
    // window.open(this.result);
    //           }
    //           this.loading = false;
    //         });
  }

  validateReminder(event: any) {
    if (event.checked) {
      let zone_name = this.timeZoneList.find(m => m.id == this.schedule.zoneId).utctimeZoneName;
      // if (this.datepipe.transform(new Date(this.scDate), 'yyyy-MM-dd') == this.datepipe.transform(new Date(), 'yyyy-MM-dd')) {
      //   if (`${this.time}:${this.minute}:00` < this.datepipe.transform(new Date(), 'HH:mm:ss')) {
      //     this.toastr.error('Please enter future time');
      //     event.checked = false;
      //     return;
      //   }
      // }navigator.language
      if (this.datepipe.transform(new Date(this.scDate), 'yyyy-MM-dd') == this.datepipe.transform(new Date().toLocaleString('en-US', { timeZone: zone_name, hour12: false }), 'yyyy-MM-dd')) {
        if (`${this.time}:${this.minute}:00` < this.datepipe.transform(new Date().toLocaleString('en-US', { timeZone: zone_name, hour12: false }), 'HH:mm:00')) {
          this.toastr.error(`Please enter future time for ${zone_name}`);
          event.checked = false;
          return;
        }
      }
      this.loadReminderInterval();
    } else this.lstReminder = [];
  }

  async loadReminderInterval() {
    this.lstReminder = []; this.loading = false;
    // let zoneName = this.timeZoneList.find(m => m.id == this.schedule.zoneId).utctimeZoneName;
    if (this.time) {
      //const new_url = `${this.apiMethod.getReminderInterval}${this.datepipe.transform(new Date(this.scDate), 'yyyy-MM-dd')} ${this.time}:${this.minute}:00?${zoneName}`;
      await super
        .FetchData("Post", this.apiMethod.getReminderIntervalNew, {
          "date": `${this.datepipe.transform(new Date(this.scDate), 'yyyy-MM-dd')} ${this.time}:${this.minute}:00`,
          "zonename": this.timeZoneList.find(m => m.id == this.schedule.zoneId).utctimeZoneName
        })
        .catch(() => {
          this.httpSvc.handleError, (this.loading = false);
        })
        .then(() => {
          if (this.result != []) {
            this.lstReminder = this.result.data;
          }
          this.loading = false;
        });
    }
  }

  deleteExpiredMeeting(event: any, mode: string, session: string) {
    if (mode == 'all')
      if (event.checked) this.results.filter(m => m.ischk == false).forEach((e) => { e.delete = true; if (!this.deleteRecordList.includes(e.session)) this.deleteRecordList.push(e.session) });
      else this.deleteRecordList = [], this.results.forEach(e => e.delete = false);
    else {
      // if (event.checked && !this.deleteRecordList.includes(session)) this.deleteRecordList.push(session);
      if (!this.deleteRecordList.includes(session)) this.deleteRecordList.push(session);
      else if (this.deleteRecordList.includes(session)) this.deleteRecordList.splice(this.deleteRecordList.indexOf(session), 1);
      $('#chk_DeleteAllMeeting').prop('checked', !(this.results.filter(m => m.ischk == false).length > this.deleteRecordList.length));
    }
  }

  async deleteRecord() {
    // if (!window.confirm('Are you sure you want to delete the meetings?'))
    //   return;
    let payload = { "meetingIds": this.deleteRecordList };
    this.loading = true;
    await super.FetchData('Post', `${this.apiMethod.deleteMeetings}`, payload, 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          if (this.result.status == 'OK') {
            this.toastr.success(this.result.message);
            this.deleteRecordList = [];
            this.onSearch(true);
            this.todaySearch();
            this.todaySearch("Calender", this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'));
          }
        }
        this.loading = false;
      });
  }

  async getDomain() {
    this.loading = true, this.lstDomain = [];
    await super.FetchData("Get", `${this.apiMethod.getDomainById}/${this.createrById}/true`, {}, 1)
      .catch(() => {
        this.httpSvc.handleError, (this.loading = false);
      })
      .then(() => {
        if (this.result != []) {
          this.lstDomain = this.result.data;
        }
        this.loading = false;
      });
  }

  onEmailRequiredFor() {
    this.schedule.participantEmails = '';
    this.selectMail = [];
    if (this.emailRequiredFor) {
      this.schedule.domainId = 0;
      if (!this.checkEmailRequired()) {
        this.scheduleForm.controls['emailRequired'].enable();
        this.scheduleForm.controls['emailRequiredWithoutValidation'].enable();
      }
      this.schedule.emailRequired = this.checkEmailRequired();
      // this.schedule.emailRequiredWithoutValidation = !this.checkEmailRequired();
    }
    else {
      this.scheduleForm.controls['emailRequired'].disable();
      this.scheduleForm.controls['emailRequiredWithoutValidation'].disable();
      this.schedule.emailRequired = true;
      this.schedule.emailRequiredWithoutValidation = false;
      this.getDomain();
    }

  }

  onChangeDomain(id: number) {
    this.schedule.participantEmails = '';
    this.schedule.participantEmails = this.lstDomain.find(m => m.id == id).mailids.split(',').join(';');
  }

  checkDuplicate() {
    let selectedEmails = this.schedule.participantEmails.trim().length > 0 ? this.schedule.participantEmails.trim().split(';') : [];
    let uniqEmailList = [...new Set(selectedEmails)];
    this.schedule.participantEmails = uniqEmailList.join(';');
  }

  checkEmailRequired(): boolean {
    let flag = false;
    if (this.schedule.breakOutRoom || this.lockTimeRequired || !this.emailRequiredFor)
      flag = true;
    // if (mode == 'Participant') {
    //   if (this.schedule.breakOutRoom || this.lockTimeRequired)
    //     flag = true;
    // }
    return flag;
  }

  async getTimeZone() {
    this.loading = true, this.timeZoneList = [];
    await super.FetchData("Get", `${this.apiMethod.getTimeZone}`, {})
      .catch(() => {
        this.httpSvc.handleError, (this.loading = false);
      })
      .then(() => {
        if (this.result != []) {
          this.timeZoneList = this.result.data;
        }
        this.loading = false;
      });
  }

  convertTimezone(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
  }

  getDuration(startDate: any, endDate: any): string {
    if (endDate) {
      let time = '';
      const startDateTime: any = new Date(startDate);
      const endDateTime: any = new Date(endDate);
      //  const days = parseInt(((endDateTime - startDateTime) / (1000 * 60 * 60 * 24)).toString());
      const hours = parseInt((Math.abs(endDateTime - startDateTime) / (1000 * 60 * 60) % 24).toString());
      const minutes = parseInt((Math.abs(endDateTime.getTime() - startDateTime.getTime()) / (1000 * 60) % 60).toString());
      const seconds = parseInt((Math.abs(endDateTime.getTime() - startDateTime.getTime()) / (1000) % 60).toString());
      time = hours > 0 ? `${hours} Hr ${minutes} Min ${seconds} Sec` : minutes > 0 ? `${minutes} Min ${seconds} Sec` : `${seconds} Sec`;
      //return `${hours} Hr ${minutes} Min ${seconds} Sec`;
      return time;
    } else {
      return '';
    }
  }

  isDisplayFeatures(type: string): boolean {
    let accessFeatures: Array<string> = [];
    accessFeatures = JSON.parse(sessionStorage.loginData).packpermissions;
    if (accessFeatures && accessFeatures.length > 0 && accessFeatures.includes(type))
      return true;
    else return false;
  }

  emailRequired(event: any, mode: string) {
    if (event.checked)
      if (mode === "withOutValidation") {
        this.schedule.emailRequiredWithoutValidation = true;
        this.schedule.emailRequired = false;
      } else {
        this.schedule.emailRequiredWithoutValidation = false;
        this.schedule.emailRequired = true;
      }
  }

  toogleScreens(status: boolean) {
    if (this.joinLink.trim().length == 0) {
      return this.toastr.warning('Please enter Joining Link');
    } else {
      if (this.joinLink.split('aar/')[1]) {
        window.open(this.joinLink, "_blank");
        this.joinLink = '';
        document.getElementById('joinClose').click();
      }
      else
        this.toastr.error("Invalid meeting link");
    }
  }

  onPageChange(event): void {
    this.page = event;
    this.onSearch();
  }

  keyDown(event: any, type: string) {
    if (event.keyCode == 13 && type == "Join") this.toogleScreens(false);
    else if (event.keyCode == 13 && type == "Search") this.onSearch();
  }


  loadScript(FILE_URL, async = true, type = "text/javascript") {
    return new Promise((resolve, reject) => {
      try {
        const scriptEle = document.createElement("script");
        scriptEle.type = type;
        scriptEle.async = async;
        scriptEle.src = FILE_URL;

        scriptEle.addEventListener("load", (ev) => {
          resolve({ status: true });
        });

        scriptEle.addEventListener("error", (ev) => {
          reject({
            status: false,
            message: `Failed to load the script ＄{FILE_URL}`
          });
        });

        document.body.appendChild(scriptEle);
      } catch (error) {
        reject(error);
      }
    });
  }

  async todaySearch(recordsType: string = "today", NextOrPrevious_Month: string = "") {
    this.serchText = '';
    if (recordsType == "today")
      this.todayMeetingCount = 0;
    this.dtOption = {}, this.results = [];
    let userId = ((this.role == 1 || this.role == 3) && this.selectUserId == 0) ? this.creatorId : (this.role == 1 || this.role == 3) ? this.selectUserId : this.creatorId;
    let zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const date = NextOrPrevious_Month ? new Date(NextOrPrevious_Month) : new Date();
    const from_date = recordsType == "today" ? new Date() : new Date(date.getFullYear(), date.getMonth(), 1);
    const to_date = recordsType == "today" ? new Date() : new Date(date.getFullYear(), date.getMonth() + 1, 0);

    let payload = `{"creatorId":${this.creatorId},"role":${this.role},"deleted":${this.deleted},"requesterID":${userId},"fromDate":"${this.datepipe.transform(from_date, 'yyyy-MM-dd')}","toDate":"${this.datepipe.transform(to_date, 'yyyy-MM-dd')}","zoneName":"${zoneName}"}`;

    const searchText = this.meetingSearch.trim();

    this.http.post<DataTablesResponse>(
      `${this.url}${this.apiMethod.jwtgetMeetingList}`,
      Object.assign({ draw: 1, start: 0, length: 1000, search: { value: searchText.length == 0 ? "" : searchText, regex: false } }, { payload: JSON.parse(payload) }), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': JSON.parse(sessionStorage.getItem('jwtToken')),
      })
    }).
      subscribe(resp => {
        if (resp != undefined && resp != null) {
          let result: any = resp.data;
          if (result.data.length > 0 && recordsType == "today") {
            this.todayMeetingCount = result.recordsFiltered;
          }
          else if (result.data.length > 0 && recordsType == "Calender") {
            const meetingDates = [];
            result.data.forEach((r: any) => { meetingDates.push(new Date(r.date).getDate()); });

            document.querySelectorAll("td.day").forEach(e => {
              if (["day", "active day", "today day"].includes(e.className)) {
                const el = (e as HTMLTableElement);
                const child = el.firstElementChild;
                if (child)
                  e.removeChild(child);

                if (meetingDates.includes(+el.innerText)) {
                  //el.style.border = "1px solid red";
                  const tag = document.createElement("div");
                  tag.style.height = "8px";
                  tag.style.width = "8px";
                  tag.style.borderRadius = "50%";
                  tag.style.background = "#4ce30d";
                  tag.style.display = "inline-block";
                  tag.style.transform = "translate(-9px, 12px)";
                  el.appendChild(tag);
                  // console.log("if", el.innerText);
                }
                else {
                  // el.style.border = "";
                  // console.log("else", el.innerHTML);

                  // if (child) {
                  //   e.removeChild(child);
                  // }
                }
              }
            });
          }
        }
        else
          this.toastr.error(`An error occurred while processing your request`);
      },
        error => {
          if (this.result.status == 401)
            setTimeout(() => { console.clear(), console.log(this.result.message); }, 500), this.route.navigateByUrl(`/`), this.toastr.warning('Session Expired, kindly re-login');
        }
      );
  }

  nextDate(dayType: string = "<"): void {
    let from_Date = new Date(this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'));

    let new_date = (dayType == "<") ? from_Date.setDate(from_Date.getDate() - 1) : from_Date.setDate(from_Date.getDate() + 1);

    this.fromDate = new Date(this.datepipe.transform(new_date, 'yyyy-MM-dd'));
    this.onFromDateChange(this.fromDate);
    setTimeout(() => this.onSearch(true), 10);
  }

  onDateChanged(): boolean {
    const val = (document.getElementById("btnMonth") as HTMLInputElement).value;
    // alert(val);
    this.todaySearch("Calender", (val.trim().length == 0) ? this.datepipe.transform(this.fromDate, 'yyyy-MM-dd') : val);
    if (val.trim().length == 0) {
      setTimeout(() => this.onSearch(true), 10);
      return false;
    }
    this.fromDate = new Date(this.datepipe.transform(new Date(val), 'yyyy-MM-dd'));
    this.toDate = new Date(this.datepipe.transform(new Date(val), 'yyyy-MM-dd'));
    setTimeout(() => this.onSearch(true), 10);
  }

  loadJQuery() {
    $('#calendar').datepicker().on('changeDate', function (ev) {
      console.log(ev);

      const val: string = $("#calendar").data('datepicker').getFormattedDate('yyyy-mm-dd');
      // alert(val);  
      const el = (document.getElementById("btnMonth") as HTMLInputElement);
      el.value = val;
      el.click();
    });

    $('#calendar').datepicker().on('changeMonth', (ev) => {
      console.log(ev);
      // alert(ev.date);

      // const selectedMonth = new Date(ev.date).getMonth();
      const selectedDate = new Date(ev.date);
      const today_date = new Date();
      // const [year, month, date] = [today_date.getFullYear(), selectedMonth, today_date.getDate()];
      const [year, month, date] = [selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate()];
      const new_date = new Date(year, month, date);
      //console.log(new_date);
      this.datepipe.transform(new_date, 'yyyy-MM-dd');
      this.onCalendarChanged(this.datepipe.transform(new_date, 'yyyy-MM-dd'));
    });
    $('#calendar').datepicker().on('changeYear', (ev) => {
      console.log(ev);
    });
  }

  onCalendarChanged(val: string) {
    const el = (document.getElementById("btnMonth") as HTMLInputElement);
    el.value = val;
    el.click();
    // alert(val)
  }

}

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

