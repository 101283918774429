import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gridviewandspeakerview',
  templateUrl: './gridviewandspeakerview.component.html',
  styleUrls: ['./gridviewandspeakerview.component.css']
})
export class GridviewandspeakerviewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
