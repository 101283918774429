import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recording-list',
  templateUrl: './recording-list.component.html',
  styleUrls: ['./recording-list.component.css']
})
export class RecordingListComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
