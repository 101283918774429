import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../BaseComponent';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/Services/http.service';
import { EntityService } from 'src/app/Services/entity.service';
import { PaginationService } from 'src/app/Services/pagination.service';

@Component({
  selector: 'app-dummy-header',
  templateUrl: './dummy-header.component.html',
  styleUrls: ['./dummy-header.component.css']
})
export class DummyHeaderComponent extends BaseComponent implements OnInit {

  constructor(
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected entitysvc: EntityService,
    protected pageSvc: PaginationService

  ) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
  }

  ngOnInit() {
  }
  async logOut() {
    if (!window.location.href.includes('subuser'))
      await super.LogOut();
  }
}
