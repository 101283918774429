import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { ToastrModule } from 'ngx-toastr';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
//import { AngularFontAwesomeModule } from 'angular-font-awesome';

import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { BsDatepickerModule, DatepickerModule, } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { InputTrimModule } from 'ng2-trim-directive';
import { SortableModule } from 'ngx-bootstrap/sortable';
//import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './Components/header/header.component';
import { FooterComponent } from './Components/footer/footer.component';
import { ForgotpwdComponent } from './Components/forgotpwd/forgotpwd.component';
import { HomeComponent } from './Components/home/home.component';
import { LoginComponent } from './Components/login/login.component';
import { ScheduleComponent } from './Components/schedule/schedule.component';
import { UserComponent } from './Components/user/user.component';
import { UserlistComponent } from './Components/userlist/userlist.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseComponent } from './Components/BaseComponent';
import { ChangePasswordComponent } from './Components/change-password/change-password.component';
import { SubscribeUserComponent } from './Components/subscribe-user/subscribe-user.component';
import { SubscribeListComponent } from './Components/subscribe-list/subscribe-list.component';
import { DummyHeaderComponent } from './Components/dummy-header/dummy-header.component';
import { PaymentHistoryComponent } from './Components/payment-history/payment-history.component';
import { ProfileComponent } from './Components/profile/profile.component';
import { VideoRoomComponent } from './Components/video-room/video-room.component';
import { ErrorMessageComponent } from './Components/error-message/error-message.component';
import { RecordingListComponent } from './Components/recording-list/recording-list.component';
import { ServerRoomsComponent } from './Components/server-rooms/server-rooms.component';
import { DashboardComponent } from './Components/dashboard/dashboard.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { JoinLoadingMessageComponent } from './Components/join-loading-message/join-loading-message.component';
import { OwndomainComponent } from './Components/owndomain/owndomain.component';
import { ApproveDomainComponent } from './Components/approve-domain/approve-domain.component';
import { StreamSettingsComponent } from './Components/stream-settings/stream-settings.component';
import { UploadUserManualComponent } from './Components/upload-user-manual/upload-user-manual.component';
import { UploadParticipantsDetailsExcelComponent } from './Components/upload-participants-details-excel/upload-participants-details-excel.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RolesComponent } from './Components/roles/roles.component';
import { RolePermissionMappingComponent } from './Components/role-permission-mapping/role-permission-mapping.component';
import { PermissionsComponent } from './Components/permissions/permissions.component';
import { ProHeaderComponent } from './Components/pro-header/pro-header.component';
import { JoinMeetingComponent } from './Components/join-meeting/join-meeting.component';
import { MenuComponent } from './Components/menu/menu.component';
import { LandingComponent } from './Components/landing/landing.component';
import { PaginationComponent } from './Components/pagination/pagination.component';
import { SMShistoryComponent } from './Components/smsinfo/smshistory.component';
import { UserdashboardComponent } from './Components/userdashboard/userdashboard.component';
import { DashboardsummaryComponent } from './Components/dashboardsummary/dashboardsummary.component';
import { LmsResourcesComponent } from './Components/resource/lms-resources/lms-resources.component';
import { SamvaadResourcesComponent } from './Components/resource/samvaad-resources/samvaad-resources.component';
import { ContactUsComponent } from './Components/contact-us/contact-us.component';
import { RequestADemoComponent } from './Components/request-ademo/request-ademo.component';
import { RequestDemoListComponent } from './Components/request-demo-list/request-demo-list.component';
import { FeaturesComponent } from './Components/features/features.component';
import { JoinsessionComponent } from './Components/features/joinsession/joinsession.component';
import { LivestreamComponent } from './Components/features/livestream/livestream.component';
import { SharevideoComponent } from './Components/features/sharevideo/sharevideo.component';
import { SelectbackgroundComponent } from './Components/features/selectbackground/selectbackground.component';
import { MessageComponent } from './Components/features/message/message.component';
import { PollsComponent } from './Components/features/polls/polls.component';
import { SharescreenComponent } from './Components/features/sharescreen/sharescreen.component';
import { GridviewandspeakerviewComponent } from './Components/features/gridviewandspeakerview/gridviewandspeakerview.component';
import { PinviewComponent } from './Components/features/pinview/pinview.component';
import { SpotlightviewComponent } from './Components/features/spotlightview/spotlightview.component';
import { BreakoutroomsComponent } from './Components/features/breakoutrooms/breakoutrooms.component';
import { WhiteboardComponent } from './Components/features/whiteboard/whiteboard.component';
import { LogoutendmeetingComponent } from './Components/features/logoutendmeeting/logoutendmeeting.component';
import { SamvaadProContentComponent } from './Components/samvaad-pro-content/samvaad-pro-content.component';
import { NewUserSubscriptionComponent } from './Components/features/new-user-subscription/new-user-subscription.component';
import { FaqComponent } from './Components/faq/faq.component';
import { ReleaseNoteComponent } from './Components/release-note/release-note.component';
import { RaisehandComponent } from './Components/features/raisehand/raisehand.component';
import { EnablelobbyComponent } from './Components/features/enablelobby/enablelobby.component';
import { PasswordprotectionComponent } from './Components/features/passwordprotection/passwordprotection.component';
import { ConnectivitystatusComponent } from './Components/features/connectivitystatus/connectivitystatus.component';
import { DevicesComponent } from './Components/features/devices/devices.component';
import { RecordingComponent } from './Components/features/recording/recording.component';
import { ShowOnlyVideoComponent } from './Components/features/show-only-video/show-only-video.component';
import { SelfViewComponent } from './Components/features/self-view/self-view.component';
import { PollListComponent } from './Components/poll-list/poll-list.component';
import { IndexComponent } from './Components/index/index.component';
import { TrashbinComponent } from './Components/trashbin/trashbin.component';
import { DomainComponent } from './Components/domain/domain.component';
import { ChatListComponent } from './Components/chat-list/chat-list.component';
import { BreakoutRoomComponent } from './Components/breakout-room/breakout-room.component';
import { MobileLunchComponent } from './Components/mobile-lunch/mobile-lunch.component';
import { SocialLoginComponent } from './Components/login/social-login/social-login.component';
import { NewPlansAndPricingComponent } from './Components/new-plans-and-pricing/new-plans-and-pricing.component';
import { SubscriptionFeaturesComponent } from './Components/subscriptions/subscription-features/subscription-features.component';
import { SubscriptionPlansComponent } from './Components/subscriptions/subscription-plans/subscription-plans.component';
import { PlanFeaturesMappingComponent } from './Components/subscriptions/plan-features-mapping/plan-features-mapping.component';
import { PlanServicesComponent } from './Components/plan-services/plan-services.component';
import { InstantMeetingComponent } from './Components/schedule/instant-meeting/instant-meeting.component';
import { NewHeaderComponent } from './Components/new-header/new-header.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { LauchDeviceComponent } from './Components/lauch-device/lauch-device.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ForgotpwdComponent,
    HomeComponent,
    LoginComponent,
    ScheduleComponent,
    UserComponent,
    UserlistComponent,
    BaseComponent,
    ChangePasswordComponent,
    SubscribeUserComponent,
    SubscribeListComponent,
    DummyHeaderComponent,
    PaymentHistoryComponent,
    ProfileComponent,
    VideoRoomComponent,
    ErrorMessageComponent,
    RecordingListComponent,
    ServerRoomsComponent,
    DashboardComponent,
    JoinLoadingMessageComponent,
    OwndomainComponent,
    ApproveDomainComponent,
    StreamSettingsComponent,
    UploadUserManualComponent,
    UploadParticipantsDetailsExcelComponent,
    RolesComponent,
    RolePermissionMappingComponent,
    PermissionsComponent,
    ProHeaderComponent,
    JoinMeetingComponent,
    MenuComponent,
    LandingComponent,
    PaginationComponent,
    SMShistoryComponent,
    UserdashboardComponent,
    DashboardsummaryComponent,
    LmsResourcesComponent,
    SamvaadResourcesComponent,
    ContactUsComponent,
    RequestADemoComponent,
    RequestDemoListComponent,
    UserlistComponent,
    FeaturesComponent,
    JoinsessionComponent,
    LivestreamComponent,
    SharevideoComponent,
    SelectbackgroundComponent,
    MessageComponent,
    PollsComponent,
    SharescreenComponent,
    GridviewandspeakerviewComponent,
    PinviewComponent,
    SpotlightviewComponent,
    BreakoutroomsComponent,
    WhiteboardComponent,
    LogoutendmeetingComponent,
    SamvaadProContentComponent,
    NewUserSubscriptionComponent,
    FaqComponent,
    ReleaseNoteComponent,
    RaisehandComponent,
    EnablelobbyComponent,
    PasswordprotectionComponent,
    ConnectivitystatusComponent,
    DevicesComponent,
    RecordingComponent,
    ShowOnlyVideoComponent,
    SelfViewComponent,
    PollListComponent,
    IndexComponent,
    TrashbinComponent,
    DomainComponent,
    ChatListComponent,
    BreakoutRoomComponent,
    MobileLunchComponent,
    SocialLoginComponent,
    NewPlansAndPricingComponent,
    SubscriptionFeaturesComponent,
    SubscriptionPlansComponent,
    PlanFeaturesMappingComponent,
    PlanServicesComponent,
    InstantMeetingComponent,
    NewHeaderComponent,
    LauchDeviceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    BrowserAnimationsModule,
    DataTablesModule,
    ReactiveFormsModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    // DatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    NgxSpinnerModule,
    NgMultiSelectDropDownModule.forRoot(),
    //AngularFontAwesomeModule,
    // DlDateTimeDateModule,
    // DlDateTimePickerModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      backdropBorderRadius: '4px',
      primaryColour: '#233d59',
      secondaryColour: '#233d59',
      tertiaryColour: '#233d59',
      fullScreenBackdrop: true
    }),
    InputTrimModule,
    SortableModule.forRoot(),
    //  SelectDropDownModule,
    NgSelectModule,
    NgxPaginationModule
  ],
  providers: [DeviceDetectorService, DatePipe],
  bootstrap: [AppComponent]
  //{ provide: LocationStrategy, useClass: HashLocationStrategy }
})
export class AppModule { }

