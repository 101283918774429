import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-lauch-device',
  templateUrl: './lauch-device.component.html',
  styleUrls: ['./lauch-device.component.css']
})
export class LauchDeviceComponent implements OnInit {
  isMobleBrowser: boolean = false;
  @Output() buttonChanged: EventEmitter<number> = new EventEmitter();
  constructor() { }

  ngOnInit() {
    this.isMobleBrowser = window.orientation > -1;
    document.getElementById("btnLaunchModel").click();
  }

  closeModel(id: number) {
    this.buttonChanged.emit(id);
  }

}
