import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EntityService {

  constructor(private httpservice: HttpService) { }

  GetData(apiMethod: string, jwtToken = '', svcTagIndx = 0, isLcl = false) {
    return this.httpservice.GetAPI({ apiMethod: apiMethod, jwtToken: jwtToken, svcTagIndx: svcTagIndx, isLcl: isLcl });
  }

  PostData(apiMethod: string, payLoad: any, jwtToken = '', svcTagIndx = 0, isLcl = false) {
    return this.httpservice.PostAPI({ apiMethod: apiMethod, payLoad: payLoad, jwtToken: jwtToken, svcTagIndx: svcTagIndx, isLcl: isLcl }).toPromise();
  }

  PostData1(apiMethod: string, payLoad: any, jwtToken = '', svcTagIndx = 0, isLcl = false) {
    return this.httpservice.PostAPI({ apiMethod: apiMethod, payLoad: payLoad, jwtToken: jwtToken, svcTagIndx: svcTagIndx, isLcl: isLcl });
  }

  GetData1(apiMethod: string, jwtToken = '', svcTagIndx = 0, isLcl = false) {
    return this.httpservice.GetAPI1({ apiMethod: apiMethod, jwtToken: jwtToken, svcTagIndx: svcTagIndx, isLcl: isLcl });
  }

  post(apiMethod: string, data: any): Observable<any> { return this.httpservice.postTest(`${apiMethod}`, data); }
}
