import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import {
  FormGroup,
  Validators,
  FormControl,
  FormBuilder,
} from "@angular/forms";
import { Router } from "@angular/router";
import { HttpService } from "src/app/Services/http.service";
import { EntityService } from "src/app/Services/entity.service";
import { BaseComponent } from "src/app/Components/BaseComponent";
import { Subject } from "rxjs";
import { DatePipe } from "@angular/common";
import { TimepickerConfig } from "ngx-bootstrap/timepicker";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PaginationService } from 'src/app/Services/pagination.service';

@Component({
  selector: "app-approve-domain",
  templateUrl: "./approve-domain.component.html",
  styleUrls: ["./approve-domain.component.css"],
  providers: [DatePipe],
})
export class ApproveDomainComponent extends BaseComponent implements OnInit {
  lstApprovalList = [];
  expiryDateTime = "";

  dtTrigger: Subject<any> = new Subject();
  constructor(
    private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected entitysvc: EntityService,
    private datepipe: DatePipe,
    private http: HttpClient,
    protected pageSvc: PaginationService,
  ) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
  }

  ngOnInit() {
    this.getApprovalList();
    this.expiryDateTime =
      JSON.parse(sessionStorage.getItem("loginData")).expiryDateTime == null
        ? ""
        : JSON.parse(sessionStorage.getItem("loginData")).expiryDateTime;
  }

  async getApprovalList() {
    this.loading = true;
    this.lstApprovalList = [];
    jQuery(".dataTable").DataTable().destroy();
    jQuery(".dataTable").DataTable({ searching: false });
    await super
      .FetchData("Get", `user/getdomainapprovaldata`, {}, 1)
      .catch(() => {
        this.httpSvc.handleError, (this.loading = false);
      })
      .then(() => {
        if (this.result != []) {
          this.lstApprovalList = this.result.data;
          this.dtTrigger.next();
        }
        this.loading = false;
      });
  }

  async approveRejectRecord(status: string, item: any) {
    // if(status=='Reject')
    // {
    //   item.
    // }
    let aprovedRecord: any = {};
    aprovedRecord = item;
    aprovedRecord.domainapproval = status;
    await super
      .FetchData('Post', `user/updatedomainstatus`, aprovedRecord, 1)
      .catch(() => {
        this.httpSvc.handleError, (this.loading = false);
      })
      .then(() => {
        if (this.result != []) {
          this.toastr.success(this.result.message);
          this.getApprovalList();
        }
        this.loading = false;
      });
  }
}
