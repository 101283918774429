export class User {
    id: number = 0;
    name: string = '';
    password: string = '';
    email: string = '';
    mobileNO: string = '';
    active: boolean = true;
    address: string = '';
    recordByDefault: boolean = false;
    liveStreamByDefault: boolean = false;
    landLineNo: string = '';
    extn: string = '';
    packType = "0";
    numberOfParticipants: '';
    numberOfHosts: '';
    amount: '';
    expiryDateTime: string = '';
    createdDate: string = '';
    archiveDuration: string = '';
    role: {
        id: number;
        name: string;
    };
    createdBy: {
        id: number;
    };
    noOfHosts: string = '';
    constructor() {
        this.role = {
            id: 0,
            name: ''
        },
            this.createdBy = {
                id: 0
            }
    }
}

export class Schedule {
    id: number = 0;
    name: string = '';
    description: string = '';
    date: string = '';
    startTime: string = '';
    endTime: string = '';
    session: string = '';
    creator: {
        id: number;
    };
    startDateTime: string = '';
    endDateTime: string = '';
    attendeePW: string = '';
    guest: string = '';
    duration: string = '';
    passwordrequired: string = '';
    record: boolean = false;
    maxParticipants: string = '';
    participantEmails: string = '';
    hidePresentation: string = '';
    repeat: boolean = false;
    repeatTilldate: string = '';
    recurrence: string = '';
    requiredWebinarLink: boolean = false;
    reminder: boolean = false;
    intervalId: number = 0;
    emailRequired: boolean = false;
    breakOutRoom: boolean = false;
    lockPeriod: boolean = false;
    enabledParticipantAudioControl: boolean = false;
    lockTime: number = 0;
    domainId: number = 0;
    zoneName: string = '';
    zoneId: number = 0;
    saveRecordingsLocally: boolean = false;
    emailRequiredWithoutValidation: boolean = false;
    meetingModerators: string='';
    constructor() {
        this.creator = {
            id: 0
        }
    }
}

export class ChangePassword {
    userID: number = 0;
    oldPassword: string = '';
    updatePwd: string = '';
}

export class Subscribe {
    startDateTime: string = '';
    endDateTime: string = '';
    subscriptionPack: {};
    user: {};
}

export class Recurrence {
    repeat: string = 'daily';
    repeatEveryCount: string = '1';
    end: {
        repeatEnd: string;
        until: any;
        count: string;
    };
    repeatOn: {
        daysOnWeek: any;
        dayOrOther: string;
        dayCount: string;
        otherWeekDays: string;
        otherDays: string;
        monthOfYear: string;
    }
    constructor() {
        this.end = {
            repeatEnd: 'never',
            until: '',
            count: '1'
        };
        this.repeatOn = {
            daysOnWeek: [],
            dayOrOther: 'days',
            dayCount: '1',
            otherWeekDays: 'first',
            otherDays: 'sunday',
            monthOfYear: '01'
        }
    }
}

export class Domain {
    id: number = 0;
    domainname: string = '';
    active: boolean = true;
    createdBy: number = 0;
    emailIds: string = '';
}