import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import customProtocolCheck from "custom-protocol-check";
import { ToastrService } from "ngx-toastr";
import { EntityService } from "src/app/Services/entity.service";
import { HttpService } from "src/app/Services/http.service";
import { PaginationService } from "src/app/Services/pagination.service";
import { BaseComponent } from "../BaseComponent";

declare let launchUri: any;
@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.css"],
})
export class IndexComponent extends BaseComponent implements OnInit {

  meetingId = ''; errorCount = 0; hitCount = 0;
  interval: any;
  constructor(
    private router: Router,
    protected toastr: ToastrService,
    protected httpSvc: HttpService,
    protected pageSvc: PaginationService,
    protected entitysvc: EntityService,
    private activeroute: ActivatedRoute
  ) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
  }

  ngOnInit() {
    this.activeroute.params.subscribe((e) => {
      this.meetingId = e.id;
    })
    if (window.orientation > -1) {
      //this.joinMeeting();
      this.route.navigate(['/mobile', location.pathname.split("/")[location.pathname.split("/").length - 1]]);
    }
  }

  openDesktopApplication() {
    this.errorCount = 0; this.hitCount = 0;
    clearInterval(this.interval);
    const time = (new Date()).getTime();
    const samvaadProtocolString = `samvaad-pro://${location.href}?V=${time}`;
    // const samvaadProtocolString = `samvaad-pro://https://dev.dhanushsamvaad.com/samvaad/aar/u1aFK-1647328078769?V=${(new Date()).getTime()}`;

    customProtocolCheck(samvaadProtocolString,
      () => {
        this.toastr.warning('Samvaad app not found on local machine.');
        //console.log("Custom protocol not found.");
      },
      () => {
        console.log("Custom protocol found and opened the file successfully.");
        this.checkSession(time.toString());
      }, 10000
      // () => { setTimeout(() => window.location.href = this.getHomeUrl(), 500); console.log("Custom protocol found and opened the file successfully."); }, 10000
    );
  }

  openDesktopApplication1() {
    const samvaadProtocolString = `samvaad-pro://${location.href}?V=${(new Date()).getTime()}`;
    // const anchroTag = document.getElementById(
    //   "btnSamvaadSetUp"
    // ) as HTMLAnchorElement;
    // anchroTag.href = samvaadProtocolString;
    // anchroTag.click();

    launchUri(samvaadProtocolString,
      () => {
        window.location.href = this.getHomeUrl();
        console.log('Have fun with my app');
      },
      () => { this.toastr.warning('Please download Samvaad APP'); },
      () => { alert('Hey, did my app launch? If not, please install it. kthxbye'); });
  }

  joinMeeting() {
    this.router.navigate([
      "./videoRoom",
      location.pathname.split("/")[location.pathname.split("/").length - 1],
    ]);
  }

  download() {
    Promise.all([this.getDownloadServerUrl()]).then(() => {
      setTimeout(() => {
        // window.location.href = `${this.recordingServerUrl + "setup/download"}`;
        const index = this.getDeviceInfo();
        if (index > 0)
          window.location.href = `${this.recordingServerUrl}setup/download/${index}`;
      }, 500);
    });
  }

  getDeviceInfo() {
    let OS_TypeId = 0;
    const osInfo = [
      { id: 1, name: "Win", fullName: "Windows OS" },
      { id: 2, name: "Linux", fullName: "Linux OS" },
      { id: 3, name: "Mac", fullName: "Macintosh" },
      { id: 4, name: "Android", fullName: "Android OS" },
      { id: 5, name: "like Mac", fullName: "iOS" },
      // { id: 0, name: "Win", fullName: "Unknown OS" },
    ];

    for (let index = 0; index < osInfo.length; index++) {
      if (navigator.userAgent.indexOf(osInfo[index].name) != -1) {
        OS_TypeId = osInfo[index].id;
        break;
      }
    }

    return OS_TypeId;
  }

  async checkSession(time: string) {
    this.hitCount++;
    await super.FetchData('Get', `session/check/${this.meetingId.split('-')[1]}/${time}`, {}, 3)
      .catch(() => { this.httpSvc.handleError })
      .then(() => {
        if (this.result.data) {
          clearInterval(this.interval);
          window.location.href = this.getHomeUrl();
        }
        else {
          this.errorCount++;
          if (this.hitCount === 50)
            clearInterval(this.interval);
          if (this.errorCount == 1)
            this.interval = setInterval(() => this.checkSession(time), 10000);
        }
      });
  }
}
