
export enum apiMethods {

    //Login
    login = 'login/login',
    forgotPwd = 'login/forgotpwd',
    checkExistEmail = 'login/existcheck/',
    generateOtp = 'login/genearateotp/',
    saveUser = 'login/saveOrUpdate',
    validateOtp = 'login/validateotp/',

    //Meeting
    resendMail = 'meeting/resendMeetingLinks/',
    meetingCancel = 'meeting/cancelMeeting/',
    saveMeeting = 'meeting/saveorupdate',
    // getAllMeetingAdminCreatedByUser = 'meeting/getMeetingListForAdmin/',
    getMeetingByDate = 'meeting/getMeetingList/',
    // getMeeting = 'meeting/fetchall',
    getMeetingByUserId = 'meeting/getMeetingsByUserId',
    forceEndMeeting = 'meeting/forceEndMeeting/',
    checkMeetingAvailable = 'meeting/checkingparticpentdata',
    getPollList = 'poll/findByMeetingId',
    saveUpdatePoll = 'poll/saveOrUpdatePoll',
    getPollResult = 'pollresult/findBypollid',
    getPollResultByDate = 'pollresult/findBypollidDateWise',
    getReminderInterval = 'interval/getIntervals/',
    getReminderIntervalNew = 'interval/getIntervalsByDateAndZoname',
    deleteMeetings = 'meeting/deletemeetings',
    getAllChat = 'chat/getChatDetails/',
    getParticipantByMeetingId = 'meeting/participentmails',
    saveUpdateBreakoutRoom = 'breakoutroom/saveOrUpdate',
    getBreakoutRoom = 'breakoutroom/getBreakOutRoom',
    fetchAttendeeDataByMeetingID = 'conclave/nojwt/meeting/fetchAttendeeDataByMeetingID',
    startPayment = 'nojwt/payment/startPaymentB',
    getparticipantmails = 'mails/getparticipantmails',
    goforbroadcast = 'livestream/stream/goforbroadcast',
    noJwtforceEndMeeting = 'conclave/nojwt/meeting/forceEndMeeting',
    getAnnotatedDocuments = 'conclave/jwt/general/getFile/AnnotatedDocuments',
    getJoinAnnotatedFile = 'join/getAnnotatedFile',
    getjoinUsersbymeetingid = 'join/getusersbymeetingid',
    getJoinUrlAndPresentationFileName = 'join/getUrlAndPresentationFileName',
    getPresentationDocuments = 'conclave/jwt/general/getFile/PresentationDocuments',
    convertMp4 = 'recording/convert2mp4',
    getRecordingById = 'recording/getRecordingById',
    jwtgetMeetingList = 'jwt/meeting/getMeetingList',
    getUserData = 'login/auth/user',
    getServerTime = 'interval/getservercurrenttime',
    getTimeZone = 'timezone/fetchall',
    // getMeetingListByPagination = 'meeting/getMeetingListWithPagination',

    //Domain
    getDomainById = 'domain/domainByUserId',
    saveOrUpdateDomain = 'domain/saveOrUpdate',

    getSchemeUrl = 'schedule/streamurl',
    //smsinfo
    getsmsinfo = 'smsinfo/getsmsinfodata',

    //User
    getPaymentHistoryList = 'user/history',
    getUser = 'user/fetchall',
    changePwd = 'user/changepwd',
    // fetchUserByCreatorId = 'user/fetchbycreatorid/',
    fetchUsersForDropDown = 'user/fetchUsersForDropDown/',
    fetchUsersForDropDownNew = 'user/fetchusersfordropdownnew',
    // getUserByRole = 'user/fetchusersbyrole/',
    getUsersList = 'user/fetchuserslist/',
    logOut = 'user/logout',
    getUserByID = 'user/getuserdatabyid/',

    //Role
    getAllRole = 'role/fetchall',
    saveRole = 'role/saveOrUpdate',

    //Audit
    getLoginAudit = 'loginDashaboard/getlogindata',
    getLogindataForEnterpriseUser = 'loginDashaboard/getenterpriselogindata',

    //Subscription
    getSubscriptionPack = 'subscription/packs',
    subscripePack = 'subscription/users/saveorupdate',
    subscribeHistoryList = 'subscription/history/',
    getNewSubscriptionPack = 'subscription/subcriptionspacksbyduration',

    //Stream Settings
    getStreamMastersData = 'userstream/getStreamMastersData',
    getUserStreamData = 'userstream/getUserStreamData/',
    addUserStream = 'userstream/addUserStream',

    //Permission
    getAllPermission = 'permission/fetchallpermissions',
    savePermission = 'permission/saveOrUpdatepermission',
    getRoleWithPermission = 'permission/listofrolepermissions',
    getRoleWithOutPermission = 'permission/listofroleswithnopermissions',
    saveRolePermission = 'permission/saveOrUpdateRolePermissions',
    getPermissionByRole = 'permission/listofrolepermissions/',

    getSamvaadFeatures = 'features/getsamvaadFeatures',

    //Lpcation
    getCountry = 'locations/getCountries',
    getStateById = 'locations/geStatesByCountryId/',

    //ContactUs
    saveContuctUs = 'enquiry/contactUsForm/save',
    saveRequestDemo = 'enquiry/requestForDemo/save',
    requestDemoList = 'enquiry/requestForDemoList',

    //NewPlan And Features
    saveUpdateFeature = 'jwt/subscription/features/saveorupdate',
    getAllSubcriptionFeatures = 'subscription/fetchallsubcriptionfeatures',
    saveUpdatePlans = 'subscription/packs/saveorupdate',
    getAllSubscriptionPlans = 'subscription/fetchallsubcriptionspacks',
    saveUpdatePlanWiseFeatures = 'subscription/permission/saveorupdate'


    //#region Old Api Methods

    // saveUser = 'saveOrUpdate',
    // getUser = 'fetchall',
    // getUserByRole = 'fetchusersbyrole/',
    // logOut = 'logout',
    // changePwd = 'changepwd',
    // checkExistEmail = 'existcheck/',
    // fetchByUserId = 'fetchbycreatorid/',

    // login = 'login/login',
    // forgotPwd = 'login/forgotpwd',
    // saveMeeting = 'meeting/saveorupdate',
    // getMeeting = 'meeting/fetchall',
    // getMeetingByUserId = 'meeting/fetchbyuserid',
    // getAllRole = 'role/fetchall',
    // resendMail = 'meeting/mailsession/',
    // getMeetingByDate = 'meeting/fetchbydates/',
    // getSubscriptionPack = 'subscription/packs',
    // subscripePack = 'subscription/users/saveorupdate',
    // generateOtp = 'genearateotp/',
    // validateOtp = 'validateotp/',
    // subscribeHistoryList = 'subscription/history/',
    // getPaymentHistory='history/',
    // meetingCancel='meeting/cancelsession/',
    //#endregion
}
