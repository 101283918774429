import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EntityService } from 'src/app/Services/entity.service';
import { HttpService } from 'src/app/Services/http.service';
import { PaginationService } from 'src/app/Services/pagination.service';
import { BaseComponent } from '../BaseComponent';

@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.css']
})
export class UserlistComponent extends BaseComponent implements OnInit {

  imgsrc: any; canvas: any;
  constructor(public sanitizer: DomSanitizer,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected pageSvc: PaginationService,
    protected entitysvc: EntityService) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
  }

  ngOnInit() {
    this.svcConvert();
  }

  async svcConvert() {
    // http://stackoverflow.com/questions/3768565/drawing-a-svg-file-on-a-html5-canvas

    var svg = document.querySelector('svg');



    //document.querySelector("image").setAttribute('src', "");
    var url = document.querySelector("image").getAttribute('xlink:href');
    document.querySelector("image").setAttribute('xlink:href', "");

    await this.httpSvc.newGet(url).subscribe(val => {
      if (this.result) {
        var xml = new XMLSerializer().serializeToString(this.result);
        var svg64 = btoa(xml);
        console.log(svg64);
        document.querySelector("image").setAttribute('src', svg64);
      }
    });



    //  super.FetchData('Get', `${url}`, {})
    // .catch(() => { this.httpSvc.handleError, this.loading = false; })
    // .then(() => {
    //   if (this.result.data != []) {
    //     var xml = new XMLSerializer().serializeToString(this.result);
    //     var svg64 = btoa(xml);
    //     console.log(svg64);
    //     document.querySelector("image").setAttribute('src', svg64);

    //   }
    // });
    console.log(url);
    // get svg data
    var xml = new XMLSerializer().serializeToString(svg);


    // make it base64
    var svg64 = btoa(xml);
    var b64Start = 'data:image/svg+xml;base64,';

    // prepend a "header"
    var image64 = b64Start + svg64;
    var image65 = `data:image/svg+xml;base64, ${(this.sanitizer.bypassSecurityTrustResourceUrl(svg64) as any).changingThisBreaksApplicationSecurity}`;
    var image66 = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/svg+xml;base64,${svg64}`);
    // 'data:image/jpg;base64,' + (this.sanitizer.bypassSecurityTrustResourceUrl(item) as any).changingThisBreaksApplicationSecurity;
    // console.log(image64);
    // console.log(image65);
    // set it as the source of the img element
    // var images = document.getElementById('imgsvc');
    // images.src = image64;
    //img.src = image64;
    this.imgsrc = image66;
    var canvas = document.querySelector('canvas');
    canvas.width = 300;
    canvas.height = 200;
    // draw the image onto the canvas
    var img = document.querySelectorAll('img');
    //  console.log(img);
    setTimeout(() => { canvas.getContext('2d').drawImage(img[2], 0, 0); }, 1000);

    function dlCanvas() {
      var dt = canvas.toDataURL('image/png'); // << this fails in IE/Edge...
      dt = dt.replace(/^data:image\/[^;]*/, 'data:application/octet-stream');
      dt = dt.replace(/^data:application\/octet-stream/, 'data:application/octet-stream;headers=Content-Disposition%3A%20attachment%3B%20filename=Canvas.png');
      this.href = dt;
    };
    document.getElementById('dl').addEventListener('click', dlCanvas, false);
  }

}
