import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EntityService } from 'src/app/Services/entity.service';
import { HttpService } from 'src/app/Services/http.service';
import { PaginationService } from 'src/app/Services/pagination.service';
import { BaseComponent } from '../BaseComponent';
declare const $: any;
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent extends BaseComponent implements OnInit {

  userName = ''; showChangePwd = true; expiryDateTime = ''; rolename = ''; userType='';
  constructor(private http: HttpService,
    protected toastr: ToastrService,
    protected router: Router,
    protected entitysvc: EntityService,
    protected pageSvc: PaginationService,
    private el: ElementRef) {
    super(toastr, router, http, entitysvc, pageSvc);
  }

  ngOnInit() {
    if (sessionStorage.getItem('loginData')) {
      this.userName = JSON.parse(sessionStorage.getItem('loginData')).name;
      this.rolename = JSON.parse(sessionStorage.getItem('loginData')).role.name;
      this.expiryDateTime = JSON.parse(sessionStorage.getItem('loginData')).expiryDateTime == null ? '' : JSON.parse(sessionStorage.getItem('loginData')).expiryDateTime;
      this.userType =JSON.parse(sessionStorage.getItem('loginData')).userType;
    }
    this.showChangePwd = this.router.url.split('/')[1] == 'subsclist' ? false : true;

    $(function () {
      'use strict'
      $('[data-toggle="offcanvas"]').on('click', function () {
        $('.offcanvas-collapse').toggleClass('open')
      });
    });
  }
  // onActivate() {
  //   window.scroll(0, 0);
  // }

  async logOut() {
    if (JSON.parse(sessionStorage.getItem('jwtToken')))
      await super.LogOut();
  }

}
