import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EntityService } from 'src/app/Services/entity.service';
import { HttpService } from 'src/app/Services/http.service';
import { PaginationService } from 'src/app/Services/pagination.service';
import { BaseComponent } from '../BaseComponent';
import { LoginComponent } from '../login/login.component';
import { ProHeaderComponent } from '../pro-header/pro-header.component';
import customProtocolCheck from "custom-protocol-check";
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/Services/login.service';

declare let $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent extends BaseComponent implements OnInit {

  lstSubscribePack = []; showpackList = []; query: any = {};
  lstNewPackList: Array<any> = [];
  isMonthely: boolean = false;
  joinLink: string = '';
  isJoinScreen: boolean = true;
  isJoinModal: boolean = false;

  errorCount = 0;
  hitCount = 0;
  interval: any;
  meetingId = '';
  joinURL: string = '';
  iosJoinUrl: string = '';
  isLaunchInWeb: boolean = false;

  constructor(private http: HttpService, private isLogin: LoginService,
    protected toastr: ToastrService,
    protected router: Router,
    protected pageSvc: PaginationService,
    protected entitysvc: EntityService,
    private activeroute: ActivatedRoute,) {
    super(toastr, router, http, entitysvc, pageSvc);
    sessionStorage.clear(); http.isLogin = false;
    http.permissionArry = [];

    // let userLoginData = JSON.parse(localStorage.getItem('samvaadUserLogin'));
    // if (userLoginData) this.login(userLoginData);
    this.isLogin.changeMessage(http.isLogin);

  }

  ngOnInit() {
    const isMeetingLink: boolean = location.href.split('aar/').length > 1;
    if (isMeetingLink) {
      // this.isJoinScreen = false;
      this.isJoinModal = true;
      this.joinLink = location.href;
      this.checkMeetingURL();
      return;
    }

    this.activeroute.queryParams.subscribe(p => {
      this.query = p;
      if (Object.keys(p).length == 0)
        return;
      else if (this.query.errors) {
        const errorMessages: Array<{ key: string, message: string }> = JSON.parse(this.query.errors);
        // this.toastr.error('', errorMessages[0].message);
        // alert(errorMessages[0].message);
        sessionStorage.error = JSON.stringify(errorMessages);
        this.router.navigate(['error-msg']);
      }
      else {
        // let queryString = window.atob(p.query).split('&').map(e => e.substring(e.indexOf('=') + 1, e.length));
        // [this.username, this.password] = ['rajesh98y@gmail.com', '12345'];
        // [this.username, this.password] = [p.username, p.password];
        // this.onSubmit();
      }
    });
    window.onscroll = function () { myFunction() };
    function myFunction() {
      var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      var scrolled = (winScroll / height) * 100;
      // document.getElementById("myBar").style.width = scrolled + "%";
    }
    // $(function(){
    //   $('.bck').backToTop({
    //           iconName : 'fas fa-arrow-up',
    //           fxName : 'rightToLeft'
    //       });
    // });



    this.loadFunction();
    this.attributesTab();
    //this.testimonial();
    // this.getSubscribePack();
    this.getNewPack("annually");
  }

  keyDown(event: any) {
    if (event.keyCode == 13) this.joinButton(false);
  }

  toogleScreens(status: boolean) {
    if (this.joinLink.trim().length == 0) {
      return this.toastr.warning('Please enter joining link');
    } else {
      this.isLaunchInWeb = !status;
      this.isJoinScreen = !this.isLaunchInWeb;
      // setTimeout(() => this.isJoinScreen = status, 100);
    }
  }

  joinButton(status: boolean) {
    if (this.joinLink.trim().length == 0)
      this.toastr.warning('Please enter Joining Link');
    else if (this.joinLink.split('aar/').length > 1)
      this.checkMeetingURL();
    // this.isJoinModal = true;
    //this.isJoinScreen = status;
    else
      this.toastr.error("Invalid meeting link");
  }

  async getSubscribePack() {
    this.lstSubscribePack = [];
    await super.FetchData('Get', `${this.apiMethod.getSubscriptionPack}`, {})
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result.data != []) {
          let subscribePack = this.result.data;
          Object.keys(subscribePack).forEach(e => {
            this.lstSubscribePack.push({
              itemName: subscribePack[e][0].duration.replace(/([A-Z])/g, ' $1'),
              itemList: subscribePack[e],
            });
          });
          this.showpackList = this.lstSubscribePack[0].itemList;
          this.lstSubscribePack[1].itemList.forEach(e => {
            this.showpackList.push(e);
          });
        }
      });
  }

  async getNewPack(plan: string) {
    this.loading = true;
    let Payload = `{"duration":"${plan}"}`
    await super.FetchData('Post', `${this.apiMethod.getNewSubscriptionPack}`, Payload)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != [] && this.result.status == 'OK') {
          this.lstNewPackList = this.result.data;
        }
        this.loading = false;
      });
  }

  //  googleTranslateElementInit() {
  //   new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
  // }


  loadFunction() {
    // $.fn.owlCarousel = function (options) {
    //   $(".owl-carousel1").owlCarousel({
    //     loop: true,
    //     autoplay: true,
    //     autoplayTimeout: 2000,
    //     autoplayHoverPause: true,
    //     center: true,
    //     margin: 0,
    //     responsiveClass: true,
    //     nav: false,
    //     responsive: {
    //       0: {
    //         items: 1,
    //         nav: false
    //       },

    //       680: {
    //         items: 2,
    //         nav: false,
    //         loop: false
    //       },

    //       1000: {
    //         items: 3,
    //         nav: true
    //       }
    //     }
    //   });
    // }

    !(function ($) {
      "use strict";
      // Smooth scroll for the navigation menu and links with .scrollto classes
      var scrolltoOffset = $('#header').outerHeight() - 1;
      $(document).on('click', '.nav-menu a, .mobile-nav a, .scrollto', function (e) {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
          var target = $(this.hash);
          if (target.length) {
            e.preventDefault();
            var scrollto = target.offset().top - scrolltoOffset;
            if ($(this).attr("href") == '#header') {
              scrollto = 0;
            }
            $('html, body').animate({
              scrollTop: scrollto
            }, 1500, 'easeInOutExpo');
            if ($(this).parents('.nav-menu, .mobile-nav').length) {
              $('.nav-menu .active, .mobile-nav .active').removeClass('active');
              $(this).closest('li').addClass('active');
            }
            if ($('body').hasClass('mobile-nav-active')) {
              $('body').removeClass('mobile-nav-active');
              $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
              $('.mobile-nav-overly').fadeOut();
            }
            return false;
          }
        }
      });
      // Activate smooth scroll on page load with hash links in the url
      $(document).ready(function () {
        if (window.location.hash) {
          var initial_nav = window.location.hash;
          if ($(initial_nav).length) {
            var scrollto = $(initial_nav).offset().top - scrolltoOffset;
            $('html, body').animate({
              scrollTop: scrollto
            }, 1500, 'easeInOutExpo');
          }
        }
      });
      // Navigation active state on scroll
      var nav_sections = $('section');
      var main_nav = $('.nav-menu, .mobile-nav');

      $(window).on('scroll', function () {
        var cur_pos = $(this).scrollTop() + 200;

        nav_sections.each(function () {
          var top = $(this).offset().top,
            bottom = top + $(this).outerHeight();

          if (cur_pos >= top && cur_pos <= bottom) {
            if (cur_pos <= bottom) {
              main_nav.find('li').removeClass('active');
            }
            main_nav.find('a[href="#' + $(this).attr('id') + '"]').parent('li').addClass('active');
          }
          if (cur_pos < 300) {
            $(".nav-menu ul:first li:first, .mobile-nav ul:first li:first").addClass('active');
          }
        });
      });
      // Mobile Navigation
      if ($('.nav-menu').length) {
        var $mobile_nav = $('.nav-menu').clone().prop({
          class: 'mobile-nav d-lg-none'
        });
        $('body').append($mobile_nav);
        $('body').prepend('<button type="button" class="mobile-nav-toggle d-lg-none"><i class="fa fa-bars"></i></button>');
        $('body').append('<div class="mobile-nav-overly"></div>');
        $(document).on('click', '.mobile-nav-toggle', function (e) {
          $('body').toggleClass('mobile-nav-active');
          $('.mobile-nav-toggle i').toggleClass('fas fa-times');
          $('.mobile-nav-overly').toggle();
        });
        $(document).on('click', '.mobile-nav .drop-down > a', function (e) {
          e.preventDefault();
          $(this).next().slideToggle(300);
          $(this).parent().toggleClass('active');
        });
        $(document).click(function (e) {
          var container = $(".mobile-nav, .mobile-nav-toggle");
          if (!container.is(e.target) && container.has(e.target).length === 0) {
            if ($('body').hasClass('mobile-nav-active')) {
              $('body').removeClass('mobile-nav-active');
              $('.mobile-nav-toggle i').toggleClass('fas fa-times');
              $('.mobile-nav-overly').fadeOut();
            }
          }
        });
      } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
        $(".mobile-nav, .mobile-nav-toggle").hide();
      }
      // Toggle .header-scrolled class to #header when page is scrolled
      $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
          $('#header').addClass('header-scrolled');
          $('#topbar').addClass('topbar-scrolled');
        } else {
          $('#header').removeClass('header-scrolled');
          $('#topbar').removeClass('topbar-scrolled');
        }
      });
      if ($(window).scrollTop() > 100) {
        $('#header').addClass('header-scrolled');
        $('#topbar').addClass('topbar-scrolled');
      }
      // Back to top button
      $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
          $('.back-to-top').fadeIn('slow');
        } else {
          $('.back-to-top').fadeOut('slow');
        }
      });
      $('.back-to-top').click(function () {
        $('html, body').animate({
          scrollTop: 0
        }, 1500, 'easeInOutExpo');
        return false;
      });
    });

    $.fn.jQuerySimpleCounter = function (options) {
      var settings = $.extend({
        start: 0,
        end: 100,
        easing: 'swing',
        duration: 400,
        complete: ''
      },
        options);
      var thisElement = $(this);
      $({ count: settings.start }).animate({ count: settings.end }, {
        duration: settings.duration,
        easing: settings.easing,
        step: function () {
          var mathCount = Math.ceil(this.count);
          thisElement.text(mathCount);
        },
        complete: settings.complete
      });
    };
    $('#number1').jQuerySimpleCounter({ end: 12, duration: 3000 });
    $('#number2').jQuerySimpleCounter({ end: 55, duration: 3000 });
    $('#number3').jQuerySimpleCounter({ end: 359, duration: 2000 });
    $('#number4').jQuerySimpleCounter({ end: 246, duration: 2500 });
    /* AUTHOR LINK */
    $('.about-me-img').hover(function () {
      $('.authorWindowWrapper').stop().fadeIn('fast').find('p').addClass('trans');
    }, function () {
      $('.authorWindowWrapper').stop().fadeOut('fast').find('p').removeClass('trans');
    });

    // var heroCarousel = $("#carouselExampleIndicators");
    // var heroCarouselIndicators = $("#featured-services");
    // heroCarousel.find(".carousel-inner").children(".carousel-item").each(function (index) {
    //   (index === 0) ?
    //     heroCarouselIndicators.append("<li data-target='#heroCarousel' data-slide-to='" + index + "' class='active'></li>") :
    //     heroCarouselIndicators.append("<li data-target='#heroCarousel' data-slide-to='" + index + "'></li>");
    // });

    // heroCarousel.on('slid.bs.carousel', function (e) {
    //   $(this).find('h2').addClass('animate__animated animate__fadeInDown');
    //   $(this).find('p, .btn-get-started').addClass('animate__animated animate__fadeInUp');
    // });


    // $(function () {
    //   $('#homeVideo').modal({
    //     show: false

    //   }).on('hidden.bs.modal', function () {
    //     $(this).find('video')[0].pause();
    //   });
    // });

    //let vid = document.getElementById("homeVideo");
    // function enableMute() {
    //   vid.muted = true;
    // }
    // function disableMute() {
    //   vid.muted = false;
    // }
    // function checkMute() {
    //   alert(vid.muted);
    // }
    // $("#homeVideo").on('hidden.bs.modal', function () {
    //   $('.modal-backdrop').css('background-color', 'black', 'opacity', '0.9');
    // });


  }

  attributesTab() {
    $(".flipper").click(function () {
      var target = $(event.target);
      if (target.is("a")) {
        //follow that link
        return true;
      } else {
        $(this).toggleClass("flip");
      }
      return false;
    });

    $(document).ready(function () {

      $('.flipControl').on('click', function () {
        $(this).closest('.card').toggleClass('flipped');

      });

    });


    $(".option").click(function () {
      $(".option").removeClass("active");
      $(this).addClass("active");

    });

  }

  testimonial() {
    // (function () {
    //   "use strict";

    //var carousels = function () {
    $(".owl-carousel1").owlCarousel({
      loop: true,
      center: true,
      margin: 0,
      responsiveClass: true,
      nav: false,
      responsive: {
        0: {
          items: 1,
          nav: false
        },
        680: {
          items: 2,
          nav: false,
          loop: false
        },
        1000: {
          items: 3,
          nav: true
        }
      }
    });
    // };

    // (function ($) {
    //   carousels();
    // })(jQuery);
    //})();
  }

  login(loginData: any) {
    this.loading = true;
    super.FetchData('Post', this.apiMethod.login, loginData)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result.data && this.result.data != []) {
          this.httpSvc.isLogin = true;
          localStorage.setItem('samvaadUserLogin', JSON.stringify(loginData));
          sessionStorage.setItem('loginData', JSON.stringify(this.result.data));
          sessionStorage.setItem('jwtToken', JSON.stringify(this.result.data.jwtToken));
          this.httpSvc.loginUserDetails = this.result.data;
          this.httpSvc.userName = this.result.data.name;
          this.result.data.role.permission.forEach(e => this.httpSvc.permissionArry.push(e.name));
          if (this.result.data && this.result.data.email == 'admin@dhanushinfotech.net')
            this.httpSvc.permissionArry.push(...['Privileges', 'Role', 'Privilege', 'Role Privilege Mapping']);
          if (Object.keys(this.query).length != 0) {
            this.router.navigate(['/Home/subsclist']);
            this.loading = false;
          }
          else {
            if (this.result.data.role.name == 'admin')
              this.router.navigate(['/Home/user']);
            else if (this.result.data.role.name == 'IS')
              this.router.navigate(['/Home/approvalDomail']);
            else {
              if (this.result.data.subscribed || this.result.data.userType == 'EH')
                this.router.navigate(['/Home/schedule']);
              else
                this.router.navigate(['/Home/subsclist']);
            }
          }
        }
        this.loading = false;
      });
  }

  toggelPack(event) {
    this.isMonthely = event.target.checked;
    let plan = this.isMonthely ? 'monthly' : 'annually';
    this.getNewPack(plan);
  }

  numberWithCommas(price: any) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  buttonChanged(id: number) {
    setTimeout(() => this.isJoinModal = false, 100);
    console.log(id);
    switch (id) {
      case 1://Launch in App(Mobile)
        this.checkDevice();
        // window.close();
        // this.router.navigate(['/']);
        break;
      case 3://Download desktop(Web)
        this.download();
        break;
      case 5://Launch in Desktop(Web)
        this.openDesktopApplication();
        break;
      case 2://Launch in Web(Mobile)
      case 4://Browser(Web)
      // console.log("Web Browser");
      // break;
      default://Close button
        console.log("Close button");
        this.isLaunchInWeb = true;
        break;
    }
  }

  openDesktopApplication() {
    let new_URL = this.getNewURL(window.location.href, true);
    this.meetingId = this.getNewURL(location.href).split('aar/')[1];
    this.errorCount = 0; this.hitCount = 0;
    clearInterval(this.interval);
    const time = (new Date()).getTime();
    const samvaadProtocolString = `samvaad-pro://${new_URL}?V=${time}`;
    // const samvaadProtocolString = `samvaad-pro://https://dev.dhanushsamvaad.com/samvaad/aar/u1aFK-1647328078769?V=${(new Date()).getTime()}`;

    customProtocolCheck(samvaadProtocolString,
      () => {
        this.toastr.warning('Samvaad app not found on local machine.');
        //console.log("Custom protocol not found.");
      },
      () => {
        console.log("Custom protocol found and opened the file successfully.");
        this.checkSession(time.toString());
      }, 10000
      // () => { setTimeout(() => window.location.href = this.getHomeUrl(), 500); console.log("Custom protocol found and opened the file successfully."); }, 10000
    );
  }

  async checkSession(time: string) {
    this.hitCount++;
    await super.FetchData('Get', `session/check/${this.meetingId.split('-')[1]}/${time}`, {}, 3)
      .catch(() => { this.httpSvc.handleError })
      .then(() => {
        if (this.result.data) {
          clearInterval(this.interval);
          window.location.href = this.getHomeUrl();
        }
        else {
          this.errorCount++;
          if (this.hitCount === 50)
            clearInterval(this.interval);
          if (this.errorCount == 1)
            this.interval = setInterval(() => this.checkSession(time), 10000);
        }
      });
  }

  download() {
    Promise.all([this.getDownloadServerUrl()]).then(() => {
      setTimeout(() => {
        // window.location.href = `${this.recordingServerUrl + "setup/download"}`;
        const index = this.getDeviceInfo();
        if (index > 0)
          window.location.href = `${this.recordingServerUrl}setup/download/${index}`;
      }, 500);
    });
  }

  getDeviceInfo() {
    let OS_TypeId = 0;
    const osInfo = [
      { id: 1, name: "Win", fullName: "Windows OS" },
      { id: 2, name: "Linux", fullName: "Linux OS" },
      { id: 3, name: "Mac", fullName: "Macintosh" },
      { id: 4, name: "Android", fullName: "Android OS" },
      { id: 5, name: "like Mac", fullName: "iOS" },
      // { id: 0, name: "Win", fullName: "Unknown OS" },
    ];

    for (let index = 0; index < osInfo.length; index++) {
      if (navigator.userAgent.indexOf(osInfo[index].name) != -1) {
        OS_TypeId = osInfo[index].id;
        break;
      }
    }

    return OS_TypeId;
  }

  checkDevice() {
    let new_URL = this.getNewURL(window.location.href);
    this.joinURL = `intent://${new_URL.replace('mobile', 'aar').replace('https://', '')}/#Intent;scheme=dhanush;package=in.org.dhanush.samvaad;end`;
    this.iosJoinUrl = `dhanush://${new_URL.replace('mobile', 'aar').replace('https://', '')}`;
    let isMobile = /Android/i.test(navigator.userAgent);
    let isIosMobile = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    if (isMobile) {
      // document.getElementById("btnCofirmModal").click();
      setTimeout(() => this.linkOpen(this.joinURL), 3000);
    }
    if (isIosMobile) {
      // document.getElementById("btnCofirmModal").click();
      setTimeout(() => this.linkOpen(this.iosJoinUrl), 3000);
    }
  }

  linkOpen(url: any) {
    window.location.assign(url);
    setTimeout(() => document.getElementById("btnClose").click(), 1000);
  }

  getNewURL(URL: string, AV: boolean = false): string {
    URL = URL.split('aar/').length > 1 ? URL : this.joinLink;
    const audio_video: boolean = (AV == false) ? (URL.split('_').length == 2) : false;
    let new_URL: string = audio_video ? URL.split('_')[0] : URL;
    return new_URL;
  }

  checkMeetingURL() {
    let url = location.hostname != 'localhost' ? `${location.origin}/conference/` : environment.apiUrl + 'conference/';
    let id: string = '';

    this.isLaunchInWeb = false;
    this.isJoinModal = false;
    try {
      id = this.joinLink.split('aar/')[1];

    } catch (error) { }
    finally {
      if (!id) {
        return this.toastr.warning('Please enter valid meeting link');
      }
    }
    let isMeeting = (id.split('_').length == 2); //click on the start button in scheduling screen
    if (isMeeting) {
      const arr: Array<any> = id.split('_');
      id = arr[0];
    }

    this.httpSvc.newGet(`${url}conclave/nojwt/meeting/getMeetingDetails/${id}`).subscribe(res => {
      const result = res.json();
      this.isJoinModal = true;
    },
      (err => {
        const result = err.json();
        this.toastr.error(result.message);
      }));
  }

  onOpenApp(id: number) {
    if (id == 3)
      this.download();
    else {
      const name = id == 1 ? "playStore" : "appStore";
      document.getElementById(name).click();
    }
  }
}