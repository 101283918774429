import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs-compat';
import { EntityService } from 'src/app/Services/entity.service';
import { HttpService } from 'src/app/Services/http.service';
import { PaginationService } from 'src/app/Services/pagination.service';
import { BaseComponent } from '../../BaseComponent';

@Component({
  selector: 'app-plan-features-mapping',
  templateUrl: './plan-features-mapping.component.html',
  styleUrls: ['./plan-features-mapping.component.css']
})
export class PlanFeaturesMappingComponent extends BaseComponent implements OnInit {

  mode: string = 'Add';
  lstPlans: Array<any> = [];
  packID: any;
  lstPlanFeatursPermission: Array<any> = [];
  lstSubscriptionPlan: Array<any> = [];
  lstAssignFeaturesSubPlan: Array<any> = [];
  lstUnAssignFeaturesSubPlan: Array<any> = [];
  lstSubscriptionFeature: Array<any> = [];
  planFeaturesForm: FormGroup;
  checkedFeatures: Array<number> = [];
  featuresPermissionId: Array<number> = [];
  dtTrigger: Subject<any> = new Subject();
  constructor(private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected pageSvc: PaginationService,
    protected entitysvc: EntityService) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
  }

  ngOnInit() {
    this.packID = 0;
    this.formInet();
    this.getAllPlan();
  }

  addEditFeaturesPermission(item: any, mode: string) {
    $('#defaultChecked_Modal0').prop('checked', false);
    this.featuresPermissionId = []; this.checkedFeatures = [];
    this.lstPlans = [];
    this.mode = mode;
    this.getAllFeatures();
    let plans = mode == 'Update' ? this.lstSubscriptionPlan : this.lstUnAssignFeaturesSubPlan;
    plans.forEach(m => { this.lstPlans.push({ id: m.id, name: m.description }) });
    if (this.mode == 'Update') {
      this.packID = item.id;
      this.planFeaturesForm.patchValue({ packID: item.id });
      item.subscriptionPermission.forEach(e => { this.featuresPermissionId.push(e.id) });
      this.checkedFeatures = this.featuresPermissionId;
    }

  }

  async getAllPlan() {
    this.loading = true; this.lstSubscriptionPlan = []; this.lstAssignFeaturesSubPlan = [];
    this.lstUnAssignFeaturesSubPlan = [];
    jQuery('.dataTable').DataTable().destroy();
    jQuery('.dataTable').DataTable({ searching: false });
    await super
      .FetchData("Get", this.apiMethod.getAllSubscriptionPlans, {}, 1)
      .catch(() => { this.httpSvc.handleError, (this.loading = false); })
      .then(() => {
        if (this.result != []) {
          let data = this.result.data;
          this.lstSubscriptionPlan = data.filter(m => m.deleted == false && m.description.trim() != '');
          this.lstAssignFeaturesSubPlan = this.lstSubscriptionPlan.filter(m => m.subscriptionPermission.length > 0);
          this.lstUnAssignFeaturesSubPlan = this.lstSubscriptionPlan.filter(m => m.subscriptionPermission.length == 0);
          //unassignedplan.forEach(m => { this.lstUnAssignFeaturesSubPlan.push({ id: m.id, name: m.description }) });
          this.dtTrigger.next();
        }
        this.loading = false;
      });
  }

  async getAllFeatures() {
    this.loading = true; this.lstSubscriptionFeature = [];
    await super
      .FetchData("Get", this.apiMethod.getAllSubcriptionFeatures, {}, 1)
      .catch(() => { this.httpSvc.handleError, (this.loading = false); })
      .then(() => {
        if (this.result != []) {
          let lstFeature = this.result.data;
          this.lstSubscriptionFeature = lstFeature.filter(m => m.deleted == false);
        }
        this.loading = false;
      });
  }

  checkUncheckEntity(event: any, id: number) {
    debugger
    if (event.target.checked) {
      this.checkedFeatures.push(id);
    } else if (this.checkedFeatures.includes(id)) {
      this.checkedFeatures.splice(this.checkedFeatures.indexOf(id), 1);
    }
    this.checkedFeatures.sort((a, b) => a - b);
  }

  checkbox_checked(isChecked: boolean, id: number) {
    if (this.mode == 'Update' && isChecked == false)
      isChecked = this.featuresPermissionId.includes(id);
    return isChecked;
  }

  check_AllFeatures(event: any) {
    if (event.target.checked) {
      this.checkedFeatures = [];
      for (const iterator of this.lstSubscriptionFeature) {
        this.checkedFeatures.push(iterator.id);
      }
    }
    else {
      this.checkedFeatures = this.featuresPermissionId;
      if (this.mode == 'Update') {
        setTimeout(() => {
          let [checkedArr, uncheckedArr] = [this.lstSubscriptionFeature.filter(v => this.checkedFeatures.includes(v.id)), this.lstSubscriptionFeature.filter(v => !this.checkedFeatures.includes(v.id))];
          this.lstSubscriptionFeature = JSON.parse(JSON.stringify([...checkedArr, ...uncheckedArr]));
        }, 100);
      }
    }
  }

  async savePlanWiseFeatures() {
    if (this.checkedFeatures.length == 0) {
      this.toastr.warning('Please select features.');
      return;
    }
    this.loading = true;
    let payload = {
      "packID": this.packID,
      "features": this.checkedFeatures
    }
    await super.FetchData("Post", this.apiMethod.saveUpdatePlanWiseFeatures, payload, 1)
      .catch(() => { this.httpSvc.handleError, (this.loading = false); })
      .then(() => {
        if (this.result != []) {
          if (this.result.status == 'OK') {
            this.toastr.success(this.result.message);
            this.close();
            this.getAllPlan();
          }
        }
        this.loading = false;
      });
  }

  formInet() {
    this.planFeaturesForm = this.formBuilder.group({
      id: [0],
      packID: ['', [Validators.required]]
    });
  }

  close() {
    this.formInet();
    this.checkedFeatures = [];
    this.featuresPermissionId = [];
    this.lstSubscriptionFeature = [];
    $('#defaultChecked_Modal0').prop('checked', false);
    $(".close").click();
  }

  ngOnDestroy() {
    $(".close").click();
  }

}
