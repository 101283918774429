import { Component, OnInit, ElementRef } from '@angular/core';
import { HttpService } from 'src/app/Services/http.service';
import { BaseComponent } from '../BaseComponent';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { EntityService } from 'src/app/Services/entity.service';
import { DatePipe } from '@angular/common';
import { PaginationService } from 'src/app/Services/pagination.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [DatePipe]
})
export class HeaderComponent extends BaseComponent implements OnInit {

  userName = ''; showChangePwd = true; expiryDateTime = ''; rolename = '';
  constructor(private http: HttpService,
    protected toastr: ToastrService,
    protected router: Router,
    protected entitysvc: EntityService,
    protected pageSvc: PaginationService,
    private el: ElementRef) {
    super(toastr, router, http, entitysvc, pageSvc);
  }

  ngOnInit() {
    this.userName = JSON.parse(sessionStorage.getItem('loginData')).name;
    this.rolename = JSON.parse(sessionStorage.getItem('loginData')).role.name;
    this.expiryDateTime = JSON.parse(sessionStorage.getItem('loginData')).expiryDateTime == null ? '' : JSON.parse(sessionStorage.getItem('loginData')).expiryDateTime;
    this.showChangePwd = this.router.url.split('/')[1] == 'subsclist' ? false : true;
    // this.http.loginUserDetails = (this.http.loginUserDetails == undefined || this.http.loginUserDetails == null) ? JSON.parse(sessionStorage.getItem('loginData')) : this.http.loginUserDetails;
    // this.http.userName = this.http.loginUserDetails.name;
    // this.userName =  JSON.parse(sessionStorage.getItem('loginData'));


    // $(this.el.nativeElement).find('button').on('click', (e, args) => {
    //   if ($('#header-bg').css('top') != "0px") {
    //     $(this).find($(".fa")).removeClass('fa-chevron-down').addClass('fa-chevron-up');
    //     $('.contentarea').removeClass('contentclick');
    //     document.getElementById("header-bg").style.top = "0";
    //   }
    //   else {
    //     $(this).find($(".fa")).removeClass('fa-chevron-up').addClass('fa-chevron-down');
    //     $('.contentarea').addClass('contentclick');
    //     $("#header-bg").css("top", "-87px");

    //   }

    // });
    $(function () {
      'use strict'

      $('[data-toggle="offcanvas"]').on('click', function () {
        $('.offcanvas-collapse').toggleClass('open')
      })
    })

  }

  async logOut() {
    if (JSON.parse(sessionStorage.getItem('jwtToken')))
      await super.LogOut();
  }

  // checkPermission(): boolean {
  //   return super.checkPermission();
  // }

}
