import { Component, OnInit } from "@angular/core";
import { User } from "src/app/Entities/entity";
import { ToastrService } from "ngx-toastr";
import { FormGroup, Validators, FormControl, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { HttpService } from "src/app/Services/http.service";
import { EntityService } from "src/app/Services/entity.service";
import { BaseComponent } from "src/app/Components/BaseComponent";
import { Subject } from "rxjs";
import { DatePipe } from "@angular/common";
import { TimepickerConfig } from "ngx-bootstrap/timepicker";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ResponseContentType, Http, Headers } from '@angular/http';
import { PaginationService } from "src/app/Services/pagination.service";
declare let $: any;

@Component({
  selector: 'app-upload-user-manual',
  templateUrl: './upload-user-manual.component.html',
  styleUrls: ['./upload-user-manual.component.css'],
  providers: [DatePipe],
})

export class UploadUserManualComponent extends BaseComponent implements OnInit {

  userManualForm: FormGroup; fromData: FormData; filename = ''; mode = 'Add';
  uploadfile: File = null; lstUserManusl = []; jwttoken = ''; role = '';
  dtTrigger: Subject<any> = new Subject();
  constructor(
    private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected entitysvc: EntityService,
    private datepipe: DatePipe,
    private http: HttpClient,
    protected pageSvc: PaginationService,
    private _http: Http) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
  }

  ngOnInit() {
    let user = JSON.parse(sessionStorage.getItem('loginData'));
    this.role = JSON.parse(sessionStorage.getItem('loginData')).role.id;
    this.jwttoken = (user == undefined) ? '' : user.jwtToken;
    this.formInit();
    this.loadUserManual();
  }

  formInit() {
    this.userManualForm = this.formBuilder.group({
      id: [0],
      user: {
        id: [0]
      },
      active: true,
      description: ["", [Validators.required]],
      documentName: ["", [Validators.required]]
    });
    this.mode = 'Add';
  }
  get f() { return this.userManualForm.controls; }

  async loadUserManual() {
    this.loading = true; this.lstUserManusl = [];
    jQuery('.dataTable').DataTable().destroy();
    jQuery('.dataTable').DataTable({ searching: false });
    let user = JSON.parse(sessionStorage.getItem('loginData'));
    var path = '';
    if (user.role.name.toLowerCase() === 'super admin')
      path = `admin/getAllUserManuals/${user.id}`;
    else
      path = `admin/getAllUserManuals`;
    await super.FetchData('Get', path, {}, 1)
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != []) {
          this.lstUserManusl = this.result.data;
          this.dtTrigger.next();
        }
        this.loading = false;
      });
  }

  handleFileInput(files: FileList) {
    let arr = files.item(0).name.split('.');
    var ext = arr[arr.length - 1].toLowerCase();
    if ($.inArray(ext, ['doc', 'docx', 'ppt', 'pptx', 'pdf']) == -1) {
      this.toastr.error('Please select valid file format.');
      return;
    }
    let size = files.item(0).size / 1024 / 1024;
    if (+size > 5) {
      this.toastr.error('Please select file size less then 5MB.');
      return;
    }
    this.uploadfile = files.item(0);
    this.filename = files.item(0).name;
    let _URL = window.URL || (window as any).webkitURL;
    let img, isValid: boolean = false;
    if ((files[0])) {
      img = new Image();
      var objectUrl = _URL.createObjectURL(files[0]);
      img.onload = function () {
        if (this.width == 150 && this.height == 33) { }
        else
          isValid = true;
      };
      setTimeout(() => {
        if (isValid)
          this.toastr.error('File width and height should be 150px by 33px');
        else {
          $(".custom-file-label").addClass("selected").html(files.item(0).name);
        }
      }, 50);
      img.src = objectUrl;
    }
  }

  async saveUpdateUserManuals() {
    this.fromData = new FormData;
    let entity = this.userManualForm.getRawValue();
    entity.user.id = JSON.parse(sessionStorage.getItem("loginData")).id;
    delete entity.documentName;
    this.fromData.append('documentname', this.uploadfile);
    this.fromData.append('request', JSON.stringify(entity));
    this.loading = true;
    await this.http.post(`${this.httpSvc.BaseUrl}conference/jwt/admin/uploadingmanuals`, this.fromData,
      { headers: new HttpHeaders({ Authorization: this.jwttoken }) }).
      subscribe(res => {
        let result: any = res;
        if (result.status == 'OK') {
          this.fromData = new FormData;
          this.uploadfile = null;
          this.toastr.success(result.message);
        }
        else this.toastr.error(result.message);
        $(".close").click();
        this.loading = false;
        this.loadUserManual();
        this.clearModal();
      },
        err => {
          if (err.status == 401) {
            this.toastr.error(err.error.message);
            sessionStorage.clear();
            this.jwttoken = '';
            setTimeout(() => { this.router.navigate(['']); }, 1000);
          }
          else {
            this.toastr.error(err.error.message);
          }
          this.loading = false;
        }
      );
  }

  updateUserManual(item: any) {
    this.mode = 'edit';
    this.userManualForm.patchValue({
      id: item.id,
      description: item.description,
      active: JSON.parse(item.active),
      documentName: item.documentName.split('/')[item.documentName.split('/').length - 1]
    });
    // this.filename = item.documentName.split('/getFile/')[1];
    // $(".custom-file-label").addClass("selected").html(item.documentName.split('/getFile/')[1]);
    //this.filename = item.documentName.split('/getFile/')[1];
    this.getdocument(item.documentName);
  }

  // openLogo(url: any) {
  //   window.open(url);
  // }

  download(url: any) {
    // var link = document.createElement("a");
    // link.href = url;
    // link.style.visibility = "visibility:hidden";
    // link.download = url;
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    if (window.orientation > -1)
      window.open(url);
    else {
      return this._http.get(`${url}`, { headers: new Headers({ Authorization: this.jwttoken }), responseType: ResponseContentType.Blob, }).
        subscribe(res => {
          let url = window.URL.createObjectURL(res.blob()),
            a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none'), a.href = url, a.download = res.url.split('getFile/')[1], a.click();
          window.URL.revokeObjectURL(url), a.remove();
        });
    }

  }

  async getdocument(url) {
    // await this.http.get(`${url}`,{ headers: new HttpHeaders({ Authorization: this.jwttoken }) }).toPromise()
    // .then(res => {
    //   let result: any = res;
    //   if (result.status == 'OK') {
    //     this.uploadfile = result.data;
    //   }
    // });
    return this._http.get(`${url}`, { headers: new Headers({ Authorization: this.jwttoken }), responseType: ResponseContentType.Blob, }).
      subscribe(res => {
        console.log(res);
      });
  }

  clearModal() {
    this.fromData = new FormData;
    this.uploadfile = null;
    this.formInit();
    $(".custom-file-label").addClass("selected").html('Choose file');
  }

  selectFile() {

  }

  ngOnDestroy() {
    $(".close").click();
  }

}
