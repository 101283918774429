import { Component, OnInit } from '@angular/core';
declare const $: any;
@Component({
  selector: 'app-samvaad-resources',
  templateUrl: './samvaad-resources.component.html',
  styleUrls: ['./samvaad-resources.component.css']
})
export class SamvaadResourcesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(function () {
      $('#myTab ul li a').click(function (ev) {
          $('#myTab ul li').removeClass('selected');
          $(ev.currentTarget).parent('li').addClass('selected');
      });

      $(".collapse.show").each(function(){
        $(this).prev(".card-header").find(".fa").addClass("fa-chevron-down").removeClass("fa-chevron-right");
      });

      $(".collapse").on('show.bs.collapse', function(){
        $(this).prev(".card-header").find(".fa").removeClass("fa fa-chevron-right").addClass("fa fa-chevron-down");
      }).on('hide.bs.collapse', function(){
        $(this).prev(".card-header").find(".fa").removeClass("fa fa-chevron-down").addClass("fa fa-chevron-right");
      });
  });
  }

}
