import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EntityService } from 'src/app/Services/entity.service';
import { HttpService } from 'src/app/Services/http.service';
import { PaginationService } from 'src/app/Services/pagination.service';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../BaseComponent';

@Component({
  selector: 'app-join-meeting',
  templateUrl: './join-meeting.component.html',
  styleUrls: ['./join-meeting.component.css']
})
export class JoinMeetingComponent extends BaseComponent implements OnInit {

  public loading = false;
  meetingLink = ''; url = ''; localUrl = ''; lstSamvaadFeatures = [];
  joinMeeting: FormGroup
  constructor(private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected entitysvc: EntityService,
    private activeroute: ActivatedRoute,
    protected pageSvc: PaginationService
  ) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
    [this.url, this.localUrl] = [environment.apiUrl, environment.localUrl];
    if (location.hostname != 'localhost')
      this.url = `${location.origin}/`;
  }

  ngOnInit() {
    this.joinMeeting = this.formBuilder.group({
      'meetingLink': ['', [Validators.required]]
    });
    this.contentLoad();
  }

  join() {
    if (this.meetingLink.includes("https://"))
      window.open(`${this.meetingLink}`);
    else
      window.open(`${this.url}aar/${this.meetingLink}`);
  }

  contentLoad() {
    this.loading = true;
    super.FetchData('Get', `${this.apiMethod.getSamvaadFeatures}`, {})
      .catch(() => { this.httpSvc.handleError, this.loading = false; })
      .then(() => {
        if (this.result != [] && this.result.status == 'OK') {
          this.lstSamvaadFeatures = this.result.data;
        }
        this.loading = false;
      });
  }

}
