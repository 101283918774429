import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpService } from "src/app/Services/http.service";
import { EntityService } from "src/app/Services/entity.service";
import { environment } from "src/environments/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { DeviceDetectorService } from "ngx-device-detector";
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { Jsonp } from "@angular/http";
import { DatePipe } from "@angular/common";
import { LoginService } from "src/app/Services/login.service";

declare const showDiv2: any;
declare const $: any;

@Component({
  selector: "app-video-room",
  templateUrl: "./video-room.component.html",
  styleUrls: ["./video-room.component.css"],
  providers: [NgxSpinnerService],
})
export class VideoRoomComponent implements OnInit {
  loading = false;
  viedoroom: FormGroup;
  username: string = "";
  password: string = "";
  checkMetingExist = true;
  sub: any;
  count = 0;
  countServerHit = 0;
  showjoinMessage: boolean = false;
  mobile: string = "";
  email: string = "";
  location: string = "";
  occupation: string = "";
  joinParticipantData: any;
  query: any;
  isHost: boolean = false;
  bbb_url: string = "";
  meeting_title: string = "";
  repeatdisplay: string = "";
  passwordrequired: boolean = false;
  url = location.hostname != 'localhost' ? `${location.origin}/conference/` : environment.apiUrl + 'conference/';
  isIOS_Android: boolean = false;
  joinURL: string = "";
  endTime: string = "";
  btnJoin = 'Join';
  disableJoin = false;
  isDataAvailable = false;
  companyName = '';
  designation = '';
  user: any;
  rememberMe: any;
  startTime: string = '';
  meetingHostName: string = '';
  emailRequired: boolean = false;
  displayStartTime: string = '';
  displayEndTime: string = '';

  @Input() meetingURL: string = "";
  @Output() toogleScreens: EventEmitter<boolean> = new EventEmitter();
  isHostStartedMeeting: boolean = false;
  data = { startWithVideoMuted: true, startWithAudioMuted: true };
  constructor(
    private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected entitysvc: EntityService,
    private activeroute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private info: DeviceDetectorService,
    private datepipe: DatePipe,
    private loginService: LoginService,
  ) {
    //  this.bbb_url = environment.apiUrl;
    this.user = JSON.parse(sessionStorage.getItem('loginData'));
  }

  ngOnInit() {
    this.viedoroom = this.formBuilder.group({
      'username': '',
      'password': '',
      'company': '',
      'mobile': ['', [Validators.required, Validators.minLength(10)]],
      'designation': '',
      'email': new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]),
      'occupation': '',
      'location': '',
    });

    this.validatepattern();

    this.loading = true;
    //this.checkDevice();
    this.showjoinMessage = false;
    let p: any;
    this.loginFocus();
    this.setFocus("username");
    // Promise.all([this.getBBB_Api()]).then(()=>{
    // this.activeroute.params.subscribe((e) => {

    let id: string = '';
    if (this.meetingURL) {
      try {
        id = this.meetingURL.split('aar/')[1];

      } catch (error) {

      }
      finally {
        if (!id) {
          this.toogleScreens.emit(true);
          return this.toastr.warning('Please Enter Valid Meeting Link');
        }

      }
      this.isHostStartedMeeting = (id.split('_').length == 2); //click on the start button in scheduling screen
      if (this.isHostStartedMeeting) {
        const arr: Array<any> = id.split('_');
        id = arr[0];
        this.data = JSON.parse(atob(arr[1]));
      }

      console.log("isHostStartedMeeting", this.isHostStartedMeeting);
      this.httpSvc.newGet(`${this.url}conclave/nojwt/meeting/getMeetingDetails/${id}`).subscribe(res => {
        this.isDataAvailable = true;
        this.loading = false;


        const result = res.json();
        this.disableJoin = false;
        // console.log(result);
        if (result.message === "getMeetingDetails" && result.status === "OK")
          this.bbb_url = result.data.avserviceurl;
        p = result.data.meetingdetails;
        // });
        // this.httpSvc.newGet(`${this.bbb_url}jwt/general/getUserData/${e.id}`).subscribe((res)=>{
        //     const response = res.json();
        //     p=JSON.parse('{"' + response.data.replace(/&/g, '","').replace(/=/g, '":"') + '"}');

        // this.activeroute.queryParams.subscribe((q) => {
        //   p = window.atob(q.query.toString());
        //   p = JSON.parse('{"' + p.replace(/&/g, '","').replace(/=/g, '":"') + '"}');

        // this.joinURL = `intent://${window.location.href}/intent://#Intent;action=android.intent.action.SEND;type=text/plain;S.android.intent.extra.TEXT=http://test.com;S.android.intent.extra.SUBJECT=Test;end`;
        //#Intent;scheme=dhanush;package=in.org.dhanush.samvaad;end
        //window.location= 'intent://scan/#Intent;scheme=dhanush;package=in.org.dhanush.samvaad;S.joinUrl=https://meet.dhanushinfotech.com/aar?query=ZnVsbE5hbWU9TmFtZSZtZWV0aW5nSUQ9MTYwMDY3MjcyNDI3NyZyZWRpcmVjdD10cnVlJm11dGVPblN0YXJ0PXRydWUmY3JlYXRlVGltZT0xNjAwNjc3MDAwMDAwJmVuZFRpbWU9MTYwMDY3NzYwMDAwMCZyb2xlPXBhcnRpY2lwYW50JnVzZXJJRD0xMTNhJm5hbWU9dGVzdGluZyBhdWRpbyBpc3N1ZSBieSBzcmlrYXIgYW5kIGFsc28gdGVzdGluZyBhbGwgdGhlIHJlbWFpbmluZyBmdW5jdGlvbmFsaXRpZXMgaW4gdGhlIHZpZGVvIGNvbmZlciZndWVzdD1mYWxzZSZwYXNzd29yZHJlcXVpcmVkPWZhbHNlJmhpZGVQcmVzZW50YXRpb249ZmFsc2UmcGFzc3dvcmQ9MTkwOTUzNjM0OQ%3D%3D;end'
        //https://meet.dhanushinfotech.com/aar/QTpt-1601383682919-38Ov"
        this.joinURL = `intent://scan/#Intent;scheme=dhanush;package=in.org.dhanush.samvaad;S.joinUrl=${window.location.href};end`;
        // if (Object.keys(q).length == 0) return;
        if (Object.keys(id).length == 0) return;
        else {
          this.isHost = p.role.toLowerCase() == "host" || p.role.toLowerCase() == "teacher";
          this.endTime = p.EndTime;
          this.startTime = p.StartTime;
          this.meetingHostName = p.hostName;
          this.displayStartTime = p.zoneId > 0 ? this.datepipe.transform(p.startTimeWithTimeZone, 'hh:mm a') : p.StartTime;
          this.displayEndTime = p.zoneId > 0 ? this.datepipe.transform(p.endTimeWithTimeZone, 'hh:mm a') : p.EndTime;
          this.emailRequired = p.emailRequired || p.emailRequiredWithoutValidation || false;
          if (!this.isHost) {
            //Student
            this.btnJoin = 'Join';
            this.query = {
              name: p.name,
              fullName: p.fullName,
              password: p.password || "",
              meetingID: p.meetingID,
              role: p.role,
              passwordrequired: p.passwordrequired || "",
              samvaad: "true",
              ErrorFlag: p.ErrorFlag,
              ErrorMessage: p.ErrorMessage,
              repeatdisplay: p.repeatdisplay,
              emailRequired: p.emailRequired || p.emailRequiredWithoutValidation || false
            };

            this.passwordrequired = this.query.passwordrequired === "true";
          } else {
            //Host
            this.btnJoin = 'Start The Meeting';
            this.query = {
              name: p.name,
              meetingID: p.meetingID,
              fullName: p.fullName,
              userID: p.userID,
              role: p.role,
              samvaad: "true",
              ErrorFlag: p.ErrorFlag,
              ErrorMessage: p.ErrorMessage,
              repeatdisplay: p.repeatdisplay,
            };
          }
          // console.log(this.query);
          [this.username, this.meeting_title, this.email, this.mobile] = [
            this.isHost ? this.query.fullName : this.user ? this.user.name || "" : "",
            this.query.name || "",
            this.isHost ? "" : this.user ? this.user.email || "" : "",
            this.isHost ? "" : this.user ? `${this.user.mobileNO}` || "" : "",
          ];
          if (this.email != "")
            this.viedoroom.controls['email'].disable();
          if (this.username != "" && !this.isHost)
            this.viedoroom.controls['username'].disable();
          if (this.mobile != "")
            this.viedoroom.controls['mobile'].disable();
          this.repeatdisplay = this.query.repeatdisplay;

          let joindata = JSON.parse(localStorage.getItem('joinData'));
          let userSessionStorage = JSON.parse(sessionStorage.getItem('loginData'));
          if (joindata) {
            [this.username, this.password, this.email, this.companyName, this.designation, this.mobile, this.occupation, this.location] =
              [joindata.username, joindata.password, joindata.email, joindata.company, joindata.designation, joindata.mobile, joindata.occupation, joindata.location];
          }
          if (localStorage.username || this.username) {
            this.username = localStorage.username || this.username;
            this.joinRoom();
          }
        }
      },
        (err => {
          // const el = document.getElementById("btnLaunchModelClose");
          const result = err.json();
          this.toastr.error(result.message);
          this.disableJoin = true;
          this.loading = false;
          this.toogleScreens.emit(true);

          // return;
        }));
    }
    // });

    //this.loading = false
    //});
    //setTimeout(() => { this.loading = false }, 5000);
  }



  async joinRoom() {
    //Checking validations
    if (this.username.trim().length == 0) {
      this.toastr.error("", "Please enter username");
      this.setFocus("username");
      return;
    }
    // else if (!this.isHost && this.password.trim().length == 0) {
    else if (this.passwordrequired && this.password.trim().length == 0) {
      this.toastr.error("", "Please enter password");
      this.setFocus("password");
      return;
    }
    else if (this.emailRequired && this.email.trim().length == 0) {
      this.toastr.error("", "Please enter email");
      this.setFocus("email");
      return;
    }
    if (this.rememberMe)
      localStorage.setItem('joinData', JSON.stringify(this.viedoroom.getRawValue()));
    // else if(!this.isHost && this.email.trim().length == 0){
    //   this.toastr.error("", "Please enter email");
    //   this.setFocus("email");
    //   return;
    // }
    var _navigator = this.info.getDeviceInfo();

    if (this.data) {
      this.query.startWithAudioMuted = this.data.startWithAudioMuted;
      this.query.startWithVideoMuted = this.data.startWithVideoMuted;
    }

    this.query.fullName = this.username.trim();
    this.query.endTime = this.endTime;
    this.query.companyName = this.companyName.trim();
    this.query.designation = this.designation.trim();
    this.query.mobileNumber = this.mobile;
    this.query.mail = this.email.trim();
    this.query.location = this.location;
    this.query.occupation = this.occupation;
    this.query.platformInfo = _navigator;
    if (!this.isHost) this.query.loginparticipant = (this.user && this.user.email) ? 'y' : 'n';
    if (this.passwordrequired) this.query.password = this.password.trim();

    this.checkMetingExist = true;
    // this.spinner.show();
    // console.log(this.query);
    // return;
    this.loginService.isSpinner(true);
    this.saveAndJoinMetting();

  }

  saveAndJoinMetting() {
    this.countServerHit++;
    // console.log(' server hit==>' + this.countServerHit);

    this.httpSvc.newPost(`${this.url}conclave/nojwt/session/getHostCreateAndJoinCheckSumUrl`, this.query)
      .subscribe((res) => {
        const response = res.json();
        if (response.status === "OK" && response.message === "Checksum has generated") {
          let newWindow;
          if (!this.isHost) {
            console.log(response.data.joinUrl);
            window.location.replace(response.data.joinUrl);
            // newWindow = window.open(response.data.joinUrl);
            // newWindow.close();
          } else {
            window.location.replace(response.data.joinUrl);
            console.log(res);
          }

        }
        console.log(response);
        // this.spinner.hide();
        this.loginService.isSpinner(false);
        //this.showjoinMessage = false;
        clearInterval(this.sub);
      },
        (err) => {
          const errResponse = err.json();
          if (!errResponse.data.validate && errResponse.message == "The Host has not joined the session yet.!") {
            this.count++;
            this.checkMetingExist = false;
            // this.spinner.show();
            this.loginService.isSpinner(true);
            this.loginService.loaderMessage("You will automatically join when the meeting starts.");
            this.showjoinMessage = true;

            if (this.countServerHit === 50) {
              clearInterval(this.sub);
              this.countServerHit = 0;
              // this.spinner.hide();
              this.loginService.isSpinner(false);
              this.toastr.warning('Please try again');
              window.location.reload;
            }
            if (this.count == 1) {
              this.toastr.error(errResponse.message);
              this.sub = setInterval(() => this.saveAndJoinMetting(), 10000);
            }
          }
          else if (!errResponse.data.validate) {
            this.toastr.error(errResponse.message);
            this.loginService.isSpinner(false);
          }
        }
      );
  }

  loginFocus() {
    (function ($) {
      "use strict";

      $(".input100").each(function () {
        $(this).on("blur", function () {
          if ($(this).val().toString().trim() != "") {
            $(this).addClass("has-val");
          } else {
            $(this).removeClass("has-val");
          }
        });
      });
      var input = $(".validate-input .input100");

      $(".validate-form").on("submit", function () {
        var check = true;

        for (var i = 0; i < input.length; i++) {
          if (validate(input[i]) == false) {
            showValidate(input[i]);
            check = false;
          }
        }
        return check;
      });

      $(".validate-form .input100").each(function () {
        $(this).focus(function () {
          hideValidate(this);
        });
      });

      function validate(input) {
        if (
          $(input).attr("type") == "email" ||
          $(input).attr("name") == "email"
        ) {
          if (
            $(input)
              .val()
              .toString()
              .trim()
              .match(
                /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/
              ) == null
          ) {
            return false;
          }
        } else {
          if ($(input).val().toString().trim() == "") {
            return false;
          }
        }
      }

      function showValidate(input) {
        var thisAlert = $(input).parent();
        $(thisAlert).addClass("alert-validate");
      }

      function hideValidate(input) {
        var thisAlert = $(input).parent();
        $(thisAlert).removeClass("alert-validate");
      }
    })(jQuery);
  }

  setFocus(elementName: string) {
    $(elementName).focus();
  }

  async getBBB_Api() {
    //https://meet.dhanushinfotech.com/conference/nojwt/login/getCheckSumUrl;
    await this.entitysvc
      .GetData1("login/getCheckSumUrl", this.query).toPromise()
      .then((res) => {
        const result = res.json();
        if (result.message === "ChecksumURL" && result.status === "OK")
          this.bbb_url = result.data;
        console.log(result);
      });
  }

  validateParticipant() {
    this.loginFocus();
    if (this.mobile != "" && this.mobile.length < 10) {
      this.toastr.error("Please enter 10 digit mobile number.");
      this.mobile = "";
      return;
    }
    if (this.email != "") {
      var regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!regex.test(this.email.trim())) {
        this.toastr.error("Please enter valid Email");
        this.email = "";
        return;
      }
    }
    //^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$
  }

  checkDevice() {
    //let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    let isMobile = /Android/i.test(navigator.userAgent);
    if (isMobile) {
      document.getElementById("btnCofirmModal").click();

      setTimeout(() => this.linkOpen(2), 3000);
      // this.linkOpen(2);
    }
    //else {
    // let isMobile = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    // if (isMobile) {
    //   //https://meet.rtc.yourcorporatedomain.com/firstName.lastName/conferenceID"
    //   this.joinURL=`https://apps.apple.com/us/app/id1532848568`;
    //   document.getElementById("btnCofirmModal").click();

    //   setTimeout(() => this.linkOpen(2), 3000);
    //   // this.linkOpen(2);
    // }
    //     }

    console.log(this.joinURL);
  }
  linkOpen(id: number) {
    // let buttonTypeId: string = id == 1 ? "btnDownload" : "btnOpenApp";
    // document.getElementById(buttonTypeId).click();
    // document.getElementById('btnInstall').click();
    window.location.assign(this.joinURL);
    setTimeout(() => document.getElementById("btnClose").click(), 1000);
  }

  dotMessage(str: string, length: number = null, ending: string = null) {
    // text_truncate = function(str, length, ending) {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }

    // var text = 'Please wait for a moderator to approve you joining the meeting.'
    // console.log(text_truncate(text))
    // console.log(text_truncate(text, 40))
    // console.log(text_truncate(text, 40, '!!'))
  }

  isTouchScreen() {
    let hasTouchScreen = false;
    let nav: Navigator;
    if ("maxTouchPoints" in navigator) {
      hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
      hasTouchScreen = nav.msMaxTouchPoints > 0;
    } else {
      let mQ = window.matchMedia && matchMedia("(pointer:coarse)");
      if (mQ && mQ.media === "(pointer:coarse)") {
        hasTouchScreen = !!mQ.matches;
      } else if ('orientation' in window) {
        hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        let UA = nav.userAgent;
        hasTouchScreen = (
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
        );
      }
    }
    return hasTouchScreen;
  }
  validatepattern() {
    this.getUsername.valueChanges.subscribe(val => {
      //let v = /[^a-zA-Z .\-\/]/
      let v = /[!@#$%~|^&*()_+=`.{}\\"',:;?<>0-9[\]\-\/]/;
      if (v.test(val)) {
        this.getUsername.setValue(val.substr(0, val.length - 1));

      }
      if (val.length > 20) {
        this.getUsername.setValue(val.substr(0, 20))
      }
    });

    this.getMobile.valueChanges.subscribe(val => {
      let v = /[^0-9]+$/
      if (v.test(val)) {
        this.getMobile.setValue(val.substr(0, val.length - 1));

      }
      if (val.length > 10) {
        this.getMobile.setValue(val.substr(0, 10))
      }
    });
    // this.viedoroom.controls['email'].valueChanges.subscribe(val => {
    //   let v =  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    //   if (v.test(val)) {
    //     this.viedoroom.controls['email'].setValue(val.substr(0, val.length - 1));
    //   }
    // });

    this.getOccupation.valueChanges.subscribe(val => {
      // let v = /[^a-zA-Z .\-\/]/
      let v = /[!@#$%~|^&*()_+=`.{}\\"',:;?<>0-9[\]\-\/]/;
      if (v.test(val)) {
        this.getOccupation.setValue(val.substr(0, val.length - 1));
      }
      if (val.length > 30) {
        this.getOccupation.setValue(val.substr(0, 30))
      }
    });
    this.getLocation.valueChanges.subscribe(val => {
      //let v = /[^a-zA-Z .\-\/]/
      let v = /[!@#$%~|^&*()_+=`.{}\\"',:;?<>0-9[\]\-\/]/;
      if (v.test(val)) {
        this.getLocation.setValue(val.substr(0, val.length - 1));
      }
      if (val.length > 30) {
        this.getLocation.setValue(val.substr(0, 30))
      }
    });
    this.getDesignation.valueChanges.subscribe(val => {
      let v = /[!@#$%~|^&*()_+=`.{}\\"',:;?<>0-9[\]\-\/]/;
      if (v.test(val)) {
        this.getDesignation.setValue(val.substr(0, val.length - 1));
      }
      if (val.length > 30) {
        this.getDesignation.setValue(val.substr(0, 30))
      }
    });

    this.getPassword.valueChanges.subscribe(val => {
      if (val.length > 10) {
        this.getPassword.setValue(val.substr(0, 10))
      }
    });

    this.getCompany.valueChanges.subscribe(val => {
      if (val.length > 30) {
        this.getCompany.setValue(val.substr(0, 30))
      }
    });
  }


  get getUsername() {
    return this.viedoroom.controls['username'];
  }
  get getMobile() {
    return this.viedoroom.controls['mobile'];
  }
  get getOccupation() {
    return this.viedoroom.controls['occupation'];
  }
  get getLocation() {
    return this.viedoroom.controls['location'];
  }
  get getDesignation() {
    return this.viedoroom.controls['designation'];
  }
  get getPassword() {
    return this.viedoroom.controls['password'];
  }
  get getCompany() {
    return this.viedoroom.controls['company'];
  }

}
