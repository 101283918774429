import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-join-loading-message',
  templateUrl: './join-loading-message.component.html',
  styleUrls: ['./join-loading-message.component.css'],
  providers: [NgxSpinnerService]
})
export class JoinLoadingMessageComponent implements OnInit {

  constructor(private spinner: NgxSpinnerService) {
    this.spinner.show();
  }

  ngOnInit() {
  }

}
