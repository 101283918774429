import { DataTablesModule } from "angular-datatables";
import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs-compat";
import { EntityService } from "src/app/Services/entity.service";
import { HttpService } from "src/app/Services/http.service";
import { PaginationService } from "src/app/Services/pagination.service";
import { BaseComponent } from "../BaseComponent";
declare let $: any;
@Component({
  selector: "app-poll-list",
  templateUrl: "./poll-list.component.html",
  styleUrls: ["./poll-list.component.css"],
})
export class PollListComponent extends BaseComponent implements OnInit {
  public loading = false;
  searchForm: FormGroup;
  pollForm: FormGroup;
  fromDate: any = new Date();
  toDate: any = new Date();
  minToDate: Date;
  count: number = 0;
  listPoll: Array<any> = [];
  listPollOptions: Array<any> = [];
  listPollResult: Array<any> = [];
  isPollOpen: boolean = false;
  btnText: string = "Save";
  meetingId: number = 0;
  polloption: FormArray;
  dtOptions: DataTables.Settings = { destroy: true };
  dtTriggerPoll: Subject<any> = new Subject();
  pollOptions = { optionid: 0, optiontext: "" };
  // bdcolor = [
  //   "#a15626",
  //   "#325a85",
  //   "#784617",
  //   "#64540f",
  //   "#767a49",
  //   "#164c37",
  //   "#41898f",
  //   "#2a5576",
  //   "#6145c1",
  //   "#d7488d",
  // ];
  bdcolor = [
    "#007bff",
    "#949d3a",
    "#fd7e14",
    "#ffc107",
    "#28a745",
    "#a15626",
    "#20c997",
    "#17a2b8",
    "#767a49",
    "#e93c97",
  ];
  constructor(
    private formBuilder: FormBuilder,
    protected toastr: ToastrService,
    protected router: Router,
    private activeroute: ActivatedRoute,
    protected httpSvc: HttpService,
    protected pageSvc: PaginationService,
    protected entitysvc: EntityService,
    private datepipe: DatePipe
  ) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
  }

  ngOnInit() {
    this.activeroute.params.subscribe((params) => {
      this.meetingId = params["id"];
    });
    this.searchForm = this.formBuilder.group({
      meetingId: 0,
      todate: ["", [Validators.required]],
      fromdate: ["", [Validators.required]],
    });
    this.pollFormInit();
    this.onSearch();
  }

  pollFormInit() {
    this.pollForm = this.formBuilder.group({
      id: 0,
      meetingId: 0,
      pollquestion: ["", [Validators.required]],
      creatorId: 0,
      pollmember: [""],
      polloption: this.formBuilder.array([]),
      active: [true],
    });
  }

  newOption(item: any = {}): FormGroup {
    return this.formBuilder.group({
      optionid: [item.optionid || 0],
      optiontext: [item.optiontext || "", [Validators.required]],
    });
  }

  getOptions(): FormArray {
    return this.pollForm.get("polloption") as FormArray;
  }

  async onSearch() {
    this.loading = true;
    this.listPoll = [];
    let entity = this.searchForm.getRawValue();
    entity.meetingId = this.meetingId;
    entity.fromdate = `${this.datepipe.transform(this.fromDate, "yyyy-MM-dd")}`;
    entity.todate = `${this.datepipe.transform(this.toDate, "yyyy-MM-dd")}`;
    $(".dataTable").DataTable().destroy();
    $(".dataTable").DataTable({ searching: false });
    await super
      .FetchData("Post", this.apiMethod.getPollList, JSON.stringify(entity), 6)
      .catch(() => {
        this.httpSvc.handleError, (this.loading = false);
      })
      .then(() => {
        if (this.result != []) {
          if (this.result.status == "OK") {
            this.listPoll = this.result.data;
            // this.listPoll.forEach((m) => {
            //   let polls = [];
            //   m.polloption.forEach((e) => {
            //     polls.push(e.optiontext);
            //   });
            //   Object.assign(m, { options: polls });
            // });
          }
        }
        setTimeout(() => this.dtTriggerPoll.next(), 500);
        this.loading = false;
      });
  }

  openClosePoll(mode: any) {
    if (mode == "create") {
      this.pollFormInit();
      this.isPollOpen = true;
      this.btnText = "Save";
      // this.polloption = this.pollForm.get("polloption") as FormArray;
      this.getOptions().push(this.newOption());
      this.getOptions().push(this.newOption());
    } else {
      this.isPollOpen = false;
      setTimeout(() => {
        this.onSearch();
      }, 500);
    }
  }

  optionAdd(mode: any) {
    if (mode == "plus") {
      //if (this.polloption.length < 10) this.polloption.push(this.newOption());
      if (this.getOptions().length < 10)
        this.getOptions().push(this.newOption());
    } else {
      //this.polloption.removeAt(this.polloption.length - 1);
      this.getOptions().removeAt(this.getOptions().length - 1);
    }
  }

  async saveOrUpdate() {
    if (!this.pollForm.valid)
      return this.toastr.warning("Please enter mandotery field.");

    let entity = this.pollForm.getRawValue();
    entity.creatorId = JSON.parse(sessionStorage.getItem("loginData")).id;
    entity.meetingId = this.meetingId;
    console.log(entity);
    this.loading = true;
    await super
      .FetchData(
        "Post",
        this.apiMethod.saveUpdatePoll,
        JSON.stringify(entity),
        6
      )
      .catch(() => {
        this.httpSvc.handleError, (this.loading = false);
      })
      .then(() => {
        if (this.result != []) {
          if (this.result.status == "OK") {
            this.toastr.success(this.result.message);
          }
        }
        this.onSearch();
        this.loading = false;
        this.isPollOpen = false;
      });
  }

  async editPoll(item: any) {
    this.btnText = "Update";
    this.isPollOpen = true;
    this.pollForm.patchValue(item);
    this.getOptions().clear();
    console.log(this.pollForm.getRawValue());
    if (item.polloption.length == 0) {
      this.getOptions().push(this.newOption());
      this.getOptions().push(this.newOption());
    } else {
      item.polloption.forEach((e) => {
        this.getOptions().push(this.newOption(e));
      });
      console.log("after polloption add", this.pollForm.getRawValue());
    }
  }

  async getPollResult(item: any) {
    this.loading = true;
    await super
      .FetchData("Get", `${this.apiMethod.getPollResult}/${item.id}`, {}, 5)
      .catch(() => {
        this.httpSvc.handleError;
        this.loading = false;
      })
      .then(() => {
        let pollResult = this.result.data;
        this.listPollResult = Object.entries(pollResult["pollPercentage"])
          .map((e) => ({
            optionName: e[0],
            percentage: e[1],
            optionId: (item.polloption as Array<any>).find(
              (m) => m.optiontext == e[0]
            ).optionid,
            participants: (pollResult["userWisePollResults"] as Array<
              any
            >).filter((m) => m.optiontext == e[0]),
          }))
          .sort((a, b) => a.optionId - b.optionId);
        this.loading = false;
      });
  }

  async getPollResultNew(item: any) {
    this.listPollResult = [];
    await super.FetchData("Get", `${this.apiMethod.getPollResultByDate}/${item.id}`, {}, 5)
      .catch(() => { this.httpSvc.handleError; })
      .then(() => {
        let pollResult = this.result.data ? this.result.data : [];
        this.listPollResult = Object.entries(pollResult).map((e) => ({ dateOfResults: e[0], resultsList: e[1], })).sort((a, b) => Number(new Date(b.dateOfResults)) - Number(new Date(a.dateOfResults)));
        (item.polloption as Array<any>).forEach((e) => {
          this.listPollResult.forEach((m) => {
            let isExist = true;
            (m["resultsList"] as Array<any>).forEach((r) => { if (e.optionid == r.optionid) isExist = false; });
            if (isExist && (m["resultsList"] as Array<any>).length != (item.polloption as Array<any>).length) {
              (m["resultsList"] as Array<any>).push({
                count: 0,
                meetingstarttime: "",
                optionid: e.optionid,
                optiontext: e.optiontext,
                participants: "",
                percentage: "0",
                pollCreatedbytimezone: m["resultsList"][0].pollCreatedbytimezone,
                zoneid: m["resultsList"][0].zoneid
              });
            }
            (m["resultsList"] as Array<any>).sort((a, b) => a.optionid - b.optionid);
          });
        });
      });
    console.log('pollResult', this.listPollResult);
  }

  onFromDateChange(val: any) {
    this.count++;
    this.minToDate = new Date();
    let formdate = this.datepipe.transform(val, "yyyy-MM-dd");
    let todate = this.datepipe.transform(
      this.searchForm.get("todate").value,
      "yyyy-MM-dd"
    );

    if (val != null) {
      if (new Date(formdate) > new Date(todate))
        this.toDate = this.count == 2 ? this.toDate : new Date(formdate);
      let edate = this.datepipe.transform(val, "yyyy-MM-dd").split("-"),
        cnvDate = new Date(
          parseInt(edate[0]),
          parseInt(edate[1]) - 1,
          parseInt(edate[2]),
          0,
          0,
          0,
          0
        );
      this.minToDate = new Date(cnvDate);

      let mdate = this.datepipe.transform(val, "yyyy-MM-dd").split("-"),
        cnvmDate = new Date(
          parseInt(mdate[0]),
          parseInt(mdate[1]) - 1,
          parseInt(mdate[2]),
          0,
          0,
          0,
          0
        );
    }
  }

  nameValidate(event: any) {
    event.target.value = event.target.value.replace(/[^A-Za-z. ]*/g, "");
  }

  resultStyle(index1: number, index2: number, item: any) {
    $("#result_" + index1 + "_" + index2)
      .css("width", item.percentage + "%")
      .css("background-color", this.bdcolor[index2])
      .attr("aria-valuenow", item.percentage);
    // .text(item.optionName);
    //.text(item.percentage + "%");
    $("#optionName_" + index1 + "_" + index2).css(
      "color",
      item.percentage > 0 ? "#060000" : "#666565"
    );
  }

  pollResult(item: any) {
    let arr = [];
    let obj = { optionName: "", percentage: "", count: 0, participants: [] };
    arr = Object.entries(item["pollPercentage"]).map((e) => ({
      optionName: e[0],
      percentage: e[1],
      participants: (item["userWisePollResults"] as Array<any>).filter(
        (m) => m.optiontext == e[0]
      ),
    }));
    console.log(arr);
    return arr;
  }

  ngOnDestroy() {
    this.dtTriggerPoll.unsubscribe();
  }
}
