import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-selectbackground',
  templateUrl: './selectbackground.component.html',
  styleUrls: ['./selectbackground.component.css']
})
export class SelectbackgroundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
