import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EntityService } from 'src/app/Services/entity.service';
import { HttpService } from 'src/app/Services/http.service';
import { PaginationService } from 'src/app/Services/pagination.service';
import { BaseComponent } from '../BaseComponent';
declare let $: any, Loadnavbarcollapse: any;
@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent extends BaseComponent implements OnInit {

  
  constructor(
    protected toastr: ToastrService,
    protected router: Router,
    protected httpSvc: HttpService,
    protected entitysvc: EntityService,
    protected pageSvc: PaginationService
  ) {
    super(toastr, router, httpSvc, entitysvc, pageSvc);
   }
  ngOnInit() {
    this.scroll();
    this.mobile();
  }

  scroll ()
  {
  $('#navbar1 a').click(function() {
    $(this).addClass('active').siblings('a').removeClass('active');
  });
  }

  mobile() {
    $('.navbar-collapse ul li a').click(function(){
      $(".navbar-collapse").toggleClass('show');
    });
    $('#mobileFeatures').click(function(){
      $(".navbar-collapse ul").toggle().css('display','block');
    });
  }
  

}
